@include max($mobile) {
  .seat-table table td {
    min-width: 2rem;
  }

  .seat-table {
    max-width: unset;
    width: auto;
  }

  .minimap-container {
    height: unset !important;
  }

  .seat-wr {
    padding: .1rem;
  }

  .seat-name-row {
    font-size: 1rem;
    display: none !important;
  }

  .seat-name {
    font-size: .8rem;
    top: calc(50% + .5px);
  }
  tr:has(.seat-td.seat-vip-row-last) td{
    margin-bottom: 0;
  }
  tr:has(.seat-td.seat-vip-row-last) td {
    padding-bottom: 0;
  }
  
  .first-vip.seat-vip-row-1 {
    border-top-left-radius: 0.5rem;
  }
  .last-vip.seat-vip-row-last{
    border-bottom-right-radius: 0.5rem;
  }
  .first-vip.seat-vip-row-last{
    border-bottom-left-radius: 0.5rem;
  }
  .last-vip.seat-vip-row-1{
    border-top-right-radius: 0.5rem;
  }
  tr:has(.seat-td.seat-vip-row-1) td{
    margin-top: 0;
  }

  .minimap{
    display: none;
  }
  .seat{
    overflow: hidden;
  }
  .shtime-ft .cinestar-item .item-time{
    transform: translateZ(0);
    border-width: 1px;
  }
  .shtime-ft .cinestar-item .item-time.disable{
    border-width: 1px;
  }
  .shtime-ft .cinestar-item .item-time.active{
    border-width: 1px;
  }
}