.footer {
    position: relative;
    padding-bottom: 4rem;
    @include max($mobile) {
      padding-bottom: calc(var(--size-mobile-nav-bt) + 4rem);
  }
}

.footer {}

.footer-wr {
    padding-top: var(--sp-sc);
    @include max($mobile) {
        border-top: 0.1rem solid #475569;
        padding-bottom:0;
    }
}

.footer{
  >div{
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(106deg, #639 0%, #36C 102.69%);
      z-index: -10;
  
      @include max($mobile) {
          background: $color-gray-2;
  
      }
  }
  }
}

.footer .footer-list {
    padding-bottom: 2.4rem;
}

.footer .footer-item {
    .text {
        font-family: var(--font-title);
        text-transform: uppercase;
    }
}


.footer .footer-item:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 2rem 0;

    @include max(900px) {
        align-items: center;
        width: 100%;
    }

    @include max($mobile) {
        gap: 0;
        margin-bottom: 0;
    }
}

.footer .footer-item .ft-logo {
    display: block;
    width: 43%;

    @include max($mobile) {
        margin-bottom: 2.4rem;
        width: 13rem;
    }
}

.footer .footer-item-bot {
    margin-top: 4.8rem;
}

.footer .footer-item {
    &:not(:first-child) {
        @include max(900px) {
            width: 25%;
        }

        @include max($mobile) {
            display: none;
        }
    }
    &:nth-child(2){
      @include max(900px) {
        width: 50%;
      }
    }
}

.footer .footer-item .menu-list {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.footer .footer-item .menu-link {
    font-size: 1.6rem;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none;

    &:hover {
        color: $color-second;
        -webkit-mask-image: linear-gradient(-75deg, rgba(243, 234, 40, 0.6) 30%, rgb(243, 234, 40) 50%, rgba(243, 234, 40, 0.6) 70%);
        -webkit-mask-size: 200%;
        animation: shine 1s infinite;
    }
}

.footer-item .ft-text .txt-deskop {
    @include max($mobile) {
        display: none;
    }
}

.footer-item .ft-text .txt-mobile {
    @include min($mobile-min) {
        display: none;
    }

    @include max($mobile) {
        margin-bottom: 3.2rem;
        text-align: center;
    }
}

.footer .ft-group-btn {
    display: flex;
    align-items: center;
    gap: 0 1.6rem;

    @include max(1000px) {
        gap: 0 1.2rem;
    }

    @include max($mobile) {
        display: none;
    }
}

.footer .ft-group-btn .btn {
    width: 17.5em;

    @include max(1200px) {
        width: 12.5em;
    }

    @include max(1000px) {
        // width: 12.5em;
    }
}

.footer .ft-hotline {
    font-family: var(--font-pri);

    @include max($mobile) {
        margin-bottom: 2.4rem;
    }
}


.ft-hotline-socials .list {
    display: flex;
    align-items: center;
    gap: 0 1.2rem;
}

.ft-hotline-socials .link {
    display: block;
    width: 2.4rem;
    aspect-ratio: 1/1;
    transition: all .3s ease;

    &:hover {
        transform: translateY(-0.4rem);
    }
}

.ft-hotline-socials .item-lk {
    @include max($mobile) {
        display: none;
    }
}

.footer .text {
    margin-bottom: 2.4rem;
}

.footer .footer-item .menu-link {
    text-decoration: none;
    text-underline-position: under;
}

.ft-lg {
    @include max($mobile) {
        margin-top: 3.6rem;
    }
}

.ft-lg>.txt {
    @include max($mobile) {
        display: none;
    }
}

.ft-lg {
    display: flex;
    align-items: center;
    gap: 0 2.4rem;
}

.lg-action {
    display: flex;
    align-items: center;
    gap: 0 0.8rem;
    position: relative;
    cursor: pointer;

    .lg-action-popup.open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        cursor: pointer;
    }

    &:hover {
        .lg-action-popup {
            @include show;
        }
    }
}

.lg-action .ic-arr {
    font-size: 1.6rem;
    transition: .2s;
}

.lg-option {
    display: flex;
    align-items: center;
    gap: 0 0.8rem;

    @include max(768px) {
        gap: 0 0.4rem;
    }

    @include max(576px) {
        gap: 0 0.2rem;
    }
}

.lg-option .txt {
    @include max(768px) {
        @include fz-14;
    }

    @include max(576px) {
        @include fz-12;
    }
}

.lg-popup {
    display: flex;
    gap: 0 0.8rem;

    @include max(768px) {
        gap: 0 0.4rem;
    }

    @include max(576px) {
        gap: 0 0.2rem;
    }
}

.lg-action-popup {
    position: absolute;
    top: calc(100% + 0.4rem);
    right: 0;
    background: rgba(51, 102, 204, 0.80);
    backdrop-filter: blur(100px);
    padding: 0.6rem 1rem;
    border-radius: $radius-2;
    min-width: 100%;
    transform: translateY(1rem);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
}

.lg-option .image {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100rem;
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.footer-bottom .menu-list {
    display: flex;
    gap: 0 2.4rem;

    @include max(1200px) {
        gap: 0 1.2rem;
    }
}

.footer-bottom .menu-list .menu-link {
    transition: all .3s;
    cursor: pointer;

    &:hover {
        color: $color-second;
        -webkit-mask-image: linear-gradient(-75deg, rgba(243, 234, 40, 0.6) 30%, rgb(243, 234, 40) 50%, rgba(243, 234, 40, 0.6) 70%);
        -webkit-mask-size: 200%;
        animation: shine 1s infinite;
    }
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 2.4rem;
    border-top: 0.1rem solid #F8FAFC;

    @include max(740px) {
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
    }

    @include max($mobile) {
        display: none;
    }
}

.ft-menu-mobile {
    @include min($mobile-min) {
        display: none;
    }

    @include max($mobile) {
        display: block;
        margin-bottom: 2.4rem;
    }
}

.ft-menu-mobile .menu-list {
    text-align: center;
}

.footer-top-mobile{
  @include max($mobile){
    display: none;
  }
}

.ft-author{
  margin-top: 4rem;
  text-align: center;
  font-size: 1.4rem;
  &-content{
    ul{
      display: flex;
    flex-direction: column;
    gap: 1.6rem;
      @include max($mobile){
        font-size: 1.4rem;
        padding: 1.2rem;
      }
    }
  }
 
}
.ft-bct{
  width: 20rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  @include max($mobile){
    width: 16rem;
  }
  img{
    width: 100%;
    display: block;
  }
}