.cinestar-system-detail {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: #0F172A;
        z-index: -20;
    }
}


.cinestar-system-detail-wr .bg {
    position: relative;
    display: block;
    padding-top: calc(500/1216*100%);
    width: 100%;
    height: auto;
    margin-bottom: 8rem;
    position: relative;

    @include max($res-mobile-min) {
        margin-bottom: 4.8rem;
    }

    @include max($mobile) {
        margin-bottom: 2.4rem;
    }

    >img {
        @include mid;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.cinestar-system-detail-wr .bg .logo {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    z-index: 5;
    width: 10%;

    @include max(768px) {
        bottom: 1rem;
        left: 1rem;
    }

    @include max($mobile) {
        width: 16%;
    }
}

.cinestar-system-detail-wr .content {
    text-align: center;
    max-width: 84rem;
    margin: 0 auto 4rem;
}


.cinestar-system-detail-wr .content .text {
    font-weight: 600;
    @include fz-18;
}

.cinestar-system-detail-wr .heading {
    @include fz-64;
    width: max-content;
    margin: 0 auto 2.4rem;
}

.address-box-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.address-box-head .icon {
    color: $color-second;
    font-size: 1.8rem;
    transition: .3s ease-in-out;
    display: block;

    @include min($mobile-min) {
        display: none;
    }
}

.address-box.active .icon {
    transform: rotate(180deg);
}


.address-box.active .list {
    @include min($mobile) {
        display: block;
    }

    @include max($mobile) {
        display: none;
    }
}

.address-list {
    @include max($mobile) {
        flex-direction: column;
    }
}

.address-item {
    @include max($mobile) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

.opera-house .address-item {
    margin: 0 auto;
}


.opera-house .address-list {
    margin-bottom: 0;
}

.address-item .link {
    transition: all .2s;

    .ic {
        transition: all .2s;
    }

    &:hover {
        color: $color-second;

        .ic {
            transform: scale(1.2);
        }
    }
}

.cinestar-system-detail .address-item .link {
    word-break: break-word;
}

.address-box {
    padding: 2.4rem;
    background: linear-gradient(106deg, rgba(102, 51, 153, 0.80) 0%, rgba(51, 102, 204, 0.80) 102.69%);
    text-align: left;
    height: 100%;

    @include max(1200px) {
        padding: 1.2rem;
    }

    @include min($mobile-min) {
        pointer-events: none;
    }
}
.address-box.rm-bg{
  background: none;
}
.address-box .sub-tittle {}

.address-box .item {
    margin-bottom: 0.8rem;
}

.address-item .list {
    margin-top: 1.2rem;

    @include min($mobile-min) {
        display: block !important;
    }

    @include max($mobile) {
        display: none;
    }
}

.address-box .item:nth-child(2) .link {
    text-decoration: underline;
    text-underline-position: under;
}

.address-box .link {
    display: flex;
    align-items: center;
    color: $color-text;
    gap: 0 0.8rem;
    pointer-events: visible;
}

.address-box .link .ic {
    img {
        width: 1.6rem;
        height: 1.6rem;
    }
}

.address-box .link .txt {}

.enter-content .item {
    &:not(:last-child) {
        margin-bottom: 2.4rem;

        @include max($mobile) {
            margin-bottom: 1.6rem;
        }
    }
}

.enter-content .item .bg {
    &:hover {
        box-shadow: 0 0.5rem 1.5rem #E3D530;
    }
}

// .enter-content .item:nth-child(2) .bg {
//     &:hover {
//         box-shadow: 0 0.5rem 1.5rem #F7983A;
//     }
// }

// .enter-content .item:nth-child(3) .bg {
//     &:hover {
//         box-shadow: 0 0.5rem 1.5rem #4167B0;
//     }
// }

// .enter-content .item:nth-child(4) .bg {
//     &:hover {
//         box-shadow: 0 0.5rem 1.5rem #009748;
//     }
// }

// .enter-content .item:nth-child(6) .bg {
//     &:hover {
//         box-shadow: 0 0.5rem 1.5rem #EA1D3E;
//     }
// }

// .enter-content .item:nth-child(7) .bg {
//     &:hover {
//         box-shadow: 0 0.5rem 1.5rem #673398;
//     }
// }

// .enter-content .item:nth-child(5) .bg {
//     &:hover {
//         box-shadow: 0 0.5rem 1.5rem $color-second;
//     }
// }

.enter-content .item .bg {
    display: block;
    padding-top: calc(202/1216*100%);
    border-radius: var(--rs);
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    transition: .5s ease-in-out;

    @include max($mobile) {
        padding-top: calc(135/350*100%);
    }


    &:hover {

        img {
            // transform: translate(-50%, -50%) scale(1.05);
        }
    }

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .6s ease-in-out;
    }

    .img-enter-des {
        @include max($mobile) {
            display: none;
        }
    }

    .img-enter-mobile {
        @include min($mobile-min) {
            display: none;
        }
    }
}