.combo-heading {
    text-align: left;
}

.food-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    gap: 1.2rem;
    overflow: hidden;
    height: 100%;
    &:hover {
        .image::before {
            left: 110%;
            transition: left 0.5s ease;
            visibility: visible;
        }

        .image img {
            // transform: scale(1.2) translate(-50%, -50%);
            transform: translate(-50%, -50%) scale(1.05) rotate(3deg);
        }

        .name {
            color: $color-second;
        }
    }
}

.food-box .img {
    display: block;
    width: 100%;
    height: auto;
}

.food-box .image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-top: calc(420/383*100%);
    overflow: hidden;


    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 8rem;
        height: 100%;
        content: '';
        background: #fff;
        opacity: 0.2;
        transition: none;
        transform: skewX(-20deg);
        visibility: hidden;
        z-index: 2;
    }

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .5s ease-in-out;
    }
}

.food-box .content .content-top {
    display: flex;
    flex-direction: column;
    gap: 0.4rem 0;
}

.food-box .content .content-top .sub-title {
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.food-box .content .content-top .desc {
    @include max($mobile) {
        font-size: 1rem;
    }
}

.food-box .content .content-top .name {
    transition: all .2s;
}

.food-box .content .content-bottom {
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    margin-top: auto;

    @include max($mobile) {
        flex-direction: column;
    }
}

.combo-heading {
    text-align: center;
}

.food-box .content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.food-box .content .content-bottom .btn {
    width: 100%;

    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.combo-list {
    justify-content: center;

    @include max($mobile) {
        --col-gap: 0.6rem;
        --row-gap: 0.6rem;
    }
}

.count {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1.2rem 1.2rem 1rem;
    width: 12rem;
    justify-content: space-between;
    border-radius: $radius-1;
    background: $color-gray-1;
    line-height: 100%;
    color: $color-gray-2;
    transition: all .3s;

    @include max($mobile) {
        width: 100%;
    }

}

.count .count-btn {
    font-size: 1.2rem;
    font-weight: 300;
    transition: all .2s;
    position: relative;
    z-index: 2;

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -60%);
        border-radius: 100%;
        width: 1.8rem;
        height: 1.8rem;
        background-color: $color-pri;
        z-index: 1;
        transition: all .2s;
        opacity: 0;

    }

    i {
        transition: all .2s;
        position: relative;
        z-index: 2;
    }

    &:hover {
        cursor: pointer;
        color: $color-second;

        &::after {
            opacity: 1;
        }
    }
}

.count .count-number {
    font-size: 2rem;
    font-weight: 400;

    @include max($mobile) {
        font-size: 1.6rem;
    }

}

.bill-wr {
    display: flex;
    justify-content: space-between;
    padding: 3.2rem;
    background: $color-pri;
    width: calc(9/12*100%);
    margin-left: auto;
    margin-right: auto;

    @include max($mobile) {
        width: calc(12/12*100%);
        padding: 1.2rem;
    }

    @include max(500px) {
        flex-direction: column;
    }
}

.bill-left,
.bill-right {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.bill-left {
    @include max(500px) {
        padding-bottom: 1.2rem;
    }
}

.bill-wr .name-combo {
    text-transform: uppercase;
    font-family: var(--font-title);
}

.bill-wr .btn {
    @include max($tablet) {
        font-size: 1.2rem;
    }
}

.bill-right {
    width: calc(3/9*100%);
    padding-left: 3.2rem;
    border-left: 0.1rem solid rgba(241, 245, 249, 0.5);

    @include max(1000px) {
        width: calc(4/9*100%);
    }

    @include max($mobile) {
        width: calc(5/12*100%);
        padding-left: 1.2rem;
    }

    @include max(500px) {
        padding-top: 1.2rem;
        width: 100%;
        padding-left: unset;
        border-left: unset;
        border-top: 0.1rem solid rgba(241, 245, 249, 0.5);
    }
}

.bill-right .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bill-right .price .num {
    font-size: 2.4rem;
    font-weight: 600;

    @include max($tablet) {
        font-size: 1.8rem;
    }
}