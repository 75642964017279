/*!
 * Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
.fa {
	font-family: var(--fa-style-family, 'Font Awesome 6 Pro');
	font-weight: var(--fa-style, 900);
}
.fa,
.fa-brands,
.fa-classic,
.fa-duotone,
.fa-light,
.fa-regular,
.fa-sharp,
.fa-sharp-solid,
.fa-solid,
.fa-thin,
.fab,
.fad,
.fal,
.far,
.fas,
.fasr,
.fass,
.fat {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: var(--fa-display, inline-block);
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	text-rendering: auto;
}
.fa-classic,
.fa-light,
.fa-regular,
.fa-solid,
.fa-thin,
.fal,
.far,
.fas,
.fat {
	font-family: 'Font Awesome 6 Pro';
}
.fa-brands,
.fab {
	font-family: 'Font Awesome 6 Brands';
}
.fa-classic.fa-duotone,
.fa-duotone,
.fad {
	font-family: 'Font Awesome 6 Duotone';
}
.fa-sharp,
.fasr,
.fass {
	font-family: 'Font Awesome 6 Sharp';
}
.fa-sharp,
.fass {
	font-weight: 900;
}
.fa-1x {
	font-size: 1em;
}
.fa-2x {
	font-size: 2em;
}
.fa-3x {
	font-size: 3em;
}
.fa-4x {
	font-size: 4em;
}
.fa-5x {
	font-size: 5em;
}
.fa-6x {
	font-size: 6em;
}
.fa-7x {
	font-size: 7em;
}
.fa-8x {
	font-size: 8em;
}
.fa-9x {
	font-size: 9em;
}
.fa-10x {
	font-size: 10em;
}
.fa-2xs {
	font-size: 0.625em;
	line-height: 0.1em;
	vertical-align: 0.225em;
}
.fa-xs {
	font-size: 0.75em;
	line-height: 0.08333em;
	vertical-align: 0.125em;
}
.fa-sm {
	font-size: 0.875em;
	line-height: 0.07143em;
	vertical-align: 0.05357em;
}
.fa-lg {
	font-size: 1.25em;
	line-height: 0.05em;
	vertical-align: -0.075em;
}
.fa-xl {
	font-size: 1.5em;
	line-height: 0.04167em;
	vertical-align: -0.125em;
}
.fa-2xl {
	font-size: 2em;
	line-height: 0.03125em;
	vertical-align: -0.1875em;
}
.fa-fw {
	text-align: center;
	width: 1.25em;
}
.fa-ul {
	list-style-type: none;
	margin-left: var(--fa-li-margin, 2.5em);
	padding-left: 0;
}
.fa-ul > li {
	position: relative;
}
.fa-li {
	left: calc(var(--fa-li-width, 2em) * -1);
	position: absolute;
	text-align: center;
	width: var(--fa-li-width, 2em);
	line-height: inherit;
}
.fa-border {
	border-radius: var(--fa-border-radius, 0.1em);
	border: var(--fa-border-width, 0.08em) var(--fa-border-style, solid) var(--fa-border-color, #eee);
	padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}
.fa-pull-left {
	float: left;
	margin-right: var(--fa-pull-margin, 0.3em);
}
.fa-pull-right {
	float: right;
	margin-left: var(--fa-pull-margin, 0.3em);
}
.fa-beat {
	-webkit-animation-name: fa-beat;
	animation-name: fa-beat;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
	animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: var(--fa-animation-direction, normal);
	animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
	animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
	animation-timing-function: var(--fa-animation-timing, ease-in-out);
}
.fa-bounce {
	-webkit-animation-name: fa-bounce;
	animation-name: fa-bounce;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
	animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: var(--fa-animation-direction, normal);
	animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
	animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
	animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}
.fa-fade {
	-webkit-animation-name: fa-fade;
	animation-name: fa-fade;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
	animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}
.fa-beat-fade,
.fa-fade {
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
	animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: var(--fa-animation-direction, normal);
	animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
	animation-duration: var(--fa-animation-duration, 1s);
}
.fa-beat-fade {
	-webkit-animation-name: fa-beat-fade;
	animation-name: fa-beat-fade;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
	animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}
.fa-flip {
	-webkit-animation-name: fa-flip;
	animation-name: fa-flip;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
	animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: var(--fa-animation-direction, normal);
	animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
	animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
	animation-timing-function: var(--fa-animation-timing, ease-in-out);
}
.fa-shake {
	-webkit-animation-name: fa-shake;
	animation-name: fa-shake;
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
	animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: var(--fa-animation-timing, linear);
	animation-timing-function: var(--fa-animation-timing, linear);
}
.fa-shake,
.fa-spin {
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
	animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: var(--fa-animation-direction, normal);
	animation-direction: var(--fa-animation-direction, normal);
}
.fa-spin {
	-webkit-animation-name: fa-spin;
	animation-name: fa-spin;
	-webkit-animation-duration: var(--fa-animation-duration, 2s);
	animation-duration: var(--fa-animation-duration, 2s);
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: var(--fa-animation-timing, linear);
	animation-timing-function: var(--fa-animation-timing, linear);
}
.fa-spin-reverse {
	--fa-animation-direction: reverse;
}
.fa-pulse,
.fa-spin-pulse {
	-webkit-animation-name: fa-spin;
	animation-name: fa-spin;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
	animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
	animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
	animation-timing-function: var(--fa-animation-timing, steps(8));
}
@media (prefers-reduced-motion: reduce) {
	.fa-beat,
	.fa-beat-fade,
	.fa-bounce,
	.fa-fade,
	.fa-flip,
	.fa-pulse,
	.fa-shake,
	.fa-spin,
	.fa-spin-pulse {
		-webkit-animation-delay: -1ms;
		animation-delay: -1ms;
		-webkit-animation-duration: 1ms;
		animation-duration: 1ms;
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		-webkit-transition-delay: 0s;
		transition-delay: 0s;
		-webkit-transition-duration: 0s;
		transition-duration: 0s;
	}
}
@-webkit-keyframes fa-beat {
	0%,
	90% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	45% {
		-webkit-transform: scale(var(--fa-beat-scale, 1.25));
		transform: scale(var(--fa-beat-scale, 1.25));
	}
}
@keyframes fa-beat {
	0%,
	90% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	45% {
		-webkit-transform: scale(var(--fa-beat-scale, 1.25));
		transform: scale(var(--fa-beat-scale, 1.25));
	}
}
@-webkit-keyframes fa-bounce {
	0% {
		-webkit-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
	}
	10% {
		-webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
		transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
	}
	30% {
		-webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1))
			translateY(var(--fa-bounce-height, -0.5em));
		transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
	}
	50% {
		-webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
		transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
	}
	57% {
		-webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
		transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
	}
	64% {
		-webkit-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
	}
	to {
		-webkit-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
	}
}
@keyframes fa-bounce {
	0% {
		-webkit-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
	}
	10% {
		-webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
		transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
	}
	30% {
		-webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1))
			translateY(var(--fa-bounce-height, -0.5em));
		transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
	}
	50% {
		-webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
		transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
	}
	57% {
		-webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
		transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
	}
	64% {
		-webkit-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
	}
	to {
		-webkit-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
	}
}
@-webkit-keyframes fa-fade {
	50% {
		opacity: var(--fa-fade-opacity, 0.4);
	}
}
@keyframes fa-fade {
	50% {
		opacity: var(--fa-fade-opacity, 0.4);
	}
}
@-webkit-keyframes fa-beat-fade {
	0%,
	to {
		opacity: var(--fa-beat-fade-opacity, 0.4);
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		opacity: 1;
		-webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
		transform: scale(var(--fa-beat-fade-scale, 1.125));
	}
}
@keyframes fa-beat-fade {
	0%,
	to {
		opacity: var(--fa-beat-fade-opacity, 0.4);
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		opacity: 1;
		-webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
		transform: scale(var(--fa-beat-fade-scale, 1.125));
	}
}
@-webkit-keyframes fa-flip {
	50% {
		-webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
		transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
	}
}
@keyframes fa-flip {
	50% {
		-webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
		transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
	}
}
@-webkit-keyframes fa-shake {
	0% {
		-webkit-transform: rotate(-15deg);
		transform: rotate(-15deg);
	}
	4% {
		-webkit-transform: rotate(15deg);
		transform: rotate(15deg);
	}
	8%,
	24% {
		-webkit-transform: rotate(-18deg);
		transform: rotate(-18deg);
	}
	12%,
	28% {
		-webkit-transform: rotate(18deg);
		transform: rotate(18deg);
	}
	16% {
		-webkit-transform: rotate(-22deg);
		transform: rotate(-22deg);
	}
	20% {
		-webkit-transform: rotate(22deg);
		transform: rotate(22deg);
	}
	32% {
		-webkit-transform: rotate(-12deg);
		transform: rotate(-12deg);
	}
	36% {
		-webkit-transform: rotate(12deg);
		transform: rotate(12deg);
	}
	40%,
	to {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
@keyframes fa-shake {
	0% {
		-webkit-transform: rotate(-15deg);
		transform: rotate(-15deg);
	}
	4% {
		-webkit-transform: rotate(15deg);
		transform: rotate(15deg);
	}
	8%,
	24% {
		-webkit-transform: rotate(-18deg);
		transform: rotate(-18deg);
	}
	12%,
	28% {
		-webkit-transform: rotate(18deg);
		transform: rotate(18deg);
	}
	16% {
		-webkit-transform: rotate(-22deg);
		transform: rotate(-22deg);
	}
	20% {
		-webkit-transform: rotate(22deg);
		transform: rotate(22deg);
	}
	32% {
		-webkit-transform: rotate(-12deg);
		transform: rotate(-12deg);
	}
	36% {
		-webkit-transform: rotate(12deg);
		transform: rotate(12deg);
	}
	40%,
	to {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}
@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}
.fa-rotate-90 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}
.fa-rotate-180 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.fa-rotate-270 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}
.fa-flip-horizontal {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.fa-flip-vertical {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
	-webkit-transform: scale(-1);
	transform: scale(-1);
}
.fa-rotate-by {
	-webkit-transform: rotate(var(--fa-rotate-angle, none));
	transform: rotate(var(--fa-rotate-angle, none));
}
.fa-stack {
	display: inline-block;
	height: 2em;
	line-height: 2em;
	position: relative;
	vertical-align: middle;
	width: 2.5em;
}
.fa-stack-1x,
.fa-stack-2x {
	left: 0;
	position: absolute;
	text-align: center;
	width: 100%;
	z-index: var(--fa-stack-z-index, auto);
}

:host,
:root {
	--fa-font-light: normal 300 1em/1 'Font Awesome 6 Pro';
}
@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url(fa-light-300.woff2) format('woff2'), url(fa-light-300.ttf) format('truetype');
}
.fa-light,
.fal {
	font-weight: 300;
}
:host,
:root {
	--fa-font-regular: normal 400 1em/1 'Font Awesome 6 Pro';
}
@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(fa-regular-400.woff2) format('woff2'), url(fa-regular-400.ttf) format('truetype');
}
.fa-regular,
.far {
	font-weight: 400;
}
:host,
:root {
	--fa-font-solid: normal 900 1em/1 'Font Awesome 6 Pro';
}
@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url(fa-solid-900.woff2) format('woff2'), url(fa-solid-900.ttf) format('truetype');
}
.fa-solid,
.fas {
	font-weight: 900;
}
:host,
:root {
	--fa-style-family-classic: 'Font Awesome 6 Pro';
	--fa-font-thin: normal 100 1em/1 'Font Awesome 6 Pro';
}
.fa-phone-volume:before,
.fa-volume-control-phone:before {
	content: '\f2a0';
}
.fa-chevron-left:before {
	content: '\f053';
}
.fa-chevron-right:before {
	content: '\f054';
}
.fa-duotone.fa-angle-down:after,
.fad.fa-angle-down:after {
	content: '\f107\f107';
}
.fa-close:before,
.fa-multiply:before,
.fa-remove:before,
.fa-times:before,
.fa-xmark:before {
	content: '\f00d';
}
.fa-circle-notch:before {
	content: '\f1ce';
}
.fa-angle-down:before {
	content: '\f107';
}
.fa-calendar-alt:before,
.fa-calendar-days:before {
	content: '\f073';
}
.fa-angle-left:before {
	content: '\f104';
}
.fa-angle-right:before {
	content: '\f105';
}
.fa-spinner:before {
	content: '\f110';
}
.fa-minus:before,
.fa-subtract:before {
	content: '\f068';
}
.fa-add:before,
.fa-plus:before {
	content: '\2b';
}
.fa-user-check:before {
	content: '\f4fc';
}
.fa-earth-america:before,
.fa-earth-americas:before,
.fa-earth:before,
.fa-globe-americas:before {
	content: '\f57d';
}
.fa-film-slash:before {
	content: '\e179';
}
.fa-user:before {
	content: '\f007';
}
.fa-sign-out-alt:before {
	content: '\f2f5';
}
.fa-caret-down:before {
	content: '\f0d7';
}
.fa-location-dot:before,
.fa-map-marker-alt:before {
	content: '\f3c5';
}
@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 100;
	font-display: block;
	src: url(fa-thin-100.woff2) format('woff2'), url(fa-thin-100.ttf) format('truetype');
}
.fa-thin,
.fat {
	font-weight: 100;
}
@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-display: block;
	font-weight: 400;
	src: url(fa-brands-400.woff2) format('woff2'), url(fa-brands-400.ttf) format('truetype');
}
@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-display: block;
	font-weight: 900;
	src: url(fa-solid-900.woff2) format('woff2'), url(fa-solid-900.ttf) format('truetype');
}
@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-display: block;
	font-weight: 400;
	src: url(fa-regular-400.woff2) format('woff2'), url(fa-regular-400.ttf) format('truetype');
}
@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-display: block;
	font-weight: 300;
	src: url(fa-light-300.woff2) format('woff2'), url(fa-light-300.ttf) format('truetype');
}
@font-face {
	font-family: 'Font Awesome 5 Duotone';
	font-display: block;
	font-weight: 900;
	src: url(fa-duotone-900.woff2) format('woff2'), url(fa-duotone-900.ttf) format('truetype');
}
@font-face {
	font-family: 'FontAwesome';
	font-display: block;
	src: url(fa-solid-900.woff2) format('woff2'), url(fa-solid-900.ttf) format('truetype');
}
@font-face {
	font-family: 'FontAwesome';
	font-display: block;
	src: url(fa-brands-400.woff2) format('woff2'), url(fa-brands-400.ttf) format('truetype');
}
@font-face {
	font-family: 'FontAwesome';
	font-display: block;
	src: url(fa-regular-400.woff2) format('woff2'), url(fa-regular-400.ttf) format('truetype');
	unicode-range: u+f003, u+f006, u+f014, u+f016-f017, u+f01a-f01b, u+f01d, u+f022, u+f03e, u+f044, u+f046, u+f05c-f05d, u+f06e, u+f070,
		u+f087-f088, u+f08a, u+f094, u+f096-f097, u+f09d, u+f0a0, u+f0a2, u+f0a4-f0a7, u+f0c5, u+f0c7, u+f0e5-f0e6, u+f0eb, u+f0f6-f0f8,
		u+f10c, u+f114-f115, u+f118-f11a, u+f11c-f11d, u+f133, u+f147, u+f14e, u+f150-f152, u+f185-f186, u+f18e, u+f190-f192, u+f196,
		u+f1c1-f1c9, u+f1d9, u+f1db, u+f1e3, u+f1ea, u+f1f7, u+f1f9, u+f20a, u+f247-f248, u+f24a, u+f24d, u+f255-f25b, u+f25d, u+f271-f274,
		u+f278, u+f27b, u+f28c, u+f28e, u+f29c, u+f2b5, u+f2b7, u+f2ba, u+f2bc, u+f2be, u+f2c0-f2c1, u+f2c3, u+f2d0, u+f2d2, u+f2d4, u+f2dc;
}
@font-face {
	font-family: 'FontAwesome';
	font-display: block;
	src: url(fa-v4compatibility.woff2) format('woff2'), url(fa-v4compatibility.ttf) format('truetype');
	unicode-range: u+f041, u+f047, u+f065-f066, u+f07d-f07e, u+f080, u+f08b, u+f08e, u+f090, u+f09a, u+f0ac, u+f0ae, u+f0b2, u+f0d0, u+f0d6,
		u+f0e4, u+f0ec, u+f10a-f10b, u+f123, u+f13e, u+f148-f149, u+f14c, u+f156, u+f15e, u+f160-f161, u+f163, u+f175-f178, u+f195, u+f1f8,
		u+f219, u+f27a;
}
