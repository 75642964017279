.promotion-banner .article-type-slide .bg {
    position: relative;
    padding-top: calc(350/1215*100%);
    display: block;
    width: 100%;
    height: auto;

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;

    }
}

.promotion-slider {
    position: relative;
    height: 100%;
    width: 100%;

}

.pro-img {
    display: block;
    padding-top: calc((350 / 1215) * 100%);
    position: relative;
    overflow: hidden;

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.promotion-slider .swiper-pagination.--second {
    position: absolute;
    bottom: 2rem;

    @include max($mobile) {
        bottom: 0.6rem;
    }
}

.promotion-banner .container {
    @include max($mobile) {
        padding: 0;
    }
}

.promotion-slider {}

.promotion-slider .swiper {
    --col-gap: 0;
}


.promotion-movie-wr {
    justify-content: space-between;
}

.promotion-it:nth-child(2) {
    width: calc(6.5/12*100%);

    @include max($mobile) {
        width: 100%;
    }
}

.promotion-it:nth-child(1) {
    width: calc(5.5/12*100%);

    @include max($mobile) {
        width: 100%;
    }
}

.promotion-it .sub-tittle {
    text-transform: uppercase;

    @include max(1200px) {
        font-size: 1.8rem;
    }
}

.promotion-it .desc {
    font-weight: 600;
}

.promotion-content {
    display: flex;
    flex-direction: column;
    gap: 2rem 0;

    @include max(768px) {
        gap: 1.2rem 0;
    }

}

.promotion-content .btn {
    width: 25rem;

    @include max($mobile) {
        width: 100%;
    }
}

.promotion-content .inner .list {
    list-style-type: disc;
    margin-left: 1.6rem;
}

.promotion-content .inner .tt {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;

    @include max(1024px) {
        margin-bottom: 0.8rem;
    }
}

.promotion-image {
    padding-top: calc(351/624*100%);
    display: block;
    width: 100%;
    height: auto;
    position: relative;

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.promotion-movie-list:nth-child(even) {

    @include min($mobile-min) {
        flex-direction: row-reverse;
    }
}


.promotion-movie-list {
    @include max($mobile) {
        flex-direction: column-reverse;
    }
}

.promotion-movie-list:last-child .promotion-it:first-child {
    @include max($mobile) {
        margin-bottom: 0;
    }
}


.promotion-movie-list:nth-child(odd) .promotion-it:nth-child(1) .promotion-content {
    @include min(2001px) {
        margin-right: 4.8rem;
    }
}

.promotion-movie-list:nth-child(even) .promotion-it:nth-child(1) .promotion-content {
    @include min(2001px) {
        margin-left: 4.8rem;
    }
}

.promotion-movie-list {

    @include max($mobile) {
        margin-bottom: 0;

        &:not(:last-child) {
            border-bottom: 0.1rem solid rgba(248, 250, 252, 0.1);
            padding-bottom: 0;
        }

        &:not(:first-child) {
            padding-top: 2rem;
        }

    }


}
.promotion .loyalty-program img{
  max-width: 60rem;
  margin: 0 auto 10rem;
  @include max($mobile){
    margin: 0 auto 5rem;

  }
}
.promotion-wrap{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 3rem;
  margin-bottom: 5rem;
  .promotion-item{
    .promotion-item__content{
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      .image{
        position: relative;
        max-width: 3.4rem;
        img{
          filter: brightness(0) saturate(100%) invert(98%) sepia(80%) saturate(2086%) hue-rotate(336deg) brightness(109%) contrast(91%);;
        }
      }
      .desc{
        line-height: 1;
      }
    }
  }
}