.swiper-slide {
    height: initial;
    overflow: hidden;
}

.rows {
    --col-gap: 2.4rem;
    --row-gap: 2.4rem;
    margin: 0 calc(var(--col-gap) / 2 * -1);

    @include max(1200px) {
        --col-gap: 1.6rem;
    }
}

.cols {
    padding: 0 calc(var(--col-gap)/2);
}

.swiper.rows {
    .col {
        margin-bottom: 0;
    }
}

.swiper-container {
    overflow: hidden;
}
.swiper-pagination{
  display: flex;
  justify-content: center;
  @include max($mobile){
    min-height: 6px;
  }
}
.swiper-pagination-bullet {
    background-color: #64748B;
    margin: 0 0.6rem !important;
    opacity: 1;
    width: 1.2rem;
    height: 1.2rem;
    position: relative;
    transition: all .3s ease;
    display: inline-block;
    border-radius: 100rem;
    cursor: pointer;

    @include max($mobile) {
        width: 0.6rem;
        height: 0.6rem;
        margin: 0 0.4rem !important;
    }
}

.swiper-pagination-bullet-active {
    position: relative;
    background-color: #F8FAFC;
}

.swiper-pagination {

    position: static;
    margin-top: 2.4rem;

    @include max($mobile) {
        margin-top: 2.4rem;
    }

    // bottom: -4.8rem;

    // @include max(1200px) {
    //     bottom: -3.2rem;
    // }
}
.web-movie-pagination.swiper-pagination {
  margin-top: 2.4rem;
}

.arrow-btn-next,
.arrow-btn-prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.2rem;
    z-index: 10;
    transition: all .2s;

    &:hover {
        color: $color-second;
    }

    @include min(2000px) {
        width: 4rem;
        height: 4rem;
    }

    @include max(1400px) {
        width: 4rem;
        height: 4rem;
        font-size: 2.4rem;
    }

    @include max($mobile) {
        display: none;
        width: 2.4rem;
        height: 2.4rem;
    }
}

.arrow-btn-next {
    right: -7rem;

    @include min(2000px) {
        right: 0;
    }

    @include max(1400px) {
        right: -3.2rem;
    }

    @include max(1280px) {
        right: 0;
    }
}

.arrow-btn-prev {
    left: -7rem;

    @include min(2000px) {
        left: 0;
    }

    @include max(1400px) {
        left: -3.2rem;
    }

    @include max(1280px) {
        left: 0;
    }
}

.swiper-pagination {
    &.--second {
        position: static;
        margin-top: 2.4rem;

        // @include max($mobile) {
        //     margin-top: 0;
        // }
    }
}