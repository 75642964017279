.detail .web-movie-box {
    &:hover {
        .attach {
            transform: translateY(0);
        }
    }
}

.detail-row {
    @include max($mobile) {
        --col-gap: 1.2rem;
    }
}

.detail .image {
    padding-top: calc(732/488*100%);
}

.detail-ct-h {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    @include max($res-mobile) {
        gap: 1.2rem;
    }
    @include max(460px) {
        gap: 0.8rem;
    }
}

.detail-ct-h .heading {
    @include max($res-mobile) {
        font-size: 2.4rem;
    }
    @include max($mobile) {
        font-size: 1.6rem;
    }
}

.detail-ct {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    @include max($res-mobile) {
        gap: 2.4rem;
    }
    @include max($tablet) {
        gap: 1.6rem;
    }
    @include max($mobile) {
        gap: 1.2rem;
    }
    @include max(460px) {
        gap: 0.8rem;
    }
}

.detail .web-movie-box .attach .age {
    align-items: center;
    justify-content: center;
    height: 7.6em;
    width: 7em;
    @include max($res-mobile) {
        height: 5.6em;
        width: 5em;
    }
    @include max($mobile) {
        width: 3.2rem;
        height: 3.4rem;
    }
}

.detail .web-movie-box .attach .type-movie {
    height: 7.6em;
    width: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max($res-mobile) {
        height: 5.6em;
        width: 5em;
    }
    @include max($mobile) {
        width: 3.2rem;
        height: 3.4rem;
    }
}

.detail .web-movie-box .attach .age .txt {
    font-size: 0.4em;
    @include max($mobile) {
        display: none;
        font-size: 0.3rem;
        width: fit-content;
    }
}

.detail .info-detail {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    @include max($res-mobile) {
        gap: 1.2rem;
    }
}

.detail .info-detail .info-item {
    display: flex;
    gap: 0 1.2rem;
    font-size: 1.8rem;
    font-family: 'Inter', sans-serif;
    @include max($res-mobile) {
        font-size: 1.6rem;
        gap: 0 0.8rem;
    }
    @include max($mobile) {
        font-size: 1rem;
        gap: 0 0.4rem;
    }
    &.limit-age .txt{
      background-color: $color-second;
      color: $color-black
    }
}

.detail .info-item .ic {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    position: relative;
    top: 0.2rem;
    @include max($res-mobile) {
        width: 1.8rem;
        height: 1.8rem;
        i {
            font-size: 1.6rem !important;
        }
    }
    @include max($mobile) {
        width: 1.6rem;
        height: 1.6rem;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.detail-ct .detail-ct-bd {
    @include max($mobile) {
        display: none;
    }
}

.detail-ct-bd .tt.sub-tittle {
    margin-bottom: 0.8rem;
    @include max($res-mobile) {
        font-size: 1.8rem;
    }
}

.detail-ct-bd .desc {
    margin-bottom: 2.4rem;
    @include max($res-mobile) {
        margin-bottom: 1.2rem;
        font-size: 1.4rem;
    }
}

.detail-ct-bd .ct {
    @include max($tablet) {
        .dt {
            overflow: hidden;
            position: relative;
            transition: all 0.3s ease-in-out;
        }
        input {
            &:checked~.dt {
                max-height: unset;
            }
            &:checked~.item-detail {
                .btn-drop-text {
                    .text-sm {
                        &:nth-child(1) {
                            display: none;
                        }
                        &:nth-child(2) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

.detail-ct-bd .ct .item-detail {
    @include min($tablet-min) {
        display: none;
    }
    @include max($tablet) {
        .btn-drop-text {
            .btn-control {
                cursor: pointer;
            }
            .text-sm {
                text-decoration: underline;
                text-underline-position: under;
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
    @include max($mobile) {
        .btn-drop-text {
            .text-sm {
                font-size: 1.2rem;
            }
        }
    }
}

.detail-ct-bd .dt {
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    @include max($res-mobile) {
        gap: 0.8rem;
        font-size: 1.6rem;
    }
}

.detail-ct-ft .video {
    display: flex;
    align-items: center;
    gap: 0 1rem;
    font-size: 2rem;
    text-decoration: underline;
    text-underline-position: under;
    @include max($res-mobile) {
        gap: 1.2rem;
        font-size: 1.8rem;
    }
    @include max($mobile) {
        gap: 0.4rem;
        font-size: 1.2rem;
    }
    .ic {
        width: 4rem;
        height: 4rem;
        flex-shrink: 0;
        border: .1rem solid rgba($color: white, $alpha: .5);
        border-radius: 100rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @include max($res-mobile) {
            width: 3.2rem;
            height: 3.2rem;
        }
        @include max($mobile) {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
}

.dt-mobile {
    @include min($mobile-min) {
        display: none;
    }
    @include max($mobile) {
        margin-top: 1rem;
    }
}

.dt-mobile .tt.sub-tittle {
    @include max($mobile) {
        font-size: 1.6rem;
    }
}

.dt-mobile .desc {
    margin-bottom: 1.2rem;
    font-size: 1.4rem;
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.dt-mobile .ct .dt {
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.shtime {
    padding-bottom: var(--sp-sc);
}

.shtime .heading {
    margin-bottom: 2.4rem;
    text-align: center;
}

.shtime-body .heading {
    margin-bottom: 0;
}

.shtime-heading {
    margin-bottom: 4rem;
}

.shtime .time-list {
    @include max($tablet) {
        width: 100%;
    }
    @include max($mobile) {
        // max-width: fit-content;
        gap: 0.8rem;
        margin: 0 auto;
        overflow-x: auto;
        white-space: nowrap;
        scroll-snap-type: x mandatory;
        &::-webkit-scrollbar {
            width: 0.5rem;
            display: none;
        }
    }
}

.shtime .time-list .box-time {
    padding: 1.6rem 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    justify-content: center;
    align-items: center;
    border-radius: $radius-1;
    border: 0.1rem solid $color-second;
    min-height: 8.3rem;
    width: fit-content;
    height: fit-content;
    transition: all .2s;
    cursor: pointer;
    width: 100%;
    height: 100%;
    @include max($res-mobile) {
        min-height: 7.3rem;
    }
    @include max($mobile) {
        min-height: 5.2rem;
        padding: 0.8rem 0.4rem;
    }
}

.time-list .box-time .date {
    font-family: var(--font-title);
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 0.02rem;
    color: $color-second;
    @include max($res-mobile) {
        font-size: 1.8rem;
    }
    @include max($mobile) {
        font-size: 1.4rem;
    }
}

.time-list .box-time .day {
    color: $color-second;
    @include max($res-mobile) {
        font-size: 1.2rem;
    }
    @include max($mobile) {
        font-size: 1rem;
    }
}

.time-item {
    @include max($mobile) {
        scroll-snap-align: start;
    }
}

.time-item .box-time {
    &:hover:not(.disable) {
        border: 0.1rem solid $color-second;
    }
}

.time-item.active .box-time {
    border: 0.1rem solid $color-second;
    .date {
        transition: all .2s;
        color: $color-second;
    }
    .day {
        transition: all .2s;
        color: $color-second;
    }
}

.shtime-slider {
    overflow: hidden;
    .swiper {
        --col-gap: 3.2rem;
        width: fit-content;
        &-container {
            display: flex;
            justify-content: center;
        }
        @include max($res-mobile) {
            --col-gap: 1.2rem;
        }
        &-slide {
            width: 13.5rem;
            @include max($mobile) {
                width: 10rem;
            }
            &.active {
                .box-time {
                    border-color: $color-second;
                    color: $color-text;
                    pointer-events: none;
                    background-color: $color-second;
                    .date {
                        color: #663399;
                    }
                    .day {
                        color: #663399;
                    }
                }
            }
            &.disable {
                .box-time {
                    border-color: #64748B !important;
                    color: #64748B !important;
                    cursor: not-allowed;
                    pointer-events: none;
                }
            }
        }
    }
}

.shtime-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.4rem;
    width: calc(8/12*100%);
    @include max($tablet) {
        width: calc(11/12*100%);
    }
    @include max($mobile) {
        width: 100%;
        margin-bottom: 1.6rem;
    }
}

.shtime-ft {
    margin-left: auto;
    margin-right: auto;
    width: calc(8/12*100%);
    @include max($tablet) {
        width: calc(11/12*100%);
    }
    @include max($mobile) {
        width: 100%;
    }
}

.shtime-ft .cinestar-item {
    border-radius: 0.4rem;
    background: #64748B;
    .icon {
        transition: all .3s ease;
    }
}

.shtime-ft .cinestar-item .cinestar-heading {
    padding: 2.4rem;
    @include max($mobile) {
        padding: 1rem;
    }
}

.shtime-ft .cinestar-item .cinestar-body {
    padding: 0 2.4rem 2.4rem 2.4rem;
    @include max($mobile) {
        padding: 0 1rem 1rem 1rem;
    }
}

.shtime-ft .cinestar-item.active {
    background: $color-pri;
    .icon {
        transform: rotate(180deg);
    }
    .tittle {
        color: $color-second;
    }
}

.shtime-ft .cinestar-list {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.shtime-ft .cinestar-item .tittle {
    color: $color-text;
    font-size: 2.4rem;
    font-weight: 600;
    @include max($res-mobile) {
        font-size: 1.8rem;
    }
    @include max($mobile) {
        font-size: 1.6rem;
    }
}

.shtime-ft .cinestar-item .addr {
    color: $color-white;
    @include max($mobile) {
        font-size: 1.4rem;
    }
}

.shtime-ft .cinestar-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.shtime-ft .cinestar-item .item-info {
    margin-top: 2.4rem;
    @include max($mobile) {
        margin-top: 1rem;
    }
}

.shtime-ft .cinestar-item .list-time {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 1.2rem;
    flex-wrap: wrap;
}

.shtime-ft .cinestar-item .item-time {
    padding: 0.8rem 1.2rem;
    border-radius: $radius-1;
    border: 0.5px solid #F8FAFC;
    line-height: 100%;
    transition: all .3s;
    cursor: pointer;
    &:hover {
        border: 0.5px solid $color-second;
        color: $color-second;
    }
    @include max($mobile) {
        padding: 0.6rem .8rem;
    }
}

.shtime-ft .cinestar-item .item-time.active {
    border: 0.5px solid $color-second;
    color: $color-second;
    pointer-events: none;
}

.shtime-ft .cinestar-item .item-time.disable {
    border: 0.5px solid #64748B;
    color: #64748B;
}

.cinestar-body {
    display: none;
}

.dt-food {
    padding-bottom: var(--sp-sc);
}

.dt-food-body .title {
    font-size: 2.4rem;
    color: $color-second;
    text-align: center;
    margin-bottom: 2.4rem;
    @include max($tablet) {
        margin-bottom: 1.2rem;
        font-size: 1.8rem;
    }
    @include max($mobile) {
        font-size: 1.6rem;
        font-weight: 700;
    }
}

.dt-food-heading {
    text-align: center;
}

.dt-food .food-box {
    flex-direction: row;
    @include max($mobile) {
        flex-direction: column;
    }
}

.dt-food .food-box .image {
    padding-top: calc(160/127*100%);
}

.dt-food .food-box .img {
    width: calc(1.5/4*100%);
    overflow: hidden;
    @include max($mobile) {
        width: 100%;
    }
}

.dt-food .food-box .content .content-top {
    align-items: flex-start;
    @include max($mobile) {
        align-items: center;
    }
}

.dt-food .food-box .image::before {
    display: none;
}

.dt-food .content-top .name {
    text-transform: uppercase;
    text-align: left;
}

.dt-food-body .dt-item {
    &:not(:last-child) {
        margin-bottom: 4rem;
        @include max($tablet) {
            margin-bottom: 1.4rem;
        }
    }
}

.dt-food .food-box .content {
    height: 100%;
}

.dt-food .food-box .content-top .desc {
    @include max($tablet) {
        font-size: 1.2rem;
    }
    @include max($mobile) {
        font-size: 1rem;
    }
}

.dt-food .food-box .content-top .sub-title {
    text-align: start;
    @include max($tablet) {
        font-size: 1.4rem;
    }
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.dt-food .count {
    transition: all .3s ease;
    @include max($tablet) {
        padding: 0.8rem 0.8rem 0.4rem;
        width: 100%;
    }
    &:hover {
        background: $color-second;
    }
}

.dt-food .combo-list {
    @include max($tablet) {
        --col-gap: 1.2rem;
        --row-gap: 1.2rem;
    }
}

.dt-bill .list {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    @include max($mobile) {
        gap: .4rem;
    }
}

.dt-bill .item {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    flex-wrap: wrap;
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.dt-bill .bill-wr {
    @include max($tablet) {
        width: calc(11/12*100%);
        padding: 2.4rem
    }
    @include max($mobile) {
        padding: 2rem;
        width: 100%;
        flex-direction: column;
    }
}

.dt-bill .bill-right {
    @include max($tablet) {
        width: calc(3/11*100%);
        padding-left: 2.4rem
    }
    @include max($mobile) {
        width: 100%;
        padding-left: unset;
        border-left: unset;
        border-top: unset;
        padding-top: unset;
    }
}

.dt-bill .bill-right .price {
    @include max($mobile) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.dt-bill .bill-right .price .txt {
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.dt-bill .bill-wr .name-combo {
    letter-spacing: 0.024rem;
    font-size: 2.4rem;
    @include max($tablet) {
        font-size: 1.8rem;
    }
    @include max($mobile) {
        font-size: 1.8rem;
    }
    @include max($mobile) {
        font-size: 1.6rem;
    }
}

.dt-bill {
    padding-bottom: var(--sp-sc);
}

.ticket .ticket-ft {
    font-size: 1.4rem;
    margin-top: 2rem;
}

.ticket .ticket-ft .dot {
    color: #F03;
}

.ticket {
    padding-bottom: var(--sp-sc);
}

.ticket .food-box .content {
    flex-direction: row;
    justify-content: space-between;
    padding: 1.2rem;
    @include max($res-mobile-min) {
        padding: unset;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    @include max($mobile) {
        flex-direction: row;
    }
}

.ticket .combo-item {
    @include max($mobile) {
        width: 100%;
    }
    // &:not(:last-child) {
    //     margin-bottom: 1.2rem;
    // }
}

.ticket .food-box .content-bottom {
    margin-bottom: auto;
}

.ticket.food-box .content-top {
    text-align: left;
}

.ticket.food-box .content-top .name {
    text-transform: uppercase;
    font-weight: 700;
}

.ticket .food-box .content-top .desc {
    font-size: 1rem;
}

.ticket .count {
    width: 10rem;
    &:hover {
        background: $color-second;
    }
}

.ticket .food-box .content-top .name {
    text-transform: uppercase;
}

.ticket .food-box .content-top {
    align-items: flex-start;
    text-align: left;
    @include max($res-mobile-min) {
        align-items: center;
        text-align: center;
    }
    @include max($mobile) {
        align-items: flex-start;
        text-align: left;
    }
}

.shtime-slider {
    .swiper-slide.active {
        pointer-events: none;
    }
}
.detail .wrap-btn-trailer{
  display: inline-block;
}