.seat {
    padding-bottom: var(--sp-sc);
}



.seat-list {
    flex-wrap: nowrap;
    margin: 0 auto;
    --col-gap: 0.8rem;

    @include max($mobile) {
        --col-gap: 0.4rem;
    }
}

.seat-item {
    margin-bottom: 0;
}


.seat .seat-input {
    display: none;
}


.seat-input+.seat-label {
    position: relative;
    display: block;
    width: 3.8rem;
    height: 2.8rem;
    transition: all 0.3s ease;
    cursor: pointer;

    @include max($res-mobile-min) {
        width: 2.8rem;
        height: 2.2rem;
    }

    @include max($mobile) {
        width: 1.6rem;
        height: 1.2rem;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(/assets/images/seat-single.svg) no-repeat center top;
        background-size: contain;
        transition: all 0.3s ease;
    }
}

.seat-input.seat-vip+.seat-label {
    &:after {
        filter: brightness(0) saturate(100%) invert(76%) sepia(18%) saturate(6547%) hue-rotate(335deg) brightness(103%) contrast(101%);
    }
}

.seat-input.seat-couple+.seat-label {
    width: 5.8rem;
    cursor: pointer;

    @include max($res-mobile-min) {
        width: 4.8rem;
    }

    @include max($mobile) {
        width: 2.9rem;
    }

    &:after {
        background: url(/assets/images/seat-couple.svg) no-repeat center top;
        background-size: contain;
    }
}

.seat-input.occupied:disabled+.seat-label,
.seat-input.occupied+.seat-label {
    cursor: default;
    pointer-events: none;

    &:after {
        filter: brightness(0) saturate(100%) invert(31%) sepia(6%) saturate(2041%) hue-rotate(176deg) brightness(97%) contrast(87%);
    }
}

.seat-input:checked+.seat-label,
.seat-input.selected+.seat-label {
    &:after {
        filter: brightness(0) saturate(100%) invert(98%) sepia(80%) saturate(2086%) hue-rotate(336deg) brightness(109%) contrast(91%);
    }
}

.seat-input:not(.selected)+.seat-label,
.seat-input:not(.occupied)+.seat-label {
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        filter: brightness(0) saturate(100%) invert(98%) sepia(80%) saturate(2086%) hue-rotate(336deg) brightness(109%) contrast(91%);
    }
}

.seat-note {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.8rem;
    @include max($mobile){
      margin-top: 1rem;
    }
}

.seat-note .note-it {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    @include max($mobile) {
        font-size: 1.4rem;
        @include gap(.8rem, 2);
       
    }
    &.note-it-couple{
      .image{
        width: 5.5rem;
      }
    }
}

.seat-note .note-it .image {
    width: 3.2rem;
    flex-shrink: 0;

    @include max($mobile) {
        width: 2.4rem;
    }

    img {
        width: 100%;
    }
}


.seat-block{
  --w: 110rem;
 
  &.--sm{
    --w: 70rem;
    @include max($mobile){
      --w: 42rem;
    }
  }
  &.--full{
    --w: 100%;
   @include min($res-mobile-min){
      .seat-td:not(.seat-couple) {
        .seat-wr{
          padding: .6rem;
        }
      }
      // .seat-td.seat-couple{
      //   .seat-wr{
      //     padding: .6rem;
      //   }
      // }
      // .seat-name{
      //   bottom: -.9rem;
      // }
    }
  }
  &.--large{
    @include min($mobile){
      .seat-td.seat-couple{
        .seat-wr{
          padding: 0;
        }
      }
    }
    @include min($res-mobile-min){
      .seat-td.seat-couple{
        .seat-wr{
          padding: .6rem;
        }
      }
      .seat-td:not(.seat-couple) {
        .seat-wr{
          padding: .4rem;
        }
      }
    }
  }
  &.--nor{
    @include min($tablet){
      .seat-td.seat-couple{
        .seat-wr{
          padding: .6rem 2rem;
        }
      }
    }
  }
  @include max(800px){
    --w: 80rem;
  }
}


.seat-main {
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  max-width: var(--w);

  &::-webkit-scrollbar {
      width: 0.5rem;
      display: none;
  }
}


.seat-screen {
    display: block;
    width: fit-content;
    height: auto;
   margin: 0 auto;
   max-width: 100%;
   max-width: var(--w);
    margin-bottom: 4.8rem;

    @include max($mobile-min) {
        margin-bottom: 4.5rem;
    }
}

.seat-screen .txt {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
    display: block;
    margin-top: -2.4rem;
    @include min(701px){
      margin-top: -8rem;
      font-size: 2.4rem;
    }
}

.seat-table{
  // width: var(--w);
  // max-width: 100%;
  // margin: 0 auto;
  margin-bottom: 4rem;
  // overflow-x: auto;
  // overflow-y: hidden;
  // @include scroll-hidden;
  padding-bottom: 1rem;
  @include max(800px){
    max-width: 75rem;
    width: 75rem;
  }
  table{
    width: 100%;
    
    tr{
      display: flex;
    }
    td{
      min-width: 30px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.seat-choosing{
  filter: brightness(0) saturate(100%) invert(82%) sepia(85%) saturate(423%) hue-rotate(357deg) brightness(92%) contrast(107%);
}
.seat-disable{
  filter: brightness(0) saturate(100%) invert(31%) sepia(6%) saturate(2041%) hue-rotate(176deg) brightness(97%) contrast(87%);
  
}
.seat-wr{
  padding: 1rem;
  cursor: pointer;
  position: relative;
  @include max($lg-min){
    padding: .5rem;
  }
  @include max($tablet){
    padding: .2rem;
  }
  img{
    transition: none;
  }
  &.choosing{
    color: $color-second;
    img{

      filter: brightness(0) saturate(100%) invert(82%) sepia(85%) saturate(423%) hue-rotate(357deg) brightness(92%) contrast(107%);
    }
  }
  &.booked{
    pointer-events: none;
    cursor: default;
    color: #475569;
    img{
      filter: brightness(0) saturate(100%) invert(32%) sepia(9%) saturate(1380%) hue-rotate(176deg) brightness(94%) contrast(88%);
    }
    .seat-name{
      color: #979ca3;
    }
  }
}
.seat-name-row{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.seat-couple{
  flex: 2 !important;
  .seat-wr{
    padding: .6rem 2rem;
    margin-top: .4rem;
    width: 100%;
    @include max($lg-min){
      padding: .6rem 1.2rem;
    }
    @include max(900px){
      padding: .6rem .7rem;
    }
    @include max($mobile){
      padding: .6rem .5rem;
    }
  }
  // .seat-name{
    // bottom: -.9rem;
    // @include max($mobile){
    //   bottom: .3rem;
    //   color: white;
    // }
  // }
}
.seat-name{
  position: absolute;
  @include posi-horizontal;
  font-family: var(--font-title);
  letter-spacing: .07rem;
  // bottom: -.5rem;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $color-pri;
  z-index: 2;
  font-size: 1.2rem;
  @include max($mobile){
    font-size: 1rem;
    // color: $color-third;
    // bottom: 1.5vw;
  }
}
.minimap-container {
  @include scroll-hidden;
  position: relative;
  @include max($mobile){
    height: 40vh !important;
  }
}
.minimap{
  top: var(--sz-hd);
  z-index: 60 !important;
  background: #000 !important;
  @include min($mobile-min){
    display: none;
  }
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: .1s ease-in-out;
  &.show{
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
  }
}
.seat-table{
  @include min($mobile){
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar:horizontal {
        height: .7rem;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $radius-1;
    }
  }
}
.minimap-viewport{
  border:.1rem solid $color-third;
}
// .seat-td.seat-vip{
//   @include max($mobile){
//     .seat-name{
//       color: white;
//     }
//   }
// }

.seat-indicator-scroll {
  position: relative;

  .img-indicator-scroll {
    @include max($mobile){
      display: block !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60vw;
      height: auto;
      z-index: 2;
      background: rgba(0, 0, 0, 0.7);
      padding: 10px 30px;
    }

    display: none;
  }
}

//red border wrap seat-vip
.seat-vip-row-middle-first{
  border-left: 1px solid red;
}
.seat-vip-row-middle-last {
  border-right: 1px solid red;
}
.seat-td.seat-vip-row-1{
  border-top:  1px solid red;
}
.seat-vip-row-last{
  border-bottom: 1px solid red;
}
.first-vip{
  border-left: 1px solid red;
  &.seat-vip-row-1{
    border-top-left-radius: .8rem;
  }
  &.seat-vip-row-last{
    border-bottom-left-radius: .8rem;
  }
}
.last-vip{
  border-right: 1px solid red;
  &.seat-vip-row-1{
    border-top-right-radius: .8rem;
  }
  &.seat-vip-row-last{
    border-bottom-right-radius: .8rem;
  }
}
tr:has(.seat-td.seat-vip-row-1) td{
  margin-top: .3rem;
  @include max($mobile){
    margin-top: .2rem;
  }
}
tr:has(.seat-td.seat-vip-row-last) td{
  padding-bottom: .4rem;
  @include max($mobile){
    margin-bottom: .2rem;
  }
}
.seat-special-top{
  border-top:  1px solid red;
}
.seat-special-bot{
  border-bottom:  1px solid red;
}