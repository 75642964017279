.back-to-top {
    position: fixed;
    background: transparent;
    bottom: calc(var(--size-mobile-nav-bt) + 1.6rem);
    right: 2rem;
    width: 5.8rem;
    height: 5.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    transition: all .3s ease-in-out;
    transform: translateX(3rem);
    opacity: 0;
    visibility: hidden;

    @include min(2001px) {
        width: 4.8rem;
        height: 4.8rem;
    }

    @include max(1200px) {
        width: 4.8rem;
        height: 4.8rem;
    }

    @include max(500px) {
        right: 1rem;
    }

    &.active {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }

    .progress-value {
        background: #06102B;
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all .3s ease-in-out;

        @include max(1200px) {
            font-size: 24px;
            width: calc(100% - 0.8rem);
            height: calc(100% - 0.8rem);
        }

        .ic {
            position: relative;
            transition: all .3s;
            z-index: 10;
            @include fz-18;
            color: $color-text;
        }
    }
}