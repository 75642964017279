.mobile {
    @include min($mobile-min) {
        display: none;
    }

    position: fixed;
    z-index: calc(var(--z) - 4);
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    padding-top: var(--sz-hd);

    background-color: $color-gray-2;
    transition: all .2s linear;
    @include hidden(0, 0);

    &.open {
        @include show;
        transition-delay: .3s;
    }
}

.mobile-con {
    width: 100%;
    height: 100%;
    padding-left: var(--pd-container);
    padding-right: var(--pd-container);
    overflow-y: auto;
    @include scroll-hidden;
}

.mobile-wr {
    padding-bottom: 3rem;
    min-height: 100%;
}

.mobile-nav {
    padding: 3rem 0;
    border-bottom: 0.2rem solid #64748B;
}

.mobile-nav .menu-nav .menu-list {
    display: flex;
    flex-direction: column;
    gap: 1.6rem 0;

}

.mobile-nav .menu-nav .menu-link {
    text-transform: uppercase;
    font-family: var(--font-title);
    font-size: 2rem;
    color: #CBD5E1;
}

.mobile .mobile-content {
    margin-top: 3.2rem;
    color: #94A3B8;
}

.mobile .mobile-content .menu-list {
    display: flex;
    flex-direction: column;
    gap: 1.2rem 0;
}

.mobile .mobile-content .menu-link {}

.mobile .mobile-content .mobile-item {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 1.6rem;
}

.mobile-navbar-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #639;
    height: var(--size-mobile-nav-bt);
    z-index: calc(var(--z) - 6);

    @include min($mobile-min) {
        display: none;
    }

    .menu-nav {
        height: 100%;

        .menu-list {
            height: 100%;
        }

        .menu-item {
            flex: 1;
            text-align: center;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .menu-link {
            position: relative;
            top: .4rem;
        }
    }

    .menu-icon {
        width: 2.4rem;
        margin-bottom: .8rem;
    }
    .navbar-bottom-txt{
      @include max(325px){
        display: none;
      }
    }
    @include max(325px){
      --size-mobile-nav-bt: 5.5rem;
      .menu-icon{
        padding-bottom: .8rem;
      }
      .navbar-bottom-txt{
        display: none;
      }
    }
}

.mobile-navbar-bottom .menu-list {
    display: flex;
    gap: 1rem;
}


.mobile-navbar-bottom .menu-icon {
    display: block;
    margin: 0 auto;

    img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(19%) saturate(6820%) hue-rotate(178deg) brightness(107%) contrast(88%);
    }
}

.mobile-navbar-bottom .menu-item {

    &.active,
    &.current-menu-item {
        >a {
            color: $color-second;

            img {
                filter: brightness(0) saturate(100%) invert(85%) sepia(96%) saturate(6287%) hue-rotate(330deg) brightness(105%) contrast(106%);
            }
        }
    }
}


.mobile-navbar-bottom .menu-link {
    @include fz-13;
    transition: all .2s;

    @include max(435px) {
        font-size: 1.15rem;
    }
}

.mobile-navbar-bottom .menu-item:last-child .menu-icon {
    border-radius: 100rem;

    img {
        object-fit: cover;
        filter: none;
    }
}


// Css menu mobile

.mobile-nav {
    .menu-item {
        &.current-menu-item {
            >a {
                color: $color-second;
            }
        }
    }
}

.desktop {
  display: none;
}


@media screen and (min-width: $mobile-min) {
  .desktop {
    display: block;
  }

  .display-mobile {
    display: none;
  }
}
