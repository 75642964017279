.checkout .process {
    display: flex;
    // align-items: center;
    gap: 5.6rem;
    font-family: var(--font-title);
    text-transform: uppercase;
    font-size: 2rem;

    @include max($mobile) {
        font-size: 1.2rem;
        gap: 3.2rem;
    }

    @include max(360px) {
        gap: 2.4rem;
    }

    @include max($mobile) {
        width: 100%;
    }
}

.checkout .heading {
    text-align: left;
}

.form-cus .checkbox .checkbox-label {
    @include max(340px) {
        font-size: 1.4rem;
    }
}

.process .process-item {
    &:not(:first-child) {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            left: -0.8rem;
            top: 50%;
            transform: translate(-100%, -50%);
            height: 0.2rem;
            width: 4rem;
            background: #D9D9D9;

            @include max($mobile) {
                width: 1.6rem;
            }

            @include max(360px) {
                width: 1.2rem;
            }
        }
    }

    @include max($mobile) {
        width: calc(100% / 3);
    }
}

.process .process-item .link {
    display: flex;
    flex-direction: column;
}

.process .process-item .link br {
    display: none;

    @include max(460px) {
        display: block;
    }
}

.process .process-item.active {
    color: $color-second;

    &::after {
        background: $color-second;
    }

}

.checkout-cus-right,
.checkout-cus-left {
    @include max($mobile) {
        width: 100%;
    }
}

.form-cus .form .form-list .btn-submit {
    margin-top: 2rem;

    @include max($mobile) {
        display: none;
    }
}

.checkout-cus-left .form {
    background-color: transparent;
}

.form-cus .form .form-label {
    color: $color-text;
}

.checkout-cus-left .form {
    padding: 0;
}

.checkbox .link {
    text-decoration: underline;
    text-underline-position: under;
    transition: all .2s;

    &:hover {
        color: $color-second;
    }
}

.checkout-cus-left .form .form-list {
    margin-top: 0;
}

.form-checkout-cus {
    padding: 2.4rem;
    background: $color-four;

    @include max($tablet) {
        padding: 1.2rem;
    }
}

.form-checkout-cus .tt {
    color: $color-second;

    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.form-checkout-cus .ct {
    font-size: 2rem;

    @include max($tablet) {
        font-size: 1.8rem;
    }

    @include max($mobile) {
        font-size: 1.4rem;
    }
}

.form-checkout-cus .dt {
    font-size: 1.4rem;

    @include max($mobile) {
        font-size: 1rem;
    }
}

.form-checkout-cus .cinestar-br .dt {
    @include max($mobile) {
        font-size: 1.4rem;
    }
}

.form-checkout-cus .cinestar-br .dt {
    @include max($mobile) {
        display: none;
    }
}

.form-checkout-cus .inner-info:nth-child(1) {
    font-family: var(--font-title);
    text-transform: uppercase;
    font-size: 2rem;

    @include max($mobile) {
        font-size: 1.6rem;
        letter-spacing: 0.16px;
    }
}

.form-main {
    gap: 1.2rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.2rem;

    @include max($mobile) {
        gap: 0.8rem;
    }
}

.form-main .inner-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:1rem 2.4rem;
}

.form-main .inner-info.per-2 {
    width: 25%;
}

.form-footer .inner-info-row {
    display: flex;
    justify-content: space-between;
    font-family: var(--font-pri);
}

.form-footer .inner-info-row .ct {
    font-size: 2.6rem;
    font-weight: 600;

    @include max($mobile) {
        font-size: 2.4rem;
    }
}

.form-footer {
    border-top: 0.2rem dashed #F8FAFC;
    padding-top: 1.2rem;
}

.form-cus .btn {
    @include max($mobile) {
        display: none;
    }
}

.checkout-back {
    align-items: center;
}

.checkout-back .ic {
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;

    &.ani-s {
        animation: ani-moveDown 1.2s linear .1s infinite;
        margin-left: -1.6rem;
    }

    &.ani-f {
        animation: ani-moveDown 1.2s linear .3s infinite;

    }
}

.checkout-back .txt {
    transition: all .2s;
}

.checkout-back {
    display: none;
    opacity: 0;
    transition: all .2;
    align-items: center;
    gap: 0.4rem;
    margin-bottom: 2.4rem;
    // color: $color-second;

    @include max($mobile) {
        display: flex;
        opacity: 1;
    }

    &:hover {
        color: $color-second;

        .ic {
            img {
                filter: brightness(0) saturate(100%) invert(99%) sepia(34%) saturate(6925%) hue-rotate(333deg) brightness(97%) contrast(98%);
            }
        }
    }
}