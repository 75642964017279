@import url("./fonts/Josefin_Sans/stylesheet.css");
@import url("./fonts/Anton/stylesheet.css");
@import url("./fonts/Inter/stylesheet.css");
// @import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;

  @media screen and (min-width: 2000px) {
    font-size: 100%;
  }


  @media screen and (min-width: 2025px) {
    // Case zoom 90% lớn hơn 100% 
    font-size: 10px;
  }
}

body {
  font-family: var(--font-pri);
  font-size: 16px;
  line-height: 1.55;
  font-weight: 400;
  color: $color-text;
  overflow: hidden auto;
  min-height: 100vh;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -10;
    background-color: #0F172A;
  }

  @media screen and (min-width: 2000px) {
    font-size: 20px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

button,
select {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  cursor: pointer;

}

button {
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.app-main,
.main {
  padding-top: var(--sz-hd);
  position: relative;
  min-height: 68vh;
}

.container {
  width: 100%;
  max-width: var(--w-container);
  height: 100%;
  margin: 0 auto;
  padding: 0 var(--pd-container);
}

.no-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

.cursor-def {
  cursor: default !important;
}

iframe {
  vertical-align: middle;
}


img {
  width: 100%;
  height: auto;
  display: block;
  transition: all .2s;
}