.side {
    &-overlay {
        @include full-fixed;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.8);
        @include transition(0.3s, ease);
        opacity: 0;
        visibility: hidden;
        transform: translateX(110%);
        top: 0;

        &.open {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }

        // @include min($res-mobile-min) {
        //     display: none;
        // }
    }
}

.block-check {
    position: fixed;
    top: 50%;
    transform: translate(50%, -50%);
    background-color: $color-white;
    width: fit-content;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 0.4rem;
    overflow: hidden;
    right: 50%;
    z-index: 100;
    overflow-y: auto;
    padding: 3.6rem;
    max-width: 66rem;

    width: fit-content;
    opacity: 0;
    visibility: hidden;

    @include max($mobile) {
        padding: 1.2rem;
        min-width: calc(100% - 3rem);
    }

    &.open {
        // display: block;
        visibility: visible;
        opacity: 1;
        animation-delay: .2s;
        animation: fadeIn 1s ease;
    }
}

.block-content {
    display: none;
    opacity: 0;
    transition: all .2s;
}

.block-content.open {
    display: block;
    opacity: 1;
}

.block-cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(106deg, #639 0%, #36C 102.69%);
    overflow: hidden;
    z-index: 400;
    overflow-y: auto;
    padding: 4.8rem;
    visibility: hidden;
    opacity: 0;

    @include max($res-mobile) {
        padding: 2.4rem;
    }

    &.open {
        visibility: visible;
        opacity: 1;
        animation-delay: .2s;
        animation: fadeIn 1s ease;
    }
}

.cookie-content {
    display: flex;
    gap: 28.4rem;

    @include max(1400px) {
        gap: 3.2rem;
    }

    @include max($res-mobile) {
        gap: 2.4rem;
    }

    @include max($mobile) {
        flex-direction: column;
    }
}

.cookie-txt {
    @include max($mobile){
        font-size: 1.4rem;
    }
}


.cookie-action {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
}

.cookie-action .btn {
    min-width: 32.4rem;
    width: 100%;

    @include max($res-mobile) {
        min-width: 15rem;
    }
}