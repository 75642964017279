.page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    font-family: 'Inter', sans-serif;

    >li {
        &:not(:first-child) {
            margin-left: 0.8rem;

            @include max(376px) {
                margin-left: 0.12rem;
            }
        }

        &>.page-numbers {
            width: 4rem;
            height: 4rem;
            border-radius: 0.4rem;
            display: flex;
            @include mid-flex;
            background-color: $color-white;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            color: #212B36;
            @include fz-14;
            font-weight: 400;
            transition: 0.4s;

            &::after {
                position: absolute;
                content: "";
                width: 0;
                height: 100%;
                top: 0;
                right: 0;
                background: $color-pri;
                transition: all 0.4s ease;
                z-index: -1;
                pointer-events: none;

            }

            @include max(1200px) {
                width: 3rem;
                height: 3rem;
                font-size: 1.4rem;
            }

            @include hover {
                &:not(.dot) {
                    color: $color-white;

                    &::after {
                        left: 0;
                        width: 100%;
                    }
                }
            }

            &.current {
                color: $color-white !important;
                font-weight: 600;

                &::after {
                    left: 0;
                    width: 100%;
                }
            }

            &.dot {
                opacity: 0.5;
                background: #919EAB;
            }

            &.next,
            &.prev {
                transition: all .4s linear;
                color: #C4CDD5;
                box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.10);
            }

          

            i {
                font-weight: 400;
            }
        }
    }
}