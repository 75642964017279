@keyframes shine {
    from {
        mask-position: 150%;
    }

    to {
        mask-position: -50%;
    }
}

@keyframes grows {
    0% {
        opacity: 1;
        transform: scale(0.9);
    }

    100% {
        opacity: 0;
        transform: scale(1.4);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(2.5rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-down {
    from {
        opacity: 0;
        transform: translateY(-2.5rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-top {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes fade-exit {

    to {
        opacity: 0;
        transform: translateY(-100%);

    }
}

@keyframes fade-top-2 {
    from {
        opacity: 0;
        transform: translateY(4rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);

    }
}


@keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 0.8;
    }
}

@keyframes fade-in-fwd-2 {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes swing-in-bottom-bck {
    0% {
        transform: rotateX(-40deg);
        transform-origin: bottom;
        opacity: 0;
    }

    100% {
        transform: rotateX(0);
        transform-origin: bottom;
        opacity: 1;
    }
}

@keyframes ani-moveDown {
    0% {
        opacity: 0;
    }
    
    33% {
        opacity: 1;
    }
    
    66% {
        opacity: 0;
    }
    
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}

@keyframes steam {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}