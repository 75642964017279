.contact {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: #0F172A;
        z-index: -20;
        pointer-events: none;
    }
}

.contact-branch .lt-social {
    display: flex;
    align-items: center;
    gap: 1.8rem;
    margin-bottom: 2.4rem;

    @include max($mobile) {
        margin-bottom: 1.6rem;
    }
}


.contact-branch .lk-social {
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    transition: all .2s;

    &:hover {
        transform: translateY(-0.5rem) scale(1.2);
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.contact-main-body {
    width: calc(6/12*100%);

    @include max(768px) {
        width: calc(8/12*100%);
    }

    @include max($mobile) {
        width: 100%;
    }
}

.contact-branch-body {}

.contact-branch-left .image {
    display: block;
    width: 100%;
    height: auto;
    position: sticky;
    top: var(--sz-hd);
}

.career-item-footer .btn {
    display: inline-block;
    pointer-events: visible;
}

.branch-heading.address-box-head .icon {
    display: block;
}

.branch-item .address-box {
    pointer-events: visible;
}

.branch-item.address-item {
    &:not(:last-child) {
        margin-bottom: 2.4rem;

        @include max(768px) {
            margin-bottom: 1.6rem;
        }
    }

    @include max(768px) {
        width: 100%;
    }
}

.branch-item .sub-tittle {
    @include max($mobile) {
        font-size: 1.8rem;
    }
}

.branch-item .link .txt {
    @include max($mobile) {
        font-size: 1.4rem;
    }
}

.branch-item-body {
    display: none;
}

.branch-item-body .desc {
    margin-top: 1.6rem;
}

.branch-item.address-item .btn {
    @include max(1200px) {
        padding: 0.8rem 1.2rem;
    }
}

.contact-branch-body {
    @include max($mobile) {
        flex-direction: column-reverse;
    }
}

.contact-branch-left {
    @include max($mobile) {
        width: 100%;
    }
}

.contact-branch-right {
    @include max($mobile) {
        width: 100%;
    }
}

.branch-item.address-item .list {
    display: block;
}