// Library
@import url("./library/fontawesome/fontawesome/fontawesome.min.css");


// Core
@import "./core/reset";
@import "./core/mona";
@import "./core/variable";
@import "./core/font";
@import "./core//base";
@import "./core/animation";
@import "./core/mixin";
@import "./core/flex";

// Component
@import "./components/header";
@import"./components/footer";
@import"./components/form";
@import"./components/burger";
@import"./components/mobile";
@import"./components/select2";
@import"./components/scroll";
@import"./components/backtotop";
@import"./components/404";
@import"./components/loading";

// Layout
@import "./layouts/btn";
@import "./layouts/text";
@import "./layouts/duaration";
@import "./layouts/page-num";
@import "./layouts/swiper";

// Pages
@import "./pages/forget-pass-success";
@import "./pages/update-pass";
@import "./pages/forget-password";
@import "./pages/cinestar-system-detail";
@import "./pages/career-detail";
@import "./pages/career";
@import "./pages/fqa";
@import "./pages/contact";
@import "./pages/opera-more";
@import "./pages/cinema-rental";
@import "./pages/news";
@import "./pages/web-event";
@import "./pages/web-movie";
@import "./pages/promotion";
@import "./pages/corn-drinl";
@import "./pages/check-out";
@import "./pages/checkout-payment";
@import "./pages/side";
@import "./pages/checkout-success";
@import "./pages/home";
@import "./pages/booking";
@import "./pages/profile";
@import "./pages/regis";
@import "./pages/detail";
@import "./pages/seat";
@import "./pages/popup";
@import "./layouts/common";
@import "./pages/custom";




.movie-showing{
  
  .btn.--m-mid{
    margin-top: 2.4rem;
  }
  @include max($mobile){
    .container:not(.keep-stable){
      padding-left: 0;
    }
  }
}