.career-detail {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: #0F172A;
        z-index: -20;
    }
}

// .career-detail-heading {
//     margin-bottom: 2.4rem;
// }
.career-detail .txt-lg {
    margin: 2.4rem 0;
    text-transform: uppercase;
    color: $color-second
}

.career-detail .career-detail-wr {
    ul:first-child {
        list-style: auto;
        padding-left: 1.2rem;
    }
}

.career-detail-heading .heading {
    margin-bottom: 0.8rem;
}

.career-detail .time {
    display: flex;
    align-items: center;
    gap: 0 1.2rem;
    @include max($mobile) {
        gap: 0 0.8rem;
    }
}

.career-detail .time .sub-tittle {
    font-family: var(--font-pri);
    margin-top: 0.8rem;
    &::first-letter {
        text-transform: uppercase;
    }
    text-transform: lowercase;
    @include max($mobile) {
        font-size: 1.6rem;
    }
}

.career-detail .time .sub-tittle .tt {
    display: inline;
}

.career-detail .time .icon {
    display: block;
    width: 2.4rem;
    aspect-ratio: 1/1;
}

.career-detail-content {
    width: calc(6.5/12*100%);
    @include max(768px) {
        width: 100%;
    }
    & p,
    & span {
        color: inherit;
    }
    .career-detail-workplace {
        margin-bottom: .75rem;
        font-size: larger;
    }
}

.career-detail-content .list {
    list-style: decimal;
    margin-left: 2.4rem;
}

.career-detail-content .list .item {
    margin-bottom: 0.8rem;
}

.career-detail-content .list .desc {
    position: relative;
    @include max(1024px) {
        font-size: 1.4rem;
    }
    &::after {
        content: "";
        position: absolute;
        left: -1.2rem;
        top: 0.8rem;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 100%;
        background-color: #F8FAFC;
    }
}

.career-detail-form {
    width: calc(5.5/12*100%);
    @include max(768px) {
        width: calc(8/12*100%);
        margin-left: auto;
        margin-right: auto;
    }
    @include max($mobile) {
        width: 100%;
    }
}