.ci-rental-more-list {
    @include max($mobile) {
        --col-gap: 1.6rem;
    }
}

.ci-rental-event-item .sec-heading {
    margin-bottom: 0;
}

.ci-rental-event-item .heading {
    text-transform: unset;
    font-size: 3rem;
    font-weight: 400;

    @include max($res-mobile) {
        font-size: 2.4rem;
    }

    @include max($tablet) {
        font-size: 1.8rem;
    }
}

.ci-rental-event-item {
    justify-content: space-between;
}

.ci-rental-event .opera-more-left {
    @include min($mobile-min) {
        width: calc(5.5/12*100%);
    }
}

.ci-rental-event .opera-more-right {
    @include min($mobile-min) {
        width: calc(6.1/12*100%);
    }
}

.ci-rental-event .btn {
    margin-top: 2.4rem;
    display: inline-block;
    width: 15rem;

    @include max(1200px) {
        margin-top: 1.2rem;
        width: 13rem;
    }

    @include max($mobile) {
        width: 100%;
    }
}

.ci-rental-event-item:nth-child(even) {
    flex-direction: row-reverse;
}


.opera-more-head.sec-heading {
    @include max($mobile) {
        text-align: center;
    }
}


.ci-rental-event-item.opera-more-wr {
    justify-content: space-between;
    margin: unset;

    @include max($mobile) {
        flex-direction: column-reverse;
        gap: 2.4rem;
    }

    &:not(:last-child) {
        @include max($mobile) {
            margin-bottom: 2.4rem;
        }
    }
}

.ci-rental-event-item .opera-more-left {
    @include max($mobile) {
        margin-bottom: 1.2rem;
    }
}

.ci-rental-event-item .opera-more-right {
    width: 50%;

    @include max($mobile) {
        width: 100%;
    }
}

.ci-rental-more-item {
    &:hover {
        .name {
            color: $color-second
        }

        .inner .image::before {
            left: 110%;
            transition: left 0.5s ease;
            visibility: visible;
        }

        .inner .image img {
            opacity: .9;
            transform: scale(1.06) translate(-50%, -50%);
        }
    }

    @include max($mobile) {
        width: calc(6/12*100%);
    }
}

.ci-rental-more-item .inner {
    display: block;
    width: 100%;
    height: 100%;
}

.ci-rental-more-item .inner .image {
    display: block;
    position: relative;
    padding-top: calc(280/399*100%);
    width: 100%;
    height: auto;
    transition: all .3s linear;
    overflow: hidden;


    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 8rem;
        height: 100%;
        content: '';
        background: #fff;
        opacity: 0.2;
        transition: none;
        transform: skewX(-20deg);
        visibility: hidden;
        z-index: 2;
    }

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.5s;
    }
}

.ci-rental-more-item .inner .name {
    display: block;
    text-align: center;
    width: 100%;
    background: linear-gradient(106deg, #639 0%, #36C 102.69%);
    padding: 1.2rem;
    text-transform: uppercase;
    font-size: 1.8rem;
    transition: all .2s;

    @include max($res-mobile-min) {
        font-size: 1.6rem;
    }
}

.ci-rental-more {
    padding-bottom: var(--sp-sc);
}

.ci-rental-more-head {
    @include min($mobile-min) {
        align-items: flex-start;
    }
}

.ci-rental-more-content .content-bottom {
    position: relative;
    overflow: hidden;
}

.ci-rental-more-content .content-bottom .bg {
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: -1;

    @include max($mobile) {
        opacity: 0.9;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.ci-rental-more-content .content-bottom .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(4/12*100%);
    margin-left: calc(1/12*100%);
    padding-top: 6rem;
    padding-bottom: 6rem;
    transition-delay: 1s !important;

    @include max(1024px) {
        width: calc(6/12*100%);
        margin-left: calc(0.5/12*100%);
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }

}

.ci-rental-more-content .content-bottom .content .link {
    color: $color-second;
    font-size: 1.8rem;
    font-weight: 600;

    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.ci-rental-more-content .content-bottom .btn {
    margin-top: 4rem;
    width: 15rem;

    @include max($res-mobile-min) {
        margin-top: 2.4rem;
        width: 13rem;
    }

    @include max($mobile) {
        margin-top: 1.2rem;
        width: max-content;
    }
}

.ci-rental-more-content .desc {
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.ci-rental-more-content .heading {
    text-transform: inherit;

    @include max($mobile) {
        font-size: 1.8rem;
    }
}

.ci-rental-more-desk {
    @include max($mobile) {
        display: none;
    }

    display: block;
}

.ci-rental-more-mobi {
    @include min($mobile-min) {
        display: none;
    }

    display: block;
}