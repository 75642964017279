.opera-detail {}

.sec-opera-dalat {
    padding-bottom: var(--sp-sc);
}

.opera-more {}

.opera-more-wr {
    align-items: center;
    justify-content: space-between;
}

.opera-more-left {
    // width: calc(5.5/12*100%);

    // @include max($mobile) {
    //     width: 100%;
    // }
}

.opera-dalat.sec-heading {
    margin-bottom: 0;

    @include max($mobile) {
        text-align: left;
    }
}

.opera-more-head.sec-heading {
    text-align: left;
    margin-bottom: 0;
}

.opera-more-right {
    width: calc(6.5/12*100%);

    @include max($mobile) {
        width: 100%;
    }
}

.article-type-content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.4rem;
}

.acc-list {
    align-items: center;
}

.acc-list .acc-item {
    margin-bottom: 1.2rem;
    text-align: left;
}

.acc-list .acc-item .acc-item-wr {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    @include max($mobile) {
        justify-content: flex-start;
    }
}

.acc-list .acc-item.per-50 {
    width: 50%;
    justify-content: flex-start;

    @include max($mobile) {
        width: 100%;
    }
}

.acc-list .acc-item.per-50 .acc-item-wr {
    justify-content: flex-start;
}

.acc-list .acc-item.per-50:nth-child(odd) .acc-item-wr {
    margin-left: calc(2/6*100%);

    @include max(1200px) {
        margin-left: calc(1/6*100%);
    }

    @include max(1024px) {
        margin-left: unset;
    }
}


.acc-list .acc-item .ic {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;

    img {
        height: 100%;
    }
}

.acc-list .acc-item .txt {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @include max($mobile) {
        font-size: 1.4rem;
        font-weight: 400;
        text-align: left;
    }
}

.article-type-content .sec-heading .desc {
    margin-bottom: 1.6rem;
    max-width: 60.6rem;
    margin-left: auto;
    margin-right: auto;
}

.article-type-content .btn {
    display: inline-block;
    margin-top: 3.2rem;
    max-width: 15rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include max($mobile) {
        max-width: unset;
        width: 100%;
    }

}

.article-type-slide {
    position: relative;
    width: 100%;
    height: 100%;
}

.article-type-slide .bg {
    display: block;
    position: absolute;
    inset: 0;

    &::after {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
        z-index: 5;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.article-type-slide .item-swiper-slide:not(:has(.content)) .bg::after{
  background: none
}

.article-type-slide .subtitle {
    font-family: var(--font-title);
    font-size: 2.4rem;
    text-transform: uppercase;

    @include max($mobile) {
        font-size: 1.6rem;
        font-weight: 400;
        color: $color-third;
    }
}

.article-type-slide .content {
    position: relative;
    min-height: calc(500/1216*var(--w-container));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    z-index: 10;
}

.content-heading .content-heading.sec-heading {
    margin-bottom: 2.4;

    @include max($mobile) {
        margin-bottom: 1.6rem;
    }
}

.content-heading .heading {
    text-transform: inherit;

    @include max($mobile) {
        font-size: 2.4rem;
        font-weight: 400;
    }
}

.content-heading .desc {
    @include max($mobile) {
        font-size: 1.4rem;
        font-weight: 600;
    }
}

.article-type-slide .content-body .name {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2.4rem;

    @include max($mobile) {
        font-size: 1.8rem;
    }
}

// .article-type-slide .content-body .image {
//     position: relative;
//     padding-top: 100%;
//     display: block;

//     img {
//         @include mid;
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }
// }


.article-type-slide .swiper-slide-active .content {

    .content-heading {
        >* {
            animation: fade-down 1s ease 0.8s both;
        }
    }

    // .content-body {
    //     >* {
    //         animation: fade-in 1s ease 0.8s both;
    //     }
    // }
}


.article-type .button-swiper .arrow-btn-next,
.article-type .button-swiper .arrow-btn-prev {
    position: absolute;
    top: 50%;
    z-index: 50;
    font-size: 4rem;
    transform: translateY(-50%);
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include max(768px) {
        width: 4rem;
        height: 4rem;
        font-size: 3.2rem;
    }

    @include max($mobile) {
        width: 2.4rem;
        height: 2.4rem;
        font-size: 2.4rem;
        top: unset;
        bottom: 3.2rem;
        transform: translateY(-6%);
    }

    i {
        transition: all .3s ease;
    }

    &.swiper-button-disabled {
        cursor: default;
        color: #64748B;
    }

    @include hover {
        &:not(.swiper-button-disabled) {
            color: $color-second;
        }
    }
}

.article-type .button-swiper .arrow-btn-next {
    right: 0;

    @include max($mobile) {
        right: 2.4rem;
    }
}

.article-type .button-swiper .arrow-btn-prev {
    left: 0;

    @include max($mobile) {
        left: 2.4rem;
    }
}

.article-type .swiper-pagination-bullets {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    bottom: 3.2rem !important;
}