.cinema-rental .opera-more-wr {
    @include max($mobile) {
        flex-direction: column-reverse;
    }
}

.cinema-rental-group {
    padding-bottom: var(--sp-sc);
}

.cinema-rental .opera-more-head.sec-heading {
    margin-bottom: 0;
}

.cinema-rental-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    flex-direction: row;
}

.cinema-rental-heading .sort {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.cinema-rental-heading .sort .txt {
    @include max($mobile) {
        display: none;
    }
}


.cinema-system .opera-more-left {
    .sec-heading .desc{
      @include fz-18
    }
    @include min($mobile-min) {
        width: calc(5.5/12*100%);
    }
}

.cinema-system .opera-more-right {
    @include min($mobile-min) {
        width: calc(6.1/12*100%);
    }
}


.cinema-rental-heading.sec-heading .heading {
    margin-bottom: 0;

}

.cinema-rental .cinema-rental-heading .sub-tittle {
    @include max($mobile) {
        font-size: 1.6rem;
    }
}

.cinema-rental .cinema-rental-body {
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.cinema-rental .content-main .desc {
    @include max($mobile) {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.cinema-rental .cinema-rental-footer {
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.cinema-rental .cinema-rental-list .image {
    @include max($mobile) {
        padding-top: calc(281.63/351*100%);
    }
}

.cinema-rental-list .image {
    position: relative;
    overflow: hidden;
    padding-top: calc(281.63/351*100%);
    @include min($mobile-min){
      border-radius: .4rem;
      // overflow: hidden;
    }
    &:hover {
        .content-main {
            top: 0;
        }

        .cinema-rental-body {
            display: block;
            opacity: 1;
        }

        .cinema-rental-link {
            display: flex;
            opacity: 1;
        }
    }
}

.cinema-rental-list .address-box {
    background: linear-gradient(106deg, rgba(102, 51, 153, 0.80) 0%, rgba(51, 102, 204, 0.80) 102.69%);
    width: 100%;
    height: 100%;
}

.cinema-rental-body {
    display: none;
    opacity: 0;
    transition: .5s .25s cubic-bezier(.17, .67, .5, 1.03);

    @include max($mobile) {
        display: block;
        opacity: 1;
    }
}

.cinema-rental-link {
    cursor: pointer;
    pointer-events: visible;
    display: none;
    opacity: 0;
    transition: all .2;
    align-items: center;
    gap: 0.8rem;
    // color: $color-second;

    @include max($mobile) {
        display: flex;
        opacity: 1;
    }

    &:hover {
        color: $color-second;

        .ic {
            img {
                filter: brightness(0) saturate(100%) invert(99%) sepia(34%) saturate(6925%) hue-rotate(333deg) brightness(97%) contrast(98%);
            }
        }
    }
}

.cinema-rental-link .txt {
    transition: all .2s;
}


.cinema-rental-link .ic {
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;

    img {
        filter: brightness(0) saturate(100%) invert(84%) sepia(14%) saturate(478%) hue-rotate(199deg) brightness(116%) contrast(97%);
    }

    &.ani-f {
        animation: ani-moveDown 1.2s linear .1s infinite;
    }

    &.ani-s {
        animation: ani-moveDown 1.2s linear .3s infinite;
        margin-left: -1.8rem;
    }
}


.cinema-rental-list .content-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.cinema-rental-list .address-item {
    width: calc(10/12*100%);
}

.cinema-rental .cinema-rental-list .content-main {
    // .cinema-rental-body .desc{
    //   @include fz-18
    // }
    @include max($mobile) {
        position: absolute;
        inset: 0;
    }
}

.cinema-rental .inner {
    @include max($mobile) {
        position: absolute;
        inset: 0;
        display: block;
    }
}

.cinema-rental-list .content-main {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: all .3s ease-in-out;
    @include min($mobile-min) {
        top: calc(100% - var(--height));
    }

    @include max($mobile) {
        position: relative;
    }
}

.cinema-rental-item {
    @include max($mobile) {
        width: 100%;
    }

    .cinema-rental-item .address-box .item:nth-child(2) .link {
        text-decoration: unset;
    }
}

.cinema-theater-item {
  @include max($mobile) {
    width: 100%;
  }

  .address-box {
    .item:nth-child(2) .link {
      text-decoration: unset;
    }
  }

  .cinema-rental-footer {
    overflow: hidden !important;
  }

  .image {
    &:hover {
        .address-box {
          overflow-y: auto;
          scroll-behavior: smooth;
        }
    }
}
}

.sort .selection {
    width: 100%;

    &::after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        background: url(/assets/images/ic-branch-map.svg);
        background-repeat: no-repeat;
        background-size: contain;
        left: 2.4rem;
        top: 46%;
        transform: translateY(-50%);

        @include max($mobile) {
            left: 1rem;
        }
    }
}


.sort .select2-selection__arrow {
    display: none !important;
}

.sort .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 4rem;
    padding-right: 2.4rem;

    @include max($mobile) {
        padding-right: 0.8rem;
        padding-left: 2.4rem;
    }
}