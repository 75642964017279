// Remove the password reveal control Microsoft Edge
input[type="password"]::-ms-reveal {
  display: none;
}

.app-main,
.main {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    @include full;
    z-index: -5;
    background-color: $color-gray-2;
  }

  transition: padding .4s;
}

@media screen and (min-width: $mobile) { 

  .main-dc {
    .bg-blur {
      content: '';
      display: block;
      position: absolute;
      width: 45%;
      aspect-ratio: 1/1;
      background-repeat: no-repeat !important;
      pointer-events: none;
      z-index: -3;
    }
  
    .bg-blur-1 {
      left: 0;
      top: 40rem;
      filter: blur(10rem);
    }
  
    .bg-blur-2 {
      right: 0;
      top: 60rem;
      filter: blur(5rem);
      transform: translateY(-40%);
    }
  }
  
  .main-dc-1 {
  
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      aspect-ratio: 1/1;
      background-repeat: no-repeat !important;
      pointer-events: none;
      z-index: -3;
    }
  
    &::before {
      left: 0;
      width: 65%;
      top: min(180rem);
      background: url(/assets/images/mobile-blur-6.webp);
      filter: blur(10rem);
      transform: translateY(-20%);
    }
  
    &::after {
      width: 1200px;
      border-radius: 50%;
      left: 10%;
      top: 60rem;
      background: #48479a;
      filter: blur(10rem);
    }
  }
  
  .main-dc-2 {
  
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 65%;
      aspect-ratio: 1/1;
      background-repeat: no-repeat !important;
      pointer-events: none;
      z-index: -3;
    }
  
    &::before {
      left: 0;
      top: min(260rem, 50%);
      background: url(/assets/images/mobile-blur-4.webp);
      filter: blur(10rem);
    }
  
    &::after {
      right: 0;
      top: min(260rem, 50%);
      background: url(/assets/images/mobile-blur-5.webp);
      filter: blur(7rem);
      transform: translateY(-20%);
    }
  }
  
  .main-dc-3 {
  
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 65%;
      aspect-ratio: 1/1;
      background-repeat: no-repeat !important;
      pointer-events: none;
      z-index: -3;
    }
  
    &::before {
      left: 0;
      top: 73%;
      background: url(/assets/images/mobile-blur-6.webp);
      filter: blur(8rem);
    }
  
    &::after {
      right: 0;
      top: 88%;
      background: url(/assets/images/mobile-blur-5.webp);
      filter: blur(10rem);
      transform: translateY(-20%);
    }
  }
  
  .main-dc-4 {
    &::before {
      // content: '';
      display: block;
      position: absolute;
      width: 65%;
      aspect-ratio: 1/1;
      background-repeat: no-repeat !important;
      pointer-events: none;
      z-index: -3;
    }
  
    &::before {
      left: 0;
      top: 85%;
      background: url(/assets/images/mobile-blur-4.webp);
      filter: blur(7rem);
    }
  }
}



.bg-blur-1,.bg-blur-2 {
  display: none !important;
}


.re-input {
  background-color: white;
  padding: 1.4rem 1.4rem;
  color: #475569;
  font-size: 1.6rem;
  font-family: var(--font-pri);
  outline: none;
  border: none;
}

.sc-pd {
  padding: var(--sp-sc) 0;
}

.sc-pd-b {
  padding-bottom: var(--sp-sc);
}

.sc-mg-t {
  margin-top: var(--sp-sc);
}

.--t-center {
  text-align: center;
}

.re-head {
  margin-bottom: 3.2rem;
}

.c-second {
  color: $color-second;
}

// Account ---------------------
.acc {
  &-menu {
    &-slider {
      .swiper {
        &-wrapper {
          @include min($mobile-min) {
            flex-wrap: wrap;
          }
        }

        &-slide {
          @include max($mobile) {
            width: fit-content;
          }

          &.active {
            .acc-menu-link {
              &::before {
                opacity: 1;
              }

              img {
                filter: brightness(0) saturate(100%) invert(81%) sepia(71%) saturate(516%) hue-rotate(1deg) brightness(105%) contrast(91%);
                transition: none;
              }

              color: $color-second;
            }
          }
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: .2rem;
        background-color: $color-second;
        top: 0;
        left: 0;
        height: 100%;
        opacity: 0;
      }

      img {
        width: 1.8rem;
        flex-shrink: 0;
      }

      @include max($mobile) {
        img {
          display: none;
        }

        &:before {
          left: unset;
          width: 100%;
          height: .4rem;
          top: unset;
          bottom: 0;
        }

        font-family: var(--font-title);
        padding: 1rem 0;
      }
    }
  }
}

.prof {
  &-wr {
    .side-bar {
      width: 30rem;
      gap: 0;

      @include max($mobile) {
        width: 100%;
      }
    }

    .prof-main {
      flex: 1;
      overflow: hidden;

      @include max($mobile) {
        width: 100%;
        flex: unset;
      }
    }
  }
}

.about-user {
  &-action {
    font-size: 1.2rem;
    text-decoration: underline;
    cursor: pointer;

    @include max($mobile) {
      text-align: center;
    }
  }

  &-lv {
    padding: .6rem 1.2rem;
    width: 100%;
    font-family: var(--font-title);
    background-color: $color-second;
    color: #000;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .info {
    width: 100%;
  }
}

.acc {
  &-sbar {
    padding: 2rem;
    border-radius: var(--rs);
    background: linear-gradient(106deg, #639 0%, #36C 102.69%);

    @include max($mobile) {
      background: transparent;
      padding: 0;
    }

    .acc-points {
      margin-bottom: 0;

      .point {
        display: inline-block;
        margin-top: 1rem;
      }
    }

    .about-user {
      margin-bottom: 1rem;
    }

    &-block {
      &:not(:last-child) {
        margin-bottom: 1.6rem;
        padding-bottom: 1.6rem;
        border-bottom: .1rem solid rgba($color: #F8FAFC, $alpha: .2);

        @include max($mobile) {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
}

.acc-prf {
  &-block {
    padding: 2rem;
    background-color: white;

    &-title {
      color: #000;
      @include fz-24;
      font-family: var(--font-title);
      margin-bottom: 1.6rem;
    }

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }

  &-form {
    @include max($mobile) {
      .f-item {
        width: 100%;
      }
    }
  }
}

.re-form {
  .re-label {
    color: #000;
  }

  .re-input {
    border: .1rem solid #000;
  }

  .f-list {
    --row-gap: 1rem;
    --col-gap: 1rem;
  }
}

// custom sellect
.select .ant-select .ant-select-selector {
  background-color: unset !important;
  border: solid 0.1rem #F3EA28 !important;
  min-width: 16rem;
  height: 4.2rem;
  border-radius: 0.4rem;
  padding: 0 1.4rem;
}

.select .ant-select .ant-select-selector .ant-select-selection-item {
  color: #F3EA28 !important
}

.none-time {
  padding: 1.6rem;
  margin-top: 2rem;
  border: .1rem solid rgba($color: white, $alpha: .4);
  border-radius: .4rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    width: 2rem;
    flex-shrink: 0;
    filter: brightness(0) invert(1);
  }

  @include max($mobile) {
    padding: 1rem;
    margin-top: 1rem;
    font-size: 1.4rem;
  }
}

.--cs-poiter {
  cursor: pointer;
}

.select-location {
  width: 15rem;
  height: 4rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: .6rem;
  padding: 0 1rem;

  @include max($mobile) {
    height: 3.2rem;
    width: 12rem;
  }

  .ant-select,
  .ant-space-item,
  .ant-space {
    width: 100% !important;
    height: 100% !important;
  }

  >img {
    width: 1.8rem;

    @include max($mobile) {
      width: 1.6rem;
    }
  }

  >.ant-select {
    position: absolute;
    @include full;
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select-selector {
    padding-left: 3.5rem !important;

    @include max($mobile) {
      padding-left: 2.8rem !important;
    }
  }

  .ant-select-selection-item {
    padding: 0 !important;

    @include max($mobile) {
      font-size: 1.2rem;
    }
  }
}

.select-location .ant-select {
  background-color: transparent !important;
  width: 100% !important;

  .ant-select-selector {
    background-color: transparent !important;
    border: .1rem solid $color-second !important;
    color: $color-second !important;
    border-radius: .4rem;
  }

  .ant-select-arrow,
  .ant-select-selection-item {
    color: $color-second !important;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.ant-select-dropdown {
  border-radius: .4rem !important;
}

// Select second 
.select-location-second {
  margin: 0 auto;
  height: 6rem;
  width: 80rem;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: .6rem;

  .ant-select,
  .ant-space-item,
  .ant-space {
    width: 100% !important;
    height: 100% !important;
  }

  @include max($mobile) {
    height: 4rem;
  }

  .ant-select-selection-item {
    font-size: 2.4rem;
    text-transform: uppercase;
    font-family: var(--font-pri);
    text-align: left;

    @include max($mobile) {
      font-size: 1.6rem;
    }
  }

  .ant-select-arrow {
    svg path {
      fill: #000;
    }
  }

  .ant-select-single .ant-select-selector {
    border-radius: .4rem !important;
  }

  .ant-select-selector {
    border-color: white !important;
  }
}

// Toast
.Toastify__toast-theme--light {
  background-color: $color-pri !important;
  color: #fff !important;
  font-family: var(--font-pri) !important;

  .Toastify__progress-bar {
    background-color: #fff !important;
    height: .2rem;
  }

  .Toastify__close-button>svg path {
    fill: #fff !important;
    opacity: 1 !important;
  }
}

.form-payment {
  @include max($mobile) {
    .btn.btn-submit {
      display: none;
    }
  }
}

.form-checkout-cus {
  .btn.btn-submit {
    width: 100%;
  }
}

.checkbox-label.checkbox-label-custom {
  display: flex;
  align-items: center;
  gap: .6rem;

  &::before,
  &:after {
    display: none;
  }

  .checkbox-label-custom-sqr {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.4rem;
    border: 0.1rem solid #D9D9D9;
    overflow: hidden;

    &::before {
      content: '\f00c';
      font-family: 'Font Awesome 6 Pro';
      font-size: 1.2rem;
      opacity: 0;
    }
  }
}

.checkbox-input:checked~.checkbox-label.checkbox-label-custom {
  .checkbox-label-custom-sqr {
    background-color: #FF9933;
    border-color: #FF9933;

    &::before {
      opacity: 1;
    }
  }
}

.sec-ticket {
  .combo-item.col-6 {
    .food-box .content {
      justify-content: flex-start;

      @include max($mobile) {
        justify-content: space-between;
      }
    }
  }
}

.cinema-rental-footer {
  .ic {
    flex-shrink: 0;
    position: relative;
    top: 0.3rem;
  }
}

.cinema-rental-footer .link {
  align-items: flex-start !important;
}

.sec-ticket {
  @include min($mobile-min) {
    .food-box .content {
      flex-direction: column !important;
      border: .1rem solid rgba($color: #F8FAFC, $alpha: .5);
      border-radius: .4rem;
      padding: 2rem !important;

      .desc {
        color: $color-second;
        font-size: 1.4rem !important;
      }
    }
  }
}

// .article-type-slide {
//   .button-swiper {
//     display: none;
//   }
// }
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  background-color: #0F172A;
  display: flex;
  justify-content: center;
  align-items: center;

  // @include min($mobile-min) {
  //     display: none;
  // }
  &-logo {
    width: 12rem;
    animation: zoomAnimation 2s infinite alternate;

    img {
      width: 100%;
    }
  }

  @include hidden();

  &.open {
    @include show;
  }
}

@keyframes zoomAnimation {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.sec-ticket {
  @include max($mobile) {
    .food-box {
      padding: 1rem;
      border-radius: var(--rs);
      border: .1rem solid rgba($color: #F8FAFC, $alpha: .5);
    }

    .food-box .content-top .desc {
      color: $color-second;
      font-size: 1.2rem;
    }
  }
}

.app-main:not(:has(.sticky)) {
  overflow: hidden;
}

.bill-fixed {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: calc(var(--z) - 5);
  background-color: $color-gray-2;
  padding: 1rem;
  animation: fadeInUp .3s ease-in-out;
  border-top: .1rem solid rgba($color: #F8FAFC, $alpha: .5);

  .container {
    padding: 0;
  }

  .bill-wr {
    padding: 0;
    width: 100%;
    background-color: $color-gray-2;
  }

  .bill-left {
    justify-content: center;

    @include max($mobile) {
      padding-bottom: .8rem;
    }

    .name-combo.sub-title {
      @include fz-24;
    }
  }

  .bill-right {
    justify-content: center;
  }

  .bill-right .price {
    display: flex;
    gap: .4rem;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  // transform: translateY(100%);
  transition: .3s ease-in-out;

  &.show {
    transform: translateY(0);
  }
}

.checkout-back {
  display: none;
}

.seat-vip {}

.dt-food-second {
  .combo-list {
    --col-gap: 1rem;
  }

  .food-box {
    // padding: 1rem;
    // border-radius: var(--rs);
    // border: .1rem solid rgba($color: #F8FAFC, $alpha: .5);
    align-items: flex-start;
  }

  @include max($res-mobile) {
    .content-bottom {
      flex-direction: column;

      .count {
        width: 100%;
      }
    }
  }
}

.dt-food .food-box {
  text-align: left;

  @include max($mobile) {
    text-align: center;
  }

  .image {
    border-radius: .4rem;
  }
}

.cinema-rental-custom {
  @include min($mobile-min) {
    @include imgPd(470, 588);

    >img {
      @include imgCover;
    }
  }
}

.web-movie-box .info .name {
  @include lc(2);
  line-height: 1.8;
}

.mona-noti {
  padding: 8rem 0;
  text-align: center;

  .txt {
    font-family: var(--font-title);
    @include fz-40;
  }
}

.mv-grid {
  &-list {
    justify-content: center;

    @include max($mobile) {
      --col-gap: 1rem;
    }
  }

  &-item {
    @include max($tablet) {
      width: calc(100% /3);

      .web-movie-box .inf .video {
        display: none !important;
      }

      .web-movie-box .info .btn {
        width: 100%;
      }
    }

    @include max($mobile) {
      width: 50%;
    }
  }
}

.movies {
  &-item {
    @include max(800px) {
      width: 100%;

      &:not(:last-child) {
        .movies-wr {
          padding-bottom: 2.4rem;
          border-bottom: .1rem solid rgba($color: #F8FAFC, $alpha: .5);
        }
      }
    }
  }

  &-wr {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &-img {
    width: calc(54% - .5rem);

    @include max(1100px) {
      width: 40%;
    }

    @include max($mobile) {
      width: 35%;
    }

    .inner {
      padding-top: 150%;
      position: relative;
      overflow: hidden;
      display: block;
      border-radius: var(--rs);

      img {
        @include imgCover;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    overflow: hidden;
    flex: 1
  }

  &-name {
    @include fz-20;
    font-family: var(--font-title);
  }

  &-type {
    font-size: 1.4rem;

    &.margin-top {
      margin-top: 1rem;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: .5rem 1rem;

      li {
        display: flex;
        gap: .4rem;

        img {
          width: 1.7rem;
          display: block;
          flex-shrink: 0;
        }

        i {
          color: $color-second;
          font-size: 1.3rem;
          position: relative;
          top: .4rem;
          flex-shrink: 0;
        }
      }
    }

    &-custom {
      ul li i {
        font-size: 1.1rem;
      }
    }
  }

  &-rp {
    width: 100%;
    --pd-wr: .8rem;

    &-title {
      text-transform: uppercase;
      font-size: 1.1rem;
      margin-bottom: .4rem;
    }

    &-day {
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: .4rem;
      cursor: pointer;
      padding: var(--pd-wr);

      i {
        transition: .3s ease-in-out;
      }
    }

    &-body {
      padding: var(--pd-wr);
      padding-top: 0;
      display: none;
    }

    &-block {
      border-radius: .6rem;
      border: .1rem solid rgba($color: #F8FAFC, $alpha: .5);

      &:not(:last-child) {
        margin-bottom: .8rem;
      }

      &.active {
        .collapseHead {
          i {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: .8rem;
      }
    }

    &-noti {
      font-size: 1.4rem;
      padding: .6rem 1rem;
      border-radius: .4rem;
      border: .1rem solid rgba($color: #F8FAFC, $alpha: .5);
      display: flex;
      align-items: center;
      gap: .6rem;

      img {
        filter: brightness(0) invert(1);
        width: 2rem;
        flex-shrink: 0;
      }
    }
  }

  &-time {
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: .6rem;
    }

    &-item {
      padding: .6rem 1.2rem;
      border-radius: .4rem;
      cursor: pointer;
      font-size: 1.3rem;
      position: relative;
      text-align: center;
      display: block;

      @include max($mobile) {
        padding: .4rem 1.2rem;
      }

      &::before {
        @include pseudo;
        @include full-size(.1rem);
        border: .1rem solid rgba($color: #F8FAFC, $alpha: .5);
        border-radius: inherit;
      }

      &.active {
        border-color: $color-second;

        &::before {
          border-color: $color-second;
        }

        color: $color-second;
      }
    }

    &-slider {
      .swiper {
        --col-gap: .4rem;

        &-container {}

        &-wrapper {
          margin-bottom: calc(var(--col-gap) * -1);
          flex-wrap: wrap;
        }

        &-slide {
          width: 25%;
          margin-bottom: var(--col-gap) !important;
          overflow: visible;

          // height: initial !important;
          @include max(1100px) {
            width: fit-content;
          }
        }
      }

      .swiper-pagination {
        margin-top: .4rem;
        text-align: left;
        justify-content: flex-start;

        &-bullet {
          width: .6rem;
          height: .6rem;
        }
      }
    }
  }

  .btn-see-more {
    text-decoration: underline;
    text-underline-offset: 3px;
    margin-bottom: .75rem;
    font-size: 1.4rem;
    transition: color .4s ease-in-out;
    font-weight: 600;
    color: $color-second;

    &:hover {
      color: $color-second;
      -webkit-mask-image: linear-gradient(-75deg, rgba(243, 234, 40, 0.6) 30%, rgb(243, 234, 40) 50%, rgba(243, 234, 40, 0.6) 70%);
      -webkit-mask-size: 200%;
      animation: shine 1s infinite;
    }
  }
}

.showtime-filter {
  --gap: 1rem;
  --spacing: 5rem;
  margin: var(--spacing) 0 3rem 0;

  .showtime-filter-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: var(--gap);
    padding-bottom: var(--spacing);
    border-bottom: .1rem solid darkgray;

    .showtime-filter-item {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border: 1px solid darkgrey;
      border-radius: .8rem;

      .showtime-filter-heading {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: $color-second;
        margin-bottom: 1rem;
        @include fz-24;

        &>span {
          line-height: 1;
        }
      }

      .showtime-date {
        display: flex;
        gap: .8rem;

        .showtime-date-item {
          align-items: center;
          flex: 1 0 calc(25% - 1.6rem);
          border: 1px solid darkgrey;
          color: $color-second;
          padding: .6rem;
          border-radius: .8rem;
          @include fz-14;
          font-weight: bolder;

          .box-time {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .3rem;
            flex-wrap: wrap;
            cursor: pointer;
          }

          &.active {
            color: $color-pri;
            background-color: $color-second;
          }
        }

        .swiper-wrapper {
          padding: 0 calc(var(--col-gap) /2);

          .swiper-slide {
            padding-right: .5rem;
          }
        }
      }

      .navigate-filter-item {
        margin-top: auto;
        text-transform: capitalize;
        height: auto;

        .ant-select-selection-item {
          letter-spacing: .1rem;
          text-transform: capitalize;
          @include fz-14;
          font-family: inherit;
          font-weight: bold;
        }

        .ant-select-single .ant-select-selector {
          padding: .2rem 2rem;
          border-radius: .8rem !important;
        }
      }

      flex-basis: calc(25% - var(--gap));
      max-width: calc(25% - var(--gap));

      &.--large {
        flex: 1;
        max-width: calc(50% - var(--gap));
      }

      @include max($mobile) {
        --gap: .5rem;
        flex-basis: calc(50% - var(--gap));
        max-width: calc(50% - var(--gap));
        padding: 1rem;

        &.--large {
          flex-basis: 100%;
          max-width: 100%;
          order: 2;
        }

        .showtime-date {
          .box-time {
            font-size: 1.2rem;
          }
        }

        .navigate-filter-item .ant-select-single .ant-select-selector {
          padding: .1rem .8rem;
        }
      }
    }
  }

  @include max($mobile) {
    --spacing: 3rem;
  }

  i {
    font-family: "Font Awesome 6 Pro";
  }

  .showtimes-icon {
    max-height: 2.6rem;
    filter: brightness(0) saturate(100%) invert(98%) sepia(80%) saturate(2086%) hue-rotate(336deg) brightness(109%) contrast(91%);
  }
}

.movies-showtimes {
  min-height: 100vh;

  .btn-more button {
    min-width: 30rem;

    @include min($tablet) {
      padding: 1.2rem 1.6rem;
    }
  }

  .btn-scroll-to-top {
    &:hover {
      background-color: $color-pri;

      svg {
        fill: white;
      }
    }

    @include max($mobile) {
      bottom: 7rem;
      right: 1.5rem;
    }
  }

  .movies-name {
    margin: 2rem 0;
  }

  .movies-type {
    ul li {
      width: 100%;
      margin: .3rem 0;
    }
  }

  .the-rest-movie {
    margin: 5rem 0;
  }

  .movies-showtime {
    border-bottom: 1px solid darkgray;
    margin-bottom: 5rem;
    padding-bottom: 3rem;

    &>.col {
      margin-bottom: 0;
      position: relative;
    }

    .sec-showtimes-left {
      width: calc(4/12 * 100%);
    }

    .sec-showtimes-right {
      width: calc(8/12 * 100%);
    }

    @include max($mobile) {
      .sec-showtimes-left {
        width: 50%
      }

      .sec-showtimes-right {
        width: 50%
      }
    }
  }

  .sec-showtimes {
    width: calc(8/12 * 100%);

    .movies-rp-body {
      display: block;
    }
  }

  .sec-movies {
    width: calc(4/12 * 100%);
  }

  .movies-list {
    margin-bottom: 3rem;
    border-bottom: 1px solid darkgray;

    &:last-child {
      border: none;
    }

    .movie-item {
      min-height: 100vh;
    }

    .theater-heading {
      text-transform: capitalize;
      font-size: 2.6rem;
      color: $color-text;

      @include max(1200px) {
        font-size: 2rem;
      }

      @include max($mobile) {
        font-size: 1.8rem;
      }
    }

    .theater-sub {
      font-size: 1.4rem;

      @include max($mobile) {
        font-size: 1rem;
      }
    }

    .movies-time {
      .movies-time-items {
        display: flex;
        gap: .5rem;
        flex-wrap: wrap;

        .movies-time-item {
          font-size: 1.6rem;

          &.active,
          &:hover {
            color: $color-second;

            &::before {
              border-color: $color-second;
              transition: all .4s;
            }
          }
        }
      }
    }

    @include max($mobile) {
      .sec-showtimes {
        width: 100%;
        padding: 0;
      }

      .sec-movies {
        width: 100%
      }

      .movies-time {
        font-size: 1.4rem;
      }
    }
  }
}

.app:has(.hd.--second) {
  .movies-fil {
    top: calc(var(--sz-hd) - var(--sz-hd-top));
  }
}

.app:has(.hd.--third) {
  .movies-fil {
    top: calc(var(--sz-hd) - var(--sz-hd-top) *2);
  }
}

.movies-fil {
  top: var(--sz-hd);
  z-index: 2;
  margin-bottom: 4rem;
  padding-top: 1rem;
  // background-color: #0F172A;
  background-color: rgba(15, 23, 42, 0.7);
  ;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 65%;
    aspect-ratio: 1/1;
    background-repeat: no-repeat !important;
    pointer-events: none;
    left: -35%;
    top: 0;
    background: url(/assets/images/mobile-blur.webp);
    filter: blur(10rem);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 65%;
    aspect-ratio: 1/1;
    background-repeat: no-repeat !important;
    pointer-events: none;
    right: -10%;
    top: 0;
    background: url(/assets/images/mobile-blur-2.webp);
    filter: blur(10rem);
    transform: translateY(-50%);
  }

  @include max($tablet) {
    &::before {
      top: 60%;
      left: -15%;
      width: 40%;
    }
  }

  @include max($mobile) {

    &::before,
    &::after {
      background: none;
    }
  }

  &-slider {
    .swiper {
      --col-gap: .8rem;

      &-slide {
        flex: 1;

        @include max(1000px) {
          flex: unset;
          flex-shrink: 0;
          width: 18rem;
        }

        @include max($mobile) {
          width: 10rem;
        }
      }
    }
  }

  &-btn {
    padding: 1rem;
    border-radius: var(--rs);
    text-align: center;
    border: .1rem solid white;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    font-family: var(--font-title);
    font-size: 1.8rem;
    overflow: hidden;
    @include affter($color-second);
    position: relative;
    display: block;

    @include max(1000px) {
      font-size: 1.6rem;
      padding: .5rem;
    }

    @include max($mobile) {
      font-size: 1rem;
    }

    @include max(325px) {
      font-size: .9rem;
    }

    >* {
      position: relative;
      z-index: 2;
    }

    @include hover {
      border-color: $color-second;
      color: $color-gray-2;

      &::after {
        left: 0;
        width: 100%;
      }
    }

    &.active {
      background: $color-second;
      color: $color-gray-2;
      border-color: $color-second;
    }
  }

  &-btn-mobile {
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    font-family: var(--font-title);
    font-size: 1.8rem;
    overflow: hidden;

    @include max(1000px) {
      font-size: 1.6rem;
      padding: .5rem 0;
    }

    @include max($mobile) {
      font-size: 1rem;
    }

    @include max(325px) {
      font-size: .9rem;
    }

    &.active {
      color: $color-second;
      border-bottom: .4rem solid $color-second;

      @include max($mobile) {
        border-bottom: .2rem solid $color-second;
      }
    }
  }
}

.movies-noti {
  padding: 4rem 0;
  text-align: center;
  border-radius: var(--rs);

  &-img {
    max-width: 30rem;
    margin: 0 auto;

    img {
      filter: brightness(0) invert(1);
    }
  }

  .txt {
    @include fz-32;
    font-family: var(--font-title);
    text-transform: uppercase;
    color: $color-second;
  }

  &.center {
    flex: 1;
  }
}

.address-box {
  @include min($mobile-min) {
    padding: 1.6rem;
  }

  .cinema-rental-footer {
    .item .txt {
      @include min($mobile-min) {
        --lc: 1;
        display: -webkit-box;
        -webkit-line-clamp: var(--lc);
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }

    .item .txt-address {
      @include min($mobile-min) {
        height: var(--heightTxt);
      }
    }
  }

  @include hover {
    .cinema-rental-footer {
      .item .txt {
        display: block;
      }
    }
  }
}

.news-dt {
  &-img {
    padding-top: calc(9/16 * 100%);
    position: relative;
    overflow: hidden;

    img {
      @include imgCover;
    }
  }

  &-main {
    margin-top: 4rem;
  }

  &-left {
    flex: 1;
    overflow: hidden;
  }

  &-right {
    width: 40rem;

    @include max(800px) {
      width: 100%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .title {}

    img {
      border-radius: var(--rs);
      display: block;
    }
  }

  &-title {
    @include fz-32;
    color: $color-second;
  }

  &-sbar {
    position: sticky;
    top: calc(var(--sz-hd) + 1rem);

    &-title {
      font-family: var(--font-title);
      @include fz-24;
      margin-bottom: 1.6rem;
    }
  }

  &-side {
    --col-gap: 1.6rem;
  }
}

.news-mn {
  &-list {
    --row-gap: 1.6rem;
  }

  &-item {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &-img {
    width: 14rem;

    @include max($mobile) {
      width: 10rem;
    }

    .inner {
      padding-top: 71.0659898477%;
      display: block;
      position: relative;
      overflow: hidden;
      border-radius: var(--rs);

      img {
        @include imgCover;
      }
    }
  }
}

.news-dt {
  &-img {
    padding-top: calc(9/16 * 100%);
    position: relative;
    overflow: hidden;

    img {
      @include imgCover;
    }
  }

  &-main {
    margin-top: 4rem;
  }

  &-left {
    flex: 1;
    overflow: hidden;
  }

  &-right {
    width: 40rem;

    @include max(800px) {
      width: 100%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .title {}

    img {
      border-radius: var(--rs);
      display: block;
    }
  }

  &-title {
    @include fz-32;
    color: $color-second;
  }

  &-sbar {
    position: sticky;
    top: calc(var(--sz-hd) + 1rem);

    &-title {
      font-family: var(--font-title);
      @include fz-24;
      margin-bottom: 1.6rem;
    }
  }

  &-side {
    --col-gap: 1.6rem;
  }
}

.news-mn {
  &-list {
    --row-gap: 1.6rem;
  }

  &-item {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &-img {
    width: 14rem;

    @include max($mobile) {
      width: 10rem;
    }

    .inner {
      padding-top: 71.0659898477%;
      display: block;
      position: relative;
      overflow: hidden;
      border-radius: var(--rs);

      img {
        @include imgCover;
      }
    }
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  &-name {
    @include lc(1);
  }

  &-des {
    @include lc(2);
    font-size: 1.4rem;
  }
}

.news-item .news-inner .image {
  border-radius: var(--rs);
}

.form-cus {
  .form-control {
    border-radius: .4rem;
  }
}

.form-checkout-cus {
  border-radius: .4rem;
}

.cinestar-item.collapseItem {
  position: relative;
}

.lding {
  position: absolute;
  @include full;
  border-radius: inherit;
  z-index: 10;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-gray-2;
  // border: .1rem dashed white;
  --sz-w: 4rem;

  &.--second {
    position: relative;
    min-height: 40vh;
    margin: 0 auto;
    background-color: transparent;
  }

  &-wr {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-box {
    width: var(--sz-w);
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: .2rem solid white;
    border-radius: .4rem;

    .inner {
      width: 100%;
      aspect-ratio: 1;
      display: grid;
      border-radius: 50%;
      background: linear-gradient(0deg, rgb(255 255 255/50%) 30%, transparent 0 70%, rgb(255 255 255/100%) 0) 50%/8% 100%, linear-gradient(90deg, rgb(255 255 255/25%) 30%, transparent 0 70%, rgb(255 255 255/75%) 0) 50%/100% 8%;
      background-repeat: no-repeat;
      animation: l23 1s infinite steps(12);

      &::before,
      &::after {
        content: "";
        grid-area: 1/1;
        border-radius: 50%;
        background: inherit;
        opacity: 0.915;
        transform: rotate(30deg);
      }

      &::after {
        opacity: 0.83;
        transform: rotate(60deg);
      }
    }
  }

  &-line {
    width: calc(var(--sz-w) - 1rem);
    height: .2rem;
    background-color: white;
    position: relative;
    margin: 0 auto;
    display: none;

    &::before {
      @include pseudo;
      @include posi-vertical;
      width: .8rem;
      height: .8rem;
      border-radius: 100rem;
      background-color: white;
      animation: loadingLineCircle 2s linear infinite alternate;
    }
  }
}

@keyframes loadingLineCircle {
  0% {
    left: 0;
  }

  100% {
    left: 95%;
  }
}

@-webkit-keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 89.30990600585938px;
    stroke-dasharray: 89.30990600585938px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 89.30990600585938px;
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 89.30990600585938px;
    stroke-dasharray: 89.30990600585938px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 89.30990600585938px;
  }
}

@keyframes l23 {
  100% {
    transform: rotate(1turn)
  }
}

.shtime-ft {
  position: relative;
}

.select-location-second {
  height: 5rem;
  position: relative;

  .ant-select-arrow {
    display: none;
  }

  &::before {
    @include pseudo;
    @include borderArrow("down", .5rem, #000);
    @include posi-vertical;
    right: 1.6rem;
    z-index: 3;
  }

  .ant-select-selection-item {
    font-size: 2rem;
    font-family: var(--font-title);
    letter-spacing: .3rem;
    font-weight: 400;
  }
}

.cinema-rental-list .image {

  >img,
  >.inner {
    @include imgCover;
  }
}

.service .cinema-rental-list .image .content-main {
  top: unset;
  height: unset;

  @include max($res-mobile) {
    display: none;
  }
}

.service .cinema-rental-list .image {
  padding-top: 80.2364672365%;
  border-radius: 0.4rem;
  overflow: hidden;
}

.service .cinema-rental-list .image .content-main .desc {
  @include lc(1);
}

.cinema-rental-remake .collapseItem .cinema-rental-heading i {
  display: none;
}

.cinema-rental-remake .collapseItem .collapseBody {
  display: none;
}

.cinema-rental-remake .collapseItem {
  @include hover {
    .collapseBody {
      display: block;
    }
  }
}

@include min($mobile-min) {
  .cinema-rental-remake-img {
    @include full;
    position: absolute;

    img {
      @include imgCover;
    }
  }
}

@include max($mobile) {
  .cinema-rental-remake .collapseItem {
    .cinema-rental-footer .collapseBody {
      display: none;
    }

    .address-box {
      padding: 0;
    }

    .cinema-rental-heading {
      padding: 1rem;
      cursor: pointer;
      justify-content: space-between;
      margin-bottom: 0;
      gap: .5rem;

      i {
        transition: .3s ease-in-out;
        display: block;
      }
    }

    .cinema-rental-bodys {
      padding: 0 1rem 1rem 1rem;
    }

    &.active {
      .cinema-rental-heading {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .address-box {
      border-radius: 0 0 .4rem .4rem;
      overflow: hidden;
    }
  }

  .cinema-rental-remake-img {
    @include imgPd(259, 630);
    border-radius: .4rem .4rem 0 0;

    img {
      @include imgCover;
    }
  }
}

// handle collapse
.ant-collapse-header {
  padding: 0 0 1rem 0 !important;
}

.ant-collapse-header:hover {
  color: #0c83f3 !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
  background-color: #ffff !important;
}

.mona-noti-custom {
  padding-bottom: var(--sp-sc);

  .box {
    text-align: center;
    font-family: var(--font-title);
    @include fz-36;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: $color-second;

    i {
      font-family: "Font Awesome 6 Pro";
    }
  }
}

.bill-custom {
  --gap: 1.6rem;

  @include max($mobile) {
    --gap: 0rem;
  }

  .bill-wr {
    gap: var(--gap);
    align-items: center;
  }

  .bill-left {
    flex: 1;
    overflow: hidden;

    @include max($mobile) {
      width: 100%;
    }
  }

  .bill-right {
    padding-left: 0;
    border-left: none;
    flex: 1;
  }

  &-right {
    width: 38rem;
    display: flex;
    gap: 1rem;

    @include max($mobile) {
      width: 100%;
      flex-direction: column;
      gap: .4rem;
    }
  }
}

.bill-coundown {
  padding: 1rem;
  border: .1rem solid rgba($color: #F8FAFC, $alpha: .5);
  border-radius: .4rem;
  background-color: $color-second;
  color: $color-black;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include max($mobile) {
    width: 100%;
    flex-direction: row;
    gap: .4rem;
    align-items: center;
    padding: .4rem .8rem;
    width: fit-content;
  }

  .txt {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.bill-time {
  display: flex;
  align-items: center;
  font-family: var(--font-title);
  letter-spacing: .1rem;
  @include fz-20;
}

.form-checkout-cus {
  .bill-coundown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .6rem;
    padding: .2rem 1rem;
    width: fit-content;
  }

  .bill-time {
    font-size: 1.6rem;
  }

  .bill-coundown-custom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .6rem;

    .txt {
      font-family: var(--font-pri);
      font-size: 1.6rem;
    }

    &.inner-info-row {
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      gap: .6rem;
    }
  }
}

.tabs-movie-mobile {
  padding-top: 0;

  .tabs-heading {
    @apply movies-fil-btn;
    font-size: 1.6rem;
  }

  .ant-tabs-tab-active {
    .tabs-heading {
      background: $color-second;
      color: $color-gray-2;
      border-color: $color-second;
    }
  }

  // .ant-tabs-tab:not(:first-child)::before{
  //   content: "";
  //   width: 2px;
  //   height: 40%;
  //   background-color: $color-second;
  //   position: absolute;
  //   left: -16px;
  // }
  .ant-tabs-nav::before {
    display: none;
  }
}

.tabs-movie-mobile-line {

  .ant-tabs-nav::before,
  .ant-tabs-nav-more {
    display: none;
  }

  .tabs-heading h1 {
    text-align: center;
    font-family: var(--font-title);
    font-size: 1.6rem
  }
}

.tabs-movie-mobile:not(.tabs-movie),
.tabs-movie-mobile-line:not(.tabs-movie) {
  .ant-tabs-nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    justify-content: flex-end;

    &:first-child {
      justify-content: flex-start;
    }

    &-btn {
      width: 100%;
    }
  }
}

.tabs-movie {
  .tabs-heading {
    padding: .5rem 1.5rem;
  }
}

.remove-padding {
  padding: 0;
}

.custom-padding {
  padding-top: 0;

  @include min($mobile) {
    padding-bottom: 0;
  }
}

.remove-margin-top {
  margin-top: 0;
}

.select-theater {
  align-items: center;
  justify-content: center;
}

// about-us gallery
.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 7rem;

  .gallery-item-content {
    text-align: center;

    .heading {
      font-size: 2rem;
      margin: 2.4rem 0;
    }
  }

  .gallery-item-wrap {
    position: relative;
  }

  .button-swiper .arrow-btn-next {
    right: -5rem;
    font-size: 2.2rem;
  }

  .button-swiper .arrow-btn-prev {
    left: -5rem;
    font-size: 2.2rem;
  }

  .gallery-item-images .gallery-item-image {
    display: none;

    &:first-child {
      display: block;
    }
  }



  @include max($tablet) {
    column-gap: 5.5rem;
    row-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    .swiper-pagination.--second {
      margin-top: 2rem;
    }

    .button-swiper .arrow-btn-next {
      right: -2.5rem;
    }

    .button-swiper .arrow-btn-prev {
      left: -2.5rem;
    }
  }

  @include max($mobile) {
    column-gap: 2.5rem;
    row-gap: 1.5rem;

    .swiper-pagination.--second {
      margin-top: 1rem;
    }

    .gallery-item-content {
      text-align: center;

      .heading {
        font-size: 1.6rem;
        margin: 1rem 0;
      }
    }
  }
}

.app-content .ht:first-child {
  padding-top: 0;
}