.career {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: #0F172A;
        z-index: -20;
    }
}

.career-detail-content-it {
    &:not(:last-child) {
        margin-bottom: 2.4rem;

    }
}

.career-detail-content-it .txt-lg {
    color: $color-second;
    text-transform: uppercase;

    @include max($mobile) {
        font-size: 1.6rem;
    }
}

.career-banner-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    @include max($mobile) {
        padding-left: calc(var(--pd-container) - 0.8rem);
        padding-right: calc(var(--pd-container) - 0.8rem);
    }
}

.career-banner-item {
    width: calc(100%/3);
    height: auto;

    @include max($mobile) {
        padding: 0 calc(1.6rem / 2);
        margin-bottom: 1.6rem;
    }
}

@include max($mobile) {
    .career-banner-item:nth-child(1) {
        width: calc(100%/2);

        .image {
            padding-top: calc(160/166*100%);
        }
    }

    .career-banner-item:nth-child(2) {
        width: calc(100%/2);

        .image {
            padding-top: calc(160/166*100%);
        }
    }

    .career-banner-item:nth-child(3) {
        width: 100%;
        margin-bottom: 0;

        .image {
            padding-top: calc(160/347*100%);
        }
    }
}



.career-banner-item .image {
    display: block;
    position: relative;
    padding-top: calc(488/ 576*100%);
    width: 100%;
    height: auto;

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.career-item .sub-tittle {
    @include max($mobile) {
        font-size: 1.8rem;
    }
}

.career-content .career-item {
    position: relative;
    padding: 2.4rem;

    &:not(:last-child) {
        margin-bottom: 2.4rem;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(51, 102, 204, 0.80);
        z-index: -5;
    }

    @include min(701px) {
        pointer-events: none;
    }

    @include max($mobile) {
        padding: 1.6rem;
    }

}

.career-content .career-item .career-item-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 10rem;

    @include max($res-mobile-min) {
        gap: 0 3.2rem;
    }

    @include max($tablet) {
        gap: 0 2.4rem;
    }

    @include max($mobile) {
        flex-direction: column;
        display: none;
    }
}

.career-content .career-item .content {
    @include max($mobile) {
        margin-bottom: 1.6rem;
    }
}

.career-item-heading {
    @include max($mobile) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.address-box-head .icon {}

.career-item.active .icon {
    transform: rotate(180deg);
}

.career-item-heading .icon {
    color: $color-second;
    font-size: 1.8rem;
    transition: .3s ease-in-out;
    display: block;

    @include min(701px) {
        display: none;
    }
}


.career-content .career-item .status {
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;

    @include max($mobile) {
        display: none;
    }
}

.career-item .status .update {
    color: $color-second;
    font-weight: 700;
}

.career-content .desc {
    font-weight: 600;
    margin-top: 0.8rem;
    @include fz-18;
    @include max($mobile) {
        font-size: 1.4rem;
        font-weight: 400;
    }
}

.career-content .career-item .btn-group {
    display: flex;
    gap: 2.4rem;

    @include max(1200px) {
        gap: 1.2rem;
    }

    @include max($mobile) {
        margin-right: auto;
    }
}

.career-item .btn-group .btn {
    display: inline-block;
    white-space: nowrap;
    min-width: 18rem;
    pointer-events: visible;

    @include max($res-mobile) {
        padding: 1.2rem 2.4rem;
        min-width: 10rem;
    }

    @include max($tablet) {
        padding: 0.8rem 1.2rem;
    }
}