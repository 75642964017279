.select2-container {
    height: 4.2rem;
    background-color: transparent;
    border-radius: $radius-1;

    @include max($mobile) {
        height: 3.2rem;
    }
}

.select2.select2-container {
    width: 14rem !important;

    @include max($mobile) {
        width: 10rem !important;
    }
}

.select2-container--default .select2-selection--single {
    border-radius: $radius-1;
    color: $color-white;
    height: 4.2rem;
    background-color: transparent;
    border: 0.1rem solid $color-second;
    @include flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include max($mobile) {
        height: 3.2rem;
    }
}

.select2.select2-container .select2-selection--multiple {
    height: auto;
    min-height: 4.2rem;

    @include max($mobile) {
        min-height: 3.2rem;
    }
}

.select2-container .select2-selection--single .select2-selection__rendered {
    background-color: transparent;
    border: 1px solid transparent;
    color: $color-second;
    @include fz-18;
    font-weight: 500;
    display: inline !important;
    @include lc(1);
    width: 100%;
}

.select2.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: block;
    padding: 1.2rem 2.4rem;
}

.select2-container .select2-dropdown {
    background: transparent;
    border: none;
    z-index: 3;
}

.select2-container .select2-dropdown .select2-search {
    padding: 0;
}

.select2-container .select2-dropdown .select2-search input {
    outline: none !important;
    border: none;
    border-bottom: none !important;
    padding: 4px 6px !important;
    background-color: $color-black;
    color: $color-black;
}

.select2-container .select2-dropdown .select2-results ul {
    background: #fefefe;
    color: $color-black;
}

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
    background: $color-pri;
    font-size: 1.6rem;
    color: $color-white;
}

.select2-container--default .select2-results__option--selected {
    background: $color-pri;
    color: $color-white;
    font-size: 1.6rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;
    border-color: #000 transparent transparent transparent;
}

.select2-search.select2-search--dropdown {
    display: none !important;
}

// .select2-selection__arrow {
//     display: none;
// }

.select2-results__option {
    font-size: 1.6rem;
}

.select2-results {
    display: block;
    overflow: hidden;
    border: 0.1rem solid transparent;
    border-radius: $radius-1 !important;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-radius: $radius-1 !important;
}


.select2-selection__placeholder {
    font-size: 1.8rem;
    color: $color-second !important;

    @include max($mobile) {
        font-size: 1.6rem;
    }
}

// .sidesl {
//     z-index: 53 !important;

//     .select2-results {
//         box-shadow: $shadow;
//     }
// }