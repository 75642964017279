input {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: var(--font-pri);
    color: $color-gray-1;
}

input::-webkit-input-placeholder {
    color: $color-gray-1;
    vertical-align: middle;
    line-height: normal;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: var(--font-pri);
    line-height: 5.2rem;
}

.form {
    background: $color-white;
    padding: 3.6rem;
    color: $color-gray-2;
    @include max(1200px) {
        padding: 2.4rem;
    }
    @include max(768px) {
        padding: 2rem;
    }
}

.form .heading {
    color: $color-gray-2;
    margin-bottom: 1.2rem;
}

.form .form-list {
    margin-top: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem 0;
    @include max(768px) {
        margin-top: 1.2rem;
    }
}

.form .form-it {
    input {
        font-size: 1.6rem;
        color: $color-gray-2;
        background-color: $color-white;
        padding: 1.2rem 3.6rem 0.8rem 1.6rem;
        transition: all .3s;
    }
    input[type="file"],
    input[type="date"] {
        display: inline-block;
        width: 100%;
        height: 3.2rem;
        overflow: hidden;
        box-sizing: border-box;
        opacity: 0;
    }
    .input {
        border: 0.1rem solid #E2E8F0;
        transition: all .3s;
        &:hover,
        &:focus {
            border: .1rem solid #64748B;
        }
    }
    textarea {
        width: 100%;
        min-height: 16.7rem;
        background-color: $color-white;
        border: 0.1rem solid #E2E8F0;
        outline: none;
        font-size: 1.6rem;
        font-weight: 400;
        font-family: var(--font-pri);
        padding: 1.4rem 1.6rem;
        transition: all .3s;
        &:hover,
        &:focus {
            border: .1rem solid #64748B;
        }
    }
}
.form-it.btn-action{
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  .btn{
    flex: 1 0 200px;
  }
}
.form .form-label {
    margin-bottom: 0.8rem;
    display: block;
}

.form .form-list .btn-submit {
    display: block;
    width: 100%;
}

.checkbox {
    &:not(:last-child) {
        margin-bottom: 1.2rem;
    }
    &-input {
        display: none;
    }
    &-label {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        color: $color-text;
        font-weight: 300;
        font-family: var(--font-pri);
        line-height: normal;
        @include fz-16;
        margin-right: 0;
        margin-left: 0;
        transition: all .2s;
    }
}

.form-discount .form-control+.form-label,
.checkbox-input+.checkbox-label {
    cursor: pointer;
    &:before {
        content: '';
        background: transparent;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: $radius-1;
        transition: all .2s;
    }
}

.form-discount .form-control+.form-label {
    cursor: pointer;
    &:before {
        border: 0.16rem solid #F93;
        position: absolute;
        right: 1.6rem;
        top: 50%;
        transform: translateY(-50%);
        @include max($mobile) {
            right: 0.8rem;
        }
    }
}

.form-discount .form-control:checked+.form-label,
.checkbox-input:checked+.checkbox-label {
    &::after {
        content: '';
        height: 0.4rem;
        padding: 0.2rem;
        text-align: center;
        border: solid #FFFFFF;
        border-width: 0 0.2rem 0.2rem 0;
        transition: all .2s;
        vertical-align: middle;
    }
}

.form-discount .form-control:checked+.form-label {
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 2.7rem;
        height: 0.4rem;
        transform: rotate(45deg) translateY(-70%);
        @include max($mobile) {
            right: 1.9rem;
        }
    }
    &::before {
        background: $color-third;
    }
}

.checkbox-input+.checkbox-label {
    &:before {
        margin-right: 1.2rem;
        border: 0.16rem solid #D9D9D9;
        margin-top: -0.8rem;
    }
}

.checkbox-input:checked+.checkbox-label {
    &::after {
        content: '';
        position: absolute;
        top: -0.2rem;
        left: 0.6rem;
        transform: rotate(45deg);
    }
    &::before {
        background: $color-third;
        border: 0.16rem solid $color-third;
    }
}

.inner-radio-white {
    [type="radio"] {
        &+label {
            position: relative;
            cursor: pointer;
            transition: all .3 ease;
        }
        &:checked {
            &+label {
                background-color: $color-white;
                color: $color-gray-2;
            }
        }
    }
}

.input-date-custom {
    opacity: 1 !important;
    height: 4.35rem !important;
    padding-right: 9px !important;
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
    }
    &::-webkit-calendar-picker-indicator {
        opacity: 0;
        cursor: pointer;
    }
    &::-webkit-input-placeholder {
        color: red !important;
    }
}

.input-error {
    border-color: rgb(239 68 68) !important;
}

.text-error {
    color: rgb(239 68 68) !important;
}

.mb-input {
    margin-bottom: 12px;
}

.form .mb-input {
    margin-bottom: 0px !important;
}

.input-has-icon {
    position: relative;
    i,
    svg {
        position: absolute;
        right: 1.3rem;
        top: 50%;
        transform: translateY(-50%);
    }
}