.news {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: #0F172A;
        z-index: -20;
        pointer-events: none;
    }
}

.news .news-head.sec-heading {
    margin-bottom: 0;
}

// .news-list {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-template-rows: auto auto auto;
//     grid-column-gap: 2.4rem;
//     grid-row-gap: 2.4rem;
//     grid-template-areas:
//         "h1 h2"
//         "h1 h3"
//         "h1 h4";
// }
// .news-item:nth-child(1) {
//     grid-area: h1;
// }
// .news-item:nth-child(2) {
//     grid-area: h2;
// }
// .news-item:nth-child(3) {
//     grid-area: h3;
// }
// .news-item:nth-child(4) {
//     grid-area: h4;
// }
.news-image {
    width: 100%;
}

.news-inner .image {
    display: block;
    position: relative;
    overflow: hidden;
    height: 100%;
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0.8rem;
        left: 0.8rem;
        right: 0.8rem;
        bottom: 0.8rem;
        border-radius: $radius-2;
        box-sizing: border-box;
        transition: 0.5s;
        z-index: 10;
        opacity: 0;
    }
    &::before {
        transform: scaleX(0);
        border-top: 0.12rem solid $color-white;
        border-bottom: 0.12rem solid $color-white;
    }
    &::after {
        border-left: 0.12rem solid $color-white;
        border-right: 0.12rem solid $color-white;
        transform: scaleY(0);
    }
    &:hover {
        &::before {
            transform: scaleX(1);
            opacity: 1;
        }
        &::after {
            transform: scaleY(1);
            opacity: 1;
        }
        img {
            opacity: .9;
            transform: scale(1.06) translate(-50%, -50%);
        }
    }
    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.5s;
    }
}

.news-item .news-inner .image {
    padding-top: calc(140/197*100%);
    &::after,
    &::before {
        border: unset;
    }
}

.news-item:nth-child(1) .news-inner .image {
    padding-top: calc(421/592*100%);
}

.news-item:nth-child(1) {
    @include max($mobile) {
        width: 100%;
    }
}

.news-item:nth-child(1) .news-inner .desc {
    -webkit-line-clamp: 3;
}

.news-inner .news-content .sub-title {
    display: block;
    margin-bottom: 1.2rem;
    font-weight: 700;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .2s;
    &:hover {
        cursor: pointer;
        color: $color-second;
    }
}

.news-wr {
    padding-bottom: var(--sp-sc);
    @include max($mobile) {
        padding-bottom: 2.4rem;
    }
}

.news-inner .news-content .desc {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-inner .news-content {
    margin-top: 2.4rem;
}

.film-news-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @include max($mobile) {
        display: none;
    }
}

.link-more {
    font-size: 1.5rem;
    text-decoration: underline;
    text-underline-position: under;
    display: block;
    transition: all .3s;
    cursor: pointer;
    &:hover {
        color: $color-pri;
    }
}

.news-inner .link-more {
    margin-top: 2.4rem;
    @include max($res-mobile) {
        margin-top: 1.2rem;
    }
}

.news-item {
    &:not(:first-child) {
        @include max($mobile) {
            width: 100%;
        }
        .news-inner {
            display: flex;
            margin: 0 -1.2rem 2.4rem;
            @include max($mobile) {
                margin: 0 -0.6rem 1.2rem;
            }
        }
        .news-content {
            margin-top: 0;
            padding: 0 1.2rem;
            width: 55%;
            @include max($mobile) {
                padding: 0 0.6rem;
                width: 45%;
            }
        }
        .desc {
            font-size: 1.4rem;
        }
        .news-image {
            width: 45%;
            padding: 0 1.2rem;
            @include max($mobile) {
                padding: 0 0.6rem;
                width: 55%;
            }
        }
    }
}

.film-news .sec-heading {
    text-align: left;
}

.hot-news-item {
    @include max($mobile) {
        width: 100%;
    }
}

.hot-news-heading .heading {
    text-align: left;
}

.hot-news-item .news-inner {
    @include max($mobile) {
        display: flex;
        width: 100%;
        margin: 0 -0.6rem;
    }
}

.hot-news-item .news-inner .news-image {
    @include max($mobile) {
        width: 55%;
        padding: 0 0.6rem;
    }
}

.hot-news-item .news-inner .news-content {
    @include max($mobile) {
        width: 45%;
        padding: 0 0.6rem;
        margin-top: 0;
    }
}

.hot-news-item .news-inner .image {
    padding-top: calc(300/384*100%);
}

.hot-news-list {
    padding-bottom: 4.8rem;
    @include max(1200px) {
        padding-bottom: 3.2rem;
    }
    @include max($mobile) {
        padding-bottom: 0;
        --row-gap: 1.2rem;
    }
}