.fqa {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: #0F172A;
        z-index: -20;
        pointer-events: none;
    }

    @include max($mobile) {
        min-height: 100vh;
    }
}

.fqa-left {
    @include max($mobile) {
        width: 100%;
        margin-bottom: 0;
    }
}

.fqa-right {
    @include max($mobile) {
        width: 100%;
        margin-bottom: 0;
    }
}

.fqa-heading {
    text-align: left;

    @include max($mobile) {
        text-align: center;
    }
}

.fqa-item .icon {
    font-size: 1.8rem;
    transition: .3s ease-in-out;
    display: block;
}

.fqa-item.active .icon {
    transform: rotate(180deg);
    color: $color-second;
}

.fqa-item.active .txt-lg {
    color: $color-second;
}

.fqa-item .txt-lg {
    transition: .3s ease-in-out;
}

.fqa-item.active .fqa-item-heading {
    background: linear-gradient(106deg, #639 0%, #36C 102.69%);
}

.fqa-item-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2.4rem;
    background: transparent;
    cursor: pointer;
    position: relative;

    @include max($mobile) {
        padding: 1.2rem;
    }

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100%;
        background: linear-gradient(106deg, #639 0%, #36C 102.69%);
        transition: all .4s;
        z-index: -1;
    }

    &:hover {
        &::after {
            opacity: 0.6;
            visibility: visible;
        }
    }
}

.fqa-item-body {
    margin-top: 1.2rem;
    padding: 2.4rem 0;
    display: none;
}