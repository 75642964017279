.not-found {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8rem;
    // background-color: white;
    height: auto;
    .box {
        box-shadow: 5px 3px 3px #e7e5e5;
        border-radius: 10px;
        width: 650px;
        background-color: #efefef;
        padding: 5rem;
        .logo {
            width: 20rem;
            height: auto;
            margin-bottom: 3rem;
        }
        .content {
            margin-bottom: 1rem;
            p {
                font-size: 2.2rem;
                font-weight: 600;
            }
        }
        .url-back {
            a {
                color: #C60;
                border-bottom: solid 0.1rem #C60;
            }
            a:hover {
                color: rgb(57, 56, 56);
                border-bottom: solid 0.1rem rgb(57, 56, 56);
            }
        }
    }
    @include max($mobile){
      .box{
        padding: 3rem 2rem;
        text-align: center;
        .logo{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            max-width: 20rem;
          }
        }
      }
    }
}
body:has(.not-found){
  min-height: auto;
}