.hd {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.4s;
    z-index: var(--z);
    display: inline-block;
    height: var(--sz-hd);
    background-color: $color-gray-2;
  
    &.--second {
        transform: translateY(calc(var(--sz-hd-top) * -1));
    }
    &.--third{
      transform: translateY(calc(var(--sz-hd-top) * -2));
    }
}

.hd-no-top {
  // height: calc(var(--sz-hd-mid) + var(--sz-hd-bot)) !important;
  height: calc(var(--sz-hd-mid) + var(--sz-hd-bot)) !important;
}
.hd-pop-up {
  height: calc(var(--sz-hd-mid) + var(--sz-hd-bot) + var(--sz-popup-top)) !important;
}
.hd-top {
    overflow: hidden;
    height: var(--sz-hd-top);
    background-color: $color-text;
    background-color: rgb(202, 200, 200);
    // @include max($res-mobile) {
    //     display: none;
    // }
    .popup-top-logo{
      height: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 0;
      gap: 3rem;
      img {
        max-height: 4.1rem;
        max-width: 4.1rem;
        border-radius: .8rem;
      }
    }
    .popup-top-content {
      color: $color-gray-2;
      display: flex;
      align-items: center;
      gap: 2rem;
      .link {
          text-decoration: none;
          padding: 0.3rem 1.2rem;
          text-transform: capitalize;
          color: #639;
          border:  1px solid #639;
          border-radius: $radius-1;
          line-height: 1.5;
      }
      .text{
        display: flex;
        gap: 0.5rem;
      }
      @include max($mobile){
        font-size: 1rem;
        justify-content: space-around;
        gap: 1.5rem;
        .link{
          padding: 0.6rem 1.2rem;
          line-height: 1;
        }
        .text{
          display: flex;
          flex-direction: column;
          gap: 0;
        }
      }
    }
    &-wr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        height: 100%;
    }
    &-content {
        color: $color-gray-2;
        display: flex;
        align-items: center;
        gap: 1rem;
        .link {
            color: $color-pri;
            text-decoration: underline;
            text-decoration-thickness: .2rem;
        }
        .txt{
          @include max($mobile){
            display: none;
          }
        }
    }
    &-close {
        width: 2.4rem;
        cursor: pointer;
    }
}

.popup-download-app{
  @include max($mobile){
    .popup-top-logo{
      justify-content: space-between;
      flex: 1;
      gap: 1rem;
      img {
        max-height: 3.8rem;
        max-width: 3.8rem;
      }
    }
    // .hd-top-close{
    //   display: none;
    // }
    .hd-top .popup-top-content{
      flex: 1;
      justify-content: space-between;
      font-size: 1.2rem;
      .txt{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .hd-top .popup-top-content{
      font-size: 1rem;
    }
    .popup-top-logo{
      img {
        max-height: 3.2rem;
        max-width: 3.2rem;
        border-radius: .6rem;
      }
    }
  }

}
.hd-mid {
    height: var(--sz-hd-mid);
    .custom-logo-link {
        width: 13rem;
        display: flex;
        @include max($mobile) {
            width: 11rem;
        }
        img {
            width: 100%;
        }
    }
    &-wr {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 3.2rem;
        @include max($res-mobile) {
            gap: 1.6rem;
        }
    }
    &-right {
        display: flex;
        align-items: center;
        gap: 3.6rem;
        @include max($res-mobile) {
            gap: 1.6rem;
        }
    }
}

.hd-bot {
    height: var(--sz-hd-bot);
    @include max($mobile) {
        display: none;
    }
    &-wr {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        border-top: .1rem solid rgba($color: $color-text, $alpha: .1);
    }
    &-loca {
        display: flex;
        // align-items: center;
        gap: .6rem;
        img {
            width: 2rem;
            flex-shrink: 0;
        }
        i{
          margin-top: .2rem;
          font-family: "Font Awesome 6 Pro";
        }
        // .txt {
        //     text-decoration: underline;
        //     text-underline-position: under;
        // }
        @include hover {
            color: $color-second;
        }
    }
    &-km {
        display: flex;
        align-items: center;
        gap: 2.4rem;
        .link {
          transition: .3s ease-in-out;
            @include hover {
                color: $color-second;
                text-decoration: underline;
                text-underline-position: under;
            }
        }
    }
}

.hd-action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex: 1;
    .btn {
        font-size: 1.4rem;
        @include max($mobile) {
            display: none;
        }
        img {
            width: 1.8rem;
        }
    }
}

.hd-search {
    position: relative;
    &-icon {
        --sz: 2.4rem;
        width: var(--sz);
        height: var(--sz);
        border-radius: 100rem;
        border: .2rem solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include max($tablet) {
            --sz: 3.2rem;
        }
        @include max($mobile) {
            display: none;
        }
        img {
            width: 50%;
        }
        @include min($res-mobile-min) {
            display: none;
        }
    }
    &-block {
        @include max($res-mobile) {
            position: fixed;
            top: var(--sz-hd);
            left: 0;
            width: 100%;
            // background-color: $color-gray-2;
            z-index: -4;
            // padding: 1rem;
            transition: .3s ease-in-out;
            @include hidden(0, -1rem);
            &.open {
                @include show;
            }

            .hd-search-form {
              padding: 1rem;
              background-color: $color-gray-2;
            }

            .search-bg-blur{
              height: calc(100vh - var(--size-mobile-nav-bt) - var(--sz-hd-mid)  - var(--sz-hd-top) - var(--size-mobile-nav-bt));
              background: rgba(0, 0, 0, 0.5);
            }

            .search-bg-blur-no-top {
              height: calc(100vh - var(--size-mobile-nav-bt) - var(--sz-hd-mid) - var(--size-mobile-nav-bt)) !important;
            }
        }
    }
    &-block-no-top {
      @include max($res-mobile) {
        top: calc(var(--sz-hd) - var(--sz-hd-top)) !important;
      }  
    }

    &-wr {
        display: flex;
        align-items: center;
        width: 25rem;
        position: relative;
        @include max($res-mobile) {
            width: 100%;
        }
        input {
            height: 4rem;
            background-color: white;
            border-radius: 100rem;
            padding: 0 1.6rem;
            font-size: 1.4rem;
            width: 100%;
            padding-right: 3rem;
            font-weight: 400;
            line-height: 1;
            @include max($res-mobile) {
                background-color: transparent;
                width: 100%;
                border: .1rem solid rgba($color: $color-text, $alpha: .1);
                border-radius: .8rem;
            }
            &::placeholder {
                font-weight: 400;
            }
        }
    }
    &-form {
        &-btn {
            position: absolute;
            @include posi-vertical;
            right: 1rem;
            width: 1.6rem;
            img {
                width: 100%;
            }
        }
    }
}

.hd-regi {
    &-wr {
        display: flex;
        align-items: center;
        gap: 1rem;
        position: relative;
        flex-shrink: 0;
        cursor: pointer;
    }
    .link {
        @include hover {
            color: $color-second;
        }
    }
    &-name {
        position: relative;
        top: .2rem;
        text-transform: uppercase;
        @include lc(1);
        max-width: 8rem;
        font-size: 1.4rem;
    }
    &-ava {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        position: relative;
        top: -.2rem;
        @include max($tablet) {
            width: 3.2rem;
            height: 3.2rem;
            top: 0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    @include max($tablet) {
        &::before {
            @include pseudo;
            top: 100%;
            height: 1rem;
            width: 2.4rem;
        }
    }
    &:hover {
        .hd-regi-list {
            @include show;
        }
    }
    &-list {
        display: flex;
        align-items: center;
        gap: .4rem;
        z-index: 1;
        &-custom{
          width: 100%;
        }
        @include max($tablet) {
            position: absolute;
            top: calc(100% + 1rem);
            right: 0;
            flex-direction: column;
            align-items: flex-start;
            background-color: $color-gray-2;
            border-radius: .4rem;
            border: .1rem solid rgba($color: $color-text, $alpha: .1);
            gap: 0;
            // hidden
            transition: .3s ease-in-out;
            @include hidden(0, 1rem);
            .link {
                white-space: nowrap;
                padding: .8rem 1rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                width: 100%;
            }
            .dot {
                display: none;
            }
        }
        &.--second {
            position: absolute;
            top: calc(100% + 1rem);
            right: 0;
            flex-direction: column;
            align-items: flex-start;
            background-color: $color-gray-2;
            border-radius: .4rem;
            border: .1rem solid rgba($color: $color-text, $alpha: .1);
            gap: 0;
            // hidden
            transition: .3s ease-in-out;
            @include hidden(0, .5rem);
            &.custom-position-left{
              left: 0;
              right: auto;
            }
            .dot {
                display: none;
            }
            .link {
                white-space: nowrap;
                padding: .8rem 1rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                width: 100%;
                transition: .3s ease-in-out;
                @include hover {
                    color: $color-second;
                }
                i {
                    display: block;
                }
            }
        }
        &-custom.--second{
          top: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 1.5rem 1rem;
          row-gap: .5rem;
        }
    }
    .link {
        i {
            vertical-align: middle;
            position: relative;
            top: -.3rem;
            @include min($tablet-min) {
                display: none;
            }
        }
    }
}

.hd-lg {
    @include max($mobile) {
        display: none;
    }
    .lg {
        &-option {
            font-size: 1.4rem;
            .txt {
                position: relative;
                top: .2rem;
            }
        }
    }
    // .lg-popup{
    //   .lg-option{
    //     padding: .2rem .8rem;
    //     border: 1px solid $color-white;
    //     border-radius: .8rem;
    //   }
    // }
}

.hd-burger {
    @include min($mobile-min) {
        display: none;
    }
}

.burger {
    width: 3.2rem;
    height: 3.2rem;
    // background-color: red;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .line {
        height: .2rem;
        background-color: $color-text;
        border-radius: 100rem;
        width: 2.4rem;
        flex-shrink: 0;
        display: block;
        transition: .3s ease-in-out;
        &:nth-child(2) {
            margin: .5rem 0;
        }
    }
    svg {
        position: absolute;
        @include full;
        path {
            stroke: $color-text;
            stroke-width: 5;
            transition: all 0.3s linear;
            fill: none;
            stroke-dasharray: 307.919, 307.919;
            stroke-dashoffset: 307.919;
        }
    }
    &.active {
        .line {
            width: 1.6rem;
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(1) {
                transform: rotate(45deg) translate(0.51rem, 0.5rem);
            }
            &:nth-child(3) {
                transform: rotate(-45deg) translate(0.5rem, -0.48rem)
            }
        }
        svg {
            path {
                stroke-dashoffset: 0;
                transition-delay: .3s;
            }
        }
    }
}

.select-localtion-header__popup{
    .ant-select-item-option-content{
      @include max($tablet){
            font-size: 1.4rem;
          }
          @include max($mobile){
            font-size: 1.2rem;
          }
  }
}
.form-career-popup{
  max-height: 90dvh;
  overflow-y: auto;
}
.select-location-header{
  .sec-heading{
    margin-top: 0;
  }
  .sub-title{
    font-size: 2rem;
    margin-right: .75rem;
  }
  .container{
    padding: 0;
    .select-location-second{
      width: 18rem;
      height: auto;
      &::before{
        @include borderArrow("down", .5rem, $color-second);
      }  
      .ant-select-selector{
        padding-top: .3rem;
        padding-bottom: .3rem;
        background: transparent;
        // border: .1rem solid $color-second!important;
        .ant-select-selection-item{
          font-size: 1.4rem;
          letter-spacing: .1rem;
          text-transform: capitalize;
          color: $color-second;
        }
      }
      .ant-select-arrow{
        display: none;
      }
    }
  }
  &.error-field.filter-career-form{
    .select-location-second{
      border: 0.1rem solid red;
    }
  }
  &.filter-career-form{
    .filter{
      width: 100%;
    }
    .select-location-second{
      width: 100%!important;
      border: 0.1rem solid #E2E8F0;
      &::before{
        @include borderArrow("down", .5rem, $color-black);
      }
      .ant-select-selector{
        padding: .8rem 3.6rem .8rem 1.6rem;
        transition: all .3s;
      }
    }
    .ant-select-selector .ant-select-selection-item{
      color: black!important;
      font-family: var(--font-pri);
    }
    .ant-select-focused .ant-select-selector{
      box-shadow: none!important;
    }
  }
  @include max(376px){
    .container{
      .select-location-second{
        width: 16rem;
        .ant-select-selector{
          padding: 0 .8rem;
          .ant-select-selection-item{
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  @include max(326px){
    .container{
      .select-location-second{
        width: 13rem;
        &::before{
          right: 1rem;
        }
        .ant-select-selector{
          .ant-select-selection-item{
            font-size: 1rem;
            padding-inline-end: 1.2rem;
          }
        }
      }
    }
  }
}
.filter-career-wrapper{
  margin-bottom: 2rem;
}
.filter-career{
  margin-right: .75rem;
  cursor: pointer;
  svg{
    fill: $color-second;
  }
}
.hd-mid-wr{
  @include max(376px){
    gap: .3rem;
  }
}
.hd-bot-action-wrap{
  display: flex;
  gap: 4rem;
}
