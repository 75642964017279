.popup {
  position: fixed;
  @include full;
  z-index: 999;
  visibility: hidden;
  pointer-events: none;

  &.open {
      visibility: visible;
      pointer-events: visible;

      .popup-overlay {
          @include show;
          height: 100%;
      }

      .popup-main {

          transform: translate(-50%, -50%);
          opacity: 1;
          visibility: visible;
          @include transition(.3s, ease-in-out, .1s);
      }
  }

  &-overlay {
      position: absolute;
      background-color: rgba($color: #000, $alpha: 0.8);
      @include full;
      z-index: 1;
      @include transitionRe;
      opacity: 0;
      visibility: hidden;
  }

  &-main {
      @include mid;
      z-index: 2;
      width: 70rem;
      max-width: 95%;
      @include transitionRe;
      opacity: 0;
      visibility: hidden;

      &-wrapper {
          overflow: hidden;
          border-radius: 6px;
      }
  }

  &-over {
      overflow-y: auto;
      min-height: 40vh;
      max-height: 80vh;
  }

  &-wrapper {
      background-color: white;
      width: 100%;
      min-height: 40vh;
      position: relative;
      padding: 4.8rem 2rem 2rem 2rem;
  }

  &-title {
      @include fz-24;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 1.6rem;
  }

  &-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      transition: 0.6s ease-in-out;
      width: 3rem;
      height: 3rem;
      @include mid-flex;
      background-color: white;
      z-index: 3;

      &:hover {
          .icon {
              transform: rotate(360deg);
          }
      }

      .icon {
          color: #000;
          font-weight: 300;
          font-size: 1.6rem;
          @include transition(0.6s, ease-in-out);
      }
  }
}

.popup-logout{
  .popup-main{
    width: 31rem;
  }
  .popup-over{
    min-height: 20vh;
  }
  .popup-wrapper{
    min-height: 20vh;
    padding: 2.4rem;
    background: linear-gradient(106deg, #639 0%, #36C 102.69%);
  } 
  .popup-close{
    display: none;
  }
  &-wr{
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  &-title{
    text-align: center;
    font-weight: 600;
  }
  &-ctr{
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
}

.popup-gg{
  .popup-main{
    width: 60rem;
  }
  .popup-over{
    min-height: unset;
  }
  .popup-wrapper{
    padding: 3.6rem;
    min-height: unset;
    @include max($mobile){
      padding: 1.2rem;
    }
  }
  .form-label{
    &::before,
    &::after{
      display: none !important;
    }
  }

  .form-label{
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    flex-direction: row !important;
  }
  &-checkbox{
    width: 1.6rem;
    height: 1.6rem;
    border: .1rem solid $color-third;
    position: relative;
    &::before{
      content: '\f00c';
      font-family: "Font Awesome 6 Pro";
      @include mid;
      width: 100%;
      height: 100%;
      font-size: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: $color-third;
      opacity: 0;
    }
  }

  .form-discount{
    .subtitle{
      color: #0F172A;
      @include fz-24;
      margin-bottom: 1.2rem;
    }
    .form-control:checked ~ .form-label .popup-gg-checkbox::before{
      opacity: 1;
    }
    .inner-radio.not-action{
      .popup-gg-checkbox{
        border-color: #94A3B8;
      }
    }
  }
  &-search{
    margin-bottom: 1.2rem;
    input{
      border: .1rem solid #E2E8F0;
    }
  }
}

.popup-noti{
  .popup-main{
    width: 40rem;
  }
  .popup-over{
    min-height: 10vh;
  }
  .popup-close{
    display: none;
  }
  .popup-wrapper{
    padding: 2.4rem;
    min-height: 10vh;
    background: linear-gradient(106deg, #639 0%, #36C 102.69%);
    text-align: center
  }
  &-wr{
    display:flex;
    flex-direction: column;
    gap: 1.6rem;
  }
  &-title{
    @include fz-24;
    font-family: var(--font-title);
    text-transform: uppercase;
  }
  .btn{
    max-width: 40rem;
    margin: 0 auto;
  }
  &.--w7{
    .popup-main{
      width: 68rem;
    }
  }
}
.popup-noti-extra{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  & div{
    flex: 1;
  }
}
.popup-promotion{
  .popup-over{
    overflow-y: hidden
  }
  .popup-main{
    width: 70rem;
  }
  .popup-over{
    min-height: 20vh;
  }
  .popup-wrapper{
    padding: 0;
    min-height: 20vh;
    background: linear-gradient(106deg, #639 0%, #36C 102.69%);
    text-align: center;
    text-transform: uppercase;
  }
  &-wr{
    display:flex;
    flex-direction: column;
    gap: 1rem;
  }
  &-title{
    @include fz-22;
    font-weight: 700;
    
  }
  &-main{
    @include fz-72;
    font-weight: 700;
  }
  &-ctr{
    margin-top: 1.8rem;
  }
  &-btn{
    @include fz-20;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 5px; 
  }

  .popup-close{
    width: 2.4rem;
    height: 2.4rem;
  }
  &.--w7{
    .popup-main{
      width: 68rem;
    }
  }
}