.movie-vip {
    padding-bottom: var(--sp-sc);
}

.movie-coming {
    padding-bottom: var(--sp-sc);
}

.web-movie-list {
    position: relative;
    @include max($mobile) {
        width: calc(100% + var(--pd-container));
    }
}

.web-movie-item {
    width: calc(3/12*100%);
    @include max(1200px) {
        width: calc(4.5/12*100%);
    }
    @include max($mobile) {
        width: calc(5/12*100%);
    }
}

.web-movie-box {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    &:hover {
        .content {
            opacity: 1;
            visibility: visible;
        }
        .inner {
            opacity: 1;
            transform: translate(0, 0);
        }
        .attach {
            opacity: 1;
            transform: translateY(-100%);
            @include max($mobile) {
                transform: translateY(0);
            }
        }
        .info .name {
            color: $color-second;
        }
    }
}

.web-movie-box .image {
    position: relative;
    padding-top: calc(420/280*100%);
    display: block;
    width: 100%;
    height: auto;
    font-size: 1rem;
    border-radius: .4rem;
    overflow: hidden;
    border: .1rem solid  #808080;
    >img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
    }
}

.web-movie-box .info {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    flex: 1;
    gap: 1.6rem;
    &.extra-premiere{
      margin-top: 1.2rem;
      gap: 1.2rem;
    }
    & .date-premiere{
      color: rgba($color: #ffffff, $alpha: .78)
    }
    @include max($mobile) {
      gap: 0.8rem;
      & .date-premiere{
        font-size: 1.2rem;
      }
  }
}

.web-movie-box .content {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: .3s ease-in-out;
    opacity: 0;
    &::before {
        content: "";
        position: absolute;
        @include full;
        background-color: rgba($color: #000000, $alpha: .8);
    }
    @include max($mobile) {
        display: none;
    }
}

.web-movie-box .inner {
    max-width: calc(100% - 1.2rem);
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    opacity: 0;
    transform: translateY(1rem);
    transition: .3s ease-in-out;
}

.web-movie-box .inner .name {
    margin-bottom: 2.4rem;
    @include max($mobile) {
        margin-bottom: 0.8rem;
    }
}

.web-movie-box .inner .txt {
    font-size: 1.2rem;
    font-family: 'Inter', sans-serif;
}

.web-movie-box .info .info-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.web-movie-box .attach {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    transform: translateY(0);
}

.web-movie-box .attach .type-movie {
    padding: 0.8rem;
    background-color: #F93;
    color: $color-black;
    font-family: var(--font-title);
    text-transform: uppercase;
    height: 4.4em;
    @include max($mobile) {
        padding: 0.4rem;
        font-size: 0.6rem;
        height: 2.2rem;
    }
}

.web-movie-box .attach .label-3d{
  background-color: $color-pri;
  color: white;
  .txt{
    border-color: white!important;
  }
}
.web-movie-box .attach .type-movie .txt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 0.4rem;
    border: 0.2rem solid $color-black;
    font-size: 1.2rem;
    @include max($mobile) {
        border: 0.16rem solid $color-black;
        width: 1.4rem;
        height: 1.4rem;
        font-size: 0.6rem;
    }
}

.web-movie-box .attach .age {
    padding: 0.5rem;
    background-color: #F03;
    display: flex;
    flex-direction: column;
    font-family: var(--font-title);
    text-transform: uppercase;
    text-align: center;
    height: 4.4em;
    @include max($mobile) {
        padding: 0.26rem;
        height: 2.2em;
    }
}

.web-movie-box .attach .age .num {
    font-size: 2.4em;
    letter-spacing: 0.18rem;
    text-indent: 0.18rem;
    line-height: 100%;
    @include max($mobile) {
        font-size: 1.2em;
        padding-bottom: 0.1em;
    }
}

.web-movie-box .attach .age .txt {
    padding: 0.2rem;
    background-color: $color-black;
    font-size: 0.42em;
    letter-spacing: 0.4rem;
    text-indent: 0.4rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    @include max($mobile) {
        display: none;
        font-size: 0.32em;
        padding: 0.1rem;
    }
}

.web-movie-box .info .name {
    font-size: 1.8rem;
    font-weight: 600;
    transition: all .2s;
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.web-movie-box .info .btn {
    width: 15rem;
    @include max(1200px) {
        width: 12rem;
    }
    @include max($mobile) {
        width: 100%;
        font-size: 1.2rem;
    }
}

.web-movie-box .info .video {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    position: relative;
    @include max(1200px) {
        gap: 0.4rem;
    }
    @include max($mobile) {
        display: none;
    }
}

.not-btn-ticket .info .video {
    margin-left: auto;
    margin-right: auto;
    @include max($mobile) {
        display: flex;
        width: 100%;
    }
}

.web-movie-box .video .ic {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100rem;
    border: .1rem solid rgba($color: white, $alpha: .5);
    @include max($mobile) {
        width: 1.8rem;
        height: 1.8rem;
    }
}

.web-movie-box .video .txt {
    font-size: 1.4rem;
    text-decoration: underline;
    text-underline-position: under;
}

.not-btn-ticket .web-movie-box .video .txt {
    text-decoration: none;
    font-size: 1.6rem;
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.web-movie-box .content .name {
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.web-movie-box .content .info-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 1.2rem;
    @include max($mobile) {
        gap: 0.4rem;
        margin-bottom: 0.8rem;
    }
}

.web-movie-box .info-item .ic {
    display:flex;
    align-items: center;
    width: 1.8rem;
    height: 1.8rem;
    @include max(460px) {
        width: 1.2rem;
        height: 1.2rem;
    }
    >img {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.web-movie-pagination.swiper-pagination {
    bottom: -4.8rem;
    @include max(1200px) {
        bottom: -3.2rem;
    }
}

.not-btn-ticket .info-action {
    background-color: transparent;
    outline: none;
    border: none;
    text-align: center;
    font-family: var(--font-title);
    text-transform: uppercase;
    border-radius: $radius-1;
    overflow: hidden;
    cursor: pointer;
    color: $color-gray-2;
    position: relative;
    padding: 0.8rem 1.2rem;
    width: 15rem;
    margin: 0 auto;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    @include max($mobile) {
        width: 100%;
        font-size: 1.2rem;
    }
    &::before,
    &::after {
        @include pseudo;
        height: 100%;
        width: 0.1rem;
        transition: all 0.4s ease-in-out;
    }
    &::before {
        width: 100%;
        background: $color-second;
        z-index: -2;
        right: 0;
        top: 0;
    }
    &::after {
        bottom: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: linear-gradient(106deg, #639 0%, #36C 102.69%);
        z-index: -1;
    }
    &:hover {
        color: $color-white;
        &::before {
            width: 0;
        }
        &::after {
            width: 100%;
        }
    }
}

.movie-showing-custom{
  .col{
    margin-bottom: calc(var(--row-gap) + 3rem);
  }
  .web-movie-box{
    .info-action{
      margin-top: 0;
    }
  }
  @include max($mobile){
    .col{
      margin-bottom: calc(var(--row-gap) + 1rem);
    }
  }
}