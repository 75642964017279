.loading-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-content {
  position: relative;
  z-index: 1;
}

.loading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); 
  color: white; 
  z-index: 2;
}
