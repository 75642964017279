:root {
    --font-title: 'Anton', sans-serif;
    --font-pri: 'Josefin Sans', sans-serif;
    --size-hd: 12rem;
    --size-note: 4rem;
    --size-hd-total: 16rem;
    --size-hd-sm: 6rem;
    --size-mobile-nav-bt: 6rem;

    // header
    --sz-hd-top: 5.8rem;
    --sz-hd-mid: 7.8rem;
    --sz-hd-bot: 5rem;
    --sz-hd: calc(var(--sz-hd-mid) + var(--sz-hd-bot) + var(--sz-hd-top));

    // Common
    --z: 55;
    --pd-container: 1.5rem;
    --w-container: 123rem;
    --rs: .8rem;
    --sp-sc: 10rem;
    --pc: calc((100vw - var(--w-container))/2);
    
    @media screen and (max-width: 1200px) {
        --sz-hd-mid: 6rem;
        --sp-sc: 4rem;
    }

    @media screen and (max-width: 700px) {
        --sz-hd-bot: 0rem;
        --rs: .4rem;
    }

    @media screen and (max-width: 375px) {
      --sz-hd-top: 5rem;
    }
}

//Color
$color-pri: #639;
$color-second: #F3EA28;
$color-third: #F93;
$color-four: #36C;
$color-text: #F8FAFC;

$color-black: #000000;
$color-white: #FFFFFF;

$color-gray: #CBD5E1;
$color-gray-1: #94A3B8;
$color-gray-2: #0F172A;

$color-input: #D9D9D9;
$color-input-1: #999;
$color-input-2: #E9E9E9;
$radius-1: 0.4rem;
$radius-2: 0.8rem;
$radius-3: 1.2rem;
$radius-4: 1.6rem;

//Responsive
$xxs-min: 376px;
$xs-min: 576px;
$sm-min: 768px;
$md-min: 992px;
$lg-min: 1200px;
$xl-min: 1400px;

$res-mobile: 1199px;
$res-mobile-min: 1200px;
$mobile: 767px;//700
$mobile-min: 768px;//701


$tablet: 1024px;
$tablet-min: 1025px;


@mixin max($value) {
    @media screen and (max-width: $value) {
        @content;
    }
}