.re-search {
   position: relative;

   .container {
      max-width: 84rem;
   }

   &-br {
      position: absolute;
      @include full;
      z-index: -1;

      &::before {
         @include pseudo;
         @include full;
         z-index: 1;
         background-color: rgba($color: $color-gray-2, $alpha: .85);
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &-form {
      --h: 4.8rem;

      @include max($mobile) {
         --h: 4rem;

      }

      &-wr {
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         gap: 1rem;

         @include max($mobile) {
            gap: 0;
         }
      }

      input {
         flex: 1;
         border-radius: .5rem;
         height: var(--h);
         padding-top: 0;
         padding-bottom: 0;

         @include max($mobile) {
            border-radius: .5rem 0 0 .5rem;
            font-size: 1.2rem;
         }
      }

      &-btn {
         height: var(--h);
         min-width: 23.6rem;

         @include max($mobile) {
            border-radius: 0 .5rem .5rem 0;
            font-size: 1.2rem;
            min-width: 12rem;
         }

         img {
            width: 1.6rem;
            flex-shrink: 0;
         }
      }
   }

   &-tg {
      margin-top: 2.4rem;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      gap: .5rem 1rem;
      font-size: 1.4rem;

      @include max($mobile) {
         display: none;
      }

      &-btn {
         padding: .8rem 1.4rem;
         border-radius: 100rem;
         display: inline-block;
         border: .1rem solid $color-text;
         font-size: 1.4rem;
         transition: .3s ease-in-out;

         @include max($mobile) {
            padding: .4rem 1.2rem;
            font-size: 1.2rem;
         }

         @include hover {
            border-color: $color-second;
            color: $color-second;
         }

         .txt {
            position: relative;
            top: .1rem;
         }
      }
   }
}

.km {
   position: relative;

   &-bn {
      margin-bottom: var(--sp-sc);

      &-slider {
         position: relative;

         .button-swiper .arrow-btn-prev,
         .button-swiper .arrow-btn-next {

            @include max($mobile) {
               display: flex;
               width: 1.2rem;
               height: 1.2rem;
               font-size: 1.2rem;
            }
         }

         .swiper-container {
            position: relative;
            overflow: visible;
         }

         .button-swiper .arrow-btn-prev {
            @include max($mobile) {
               transform: translateX(-100%);
            }
         }

         .button-swiper .arrow-btn-next {
            @include max($mobile) {
               transform: translateX(100%);
            }
         }

         .swiper-pagination.--second {
            @include min($mobile-min) {
               display: none;
            }
         }

         .swiper {
            --col-gap: 0;
         }
      }

      &-img {
         @include imgPd(683, 1215);
         border-radius: .4rem;

         img:not(.trailer) {
            @include imgCover;
         }
      }
      &-btn{
        position: absolute;
        bottom: 8%;
        right: 15%;
        min-width: 23.8rem;
        @media only screen and (max-width: 865px) {
          bottom: 5%;
          right: 15%;
          .txt{
            @include fz-14
          }
        }
        @include max($mobile){
          min-width: 0;
          right: 12%;
          .txt{
            @include fz-10 
          }
        }
        &:hover{
          .ic img{
            filter: none;
          }
        }
      }
   }

   &-m {
      .sec-heading {
         text-align: unset;
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         gap: 1rem;
         justify-content: space-between;
         flex-direction: row;

         @include max($mobile) {
            margin-bottom: 1.2rem;

            .hd-bot-loca {
               display: none;
            }
         }
      }

      &-slider {
         @include max($mobile) {
            width: calc(100% + var(--pd-container));
         }

         .swiper {
            --col-gap: 3.2rem;

            @include max($mobile) {
               --col-gap: 1.2rem;
            }

            &-slide {
               @include max($mobile) {
                  width: calc(100% / 2.3);
               }
            }
         }

         .swiper-pagination.--second {
            @include max($mobile) {
               display: none;
            }
         }
      }

      &-btn {
         margin-top: 2.4rem;
         min-width: 23.6rem;
         width: fit-content;

         @include max($mobile) {
            display: none
         }
      }

      &-img {
         @include imgPd(216, 380);

         img {
            @include imgCover;
         }
      }
   }
   &-action{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    gap: 1rem;
    .video{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      position: relative;
      .ic{
        // height: 4rem;
        aspect-ratio: 1 /1 ;
        border-radius: 100rem;
        border: .1rem solid rgba($color: white, $alpha: .5);
        @include max($mobile) {
          height: 1.3rem
        }
      }
    }
   }
}

.memb {
   position: relative;
   overflow: hidden;

   &-br {
      position: absolute;
      @include full;
      z-index: -1;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }

      &::before {
         @include pseudo;
         z-index: 1;
         @include full;
         background-color: rgba($color: #000000, $alpha: .6);
      }
   }

   &-slider {
      .swiper {
         --col-gap: 3.2rem;
         overflow: visible;

         @include max($mobile) {
            --col-gap: 1rem;
         }

         &-container {
            overflow: visible;
         }

         &-slide {
            overflow: visible;
            opacity: 0;
            transition: .3s ease-in-out;

            &-next,
            &-active {
               opacity: 1;
            }
         }
      }

      &.logined {
         width: calc(10/12*100%);
         margin-left: auto;
         margin-right: auto;

         @include max($mobile) {
            width: 100%;
         }

         .acc-points {
            margin-bottom: 3.6rem;

            @include max($res-mobile-min) {
               margin-bottom: 2.4rem;
            }

            @include max($mobile) {
               margin-bottom: 1.2rem;
            }
         }

         .memb-img {
            padding-top: calc(353/1008*100%);
         }

         .memb-name {
            text-align: center;
         }

         .acc-points .ct {
            justify-content: center;
            font-size: 3.2rem;
            font-weight: 600;

            @include max($res-mobile-min) {
               font-size: 2.4rem;
            }

            @include max($mobile) {
               font-size: 1.6rem;
            }
         }
      }
   }

   &-img {
      @include imgPd(282, 519);
      border-radius: var(--rs);
      box-shadow: 0px 12px 48px 0px rgba(243, 234, 40, 0.50);

      img {
         @include imgCover;
      }
   }

   &-content {
      margin-top: 3.2rem;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      @include max($mobile) {
         margin-top: 1.2rem;
         gap: .4rem;
      }

      .btn {
         margin-top: auto;
         width: fit-content;
         min-width: 23.6rem;

         @include max($mobile) {
            font-size: 1.2rem;
            min-width: unset;
         }
      }
   }

   &-name {
      @include fz-36;
      font-family: var(--font-title);
      text-transform: uppercase;
      letter-spacing: 0.18rem;

      @include max($mobile) {
         font-size: 1.4rem;
         font-family: var(--font-pri);
         letter-spacing: 0;
      }
   }

   &-des {
      --lc: 3;
      display: -webkit-box;
      -webkit-line-clamp: var(--lc);
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;

      @include max($mobile) {
         font-size: 1.2rem;
         --lc: 2;
      }
   }
}

.ct {
   &-left {
      @include max($mobile) {
         width: 100%;
      }

      &-inner {
         padding: 4rem 0;
      }
   }

   &-right {
      @include max($mobile) {
         width: 100%;
      }
   }

   &-social {
      width: 47rem;
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      @include max($mobile) {
         width: 25rem;
      }

      &-link {
         display: flex;
         align-items: center;
         @include fz-36;
         font-family: var(--font-title);
         text-transform: uppercase;

         @include max($res-mobile) {
            font-size: 3.5vw;
         }

         @include max($mobile) {
            font-size: 1.6rem;
         }

         img {
            width: 18rem;

            @include max($res-mobile) {
               width: calc(180/1200 * 100vw);
            }

            @include max($mobile) {
               width: 7rem;
            }
         }

         .txt {
            --mn: 6rem;
            display: inline-block;
            padding: 2.4rem;
            flex: 1;
            text-align: center;
            letter-spacing: 0.18rem;
            position: relative;

            @include max($res-mobile) {
               padding: calc(24/1728 * 100vw);
            }

            @include max($mobile) {
               flex: unset;
               padding: 1rem 3rem;
               --mn: 3rem;
            }

            &::before {
               @include pseudo;
               background: linear-gradient(106deg, #639 0%, #36C 102.69%);
               top: 0;
               height: 100%;
               right: 0;
               left: calc(var(--mn) * -1);
               z-index: -1;
               border-radius: .8rem;
            }
         }

         &:nth-child(even) {
            flex-direction: row-reverse;

            .txt {
               &::before {
                  right: calc(var(--mn) * -1);
                  left: 0;
               }
            }
         }
      }
   }

   &-box {
      padding: 4rem;
      background: rgba(51, 102, 204, 0.80);
      border-radius: .5rem;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      @include max($tablet) {
         padding: 2.4rem;
      }

      @include max($mobile) {
         padding: 2.4rem 1.6rem
      }

      .heading {
         @include fz-24;
      }
   }

   &-tt {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      li {
         display: flex;
         gap: 1rem;

         img {
            width: 2.4rem;
         }

         a {
            flex: 1;
         }
      }
   }

   &-form {
      margin-top: 2.4rem;

      .re-input {
         border-radius: .4rem;
         width: 100%;
      }

      .btn {
         min-width: 23rem;
         margin-top: 2.4rem;

         @include max($mobile) {
            margin: 2.4rem auto 0;
         }
      }
   }
}


.ht {
   padding: var(--sp-sc) 0;
   padding-top: 5rem;

   &-qc {
      padding: 10rem 2rem;
      position: relative;
      margin-bottom: var(--sp-sc);

      &-br {
         position: absolute;
         @include full;
         z-index: -1;

         &::before {
            @include pseudo;
            @include full;
            background: rgba(0, 13, 57, 0.50);
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      &-content {
         text-align: center;
         max-width: 83rem;
         margin: 0 auto;
         display: flex;
         flex-direction: column;
         gap: 2.4rem;
      }
   }

   &-as {
      &-item {
         @include max($mobile) {
            width: 100%;
         }
      }

      &-wr {
         text-align: center;
         display: flex;
         flex-direction: column;
         gap: 1.6rem;
         padding: 2.4rem;
         border-radius: .8rem;
         background: rgba(51, 102, 204, 0.80);
         height: 100%;

         @include max($mobile) {
            padding: 1.6rem;
            gap: 0.8rem;
         }

         .num {
            font-size: 3.6rem;
            font-weight: 700;
            color: $color-second;
            margin: 0 auto;
            line-height: 100%;

            @include max($res-mobile-min) {
               font-size: 3.1rem;
            }

            @include max($mobile) {
               font-size: 2.4rem;
            }
         }
      }
   }
}

.ts {
   padding-bottom: var(--sp-sc);

   .sec-heading {
      .des {
         max-width: 66rem;
         margin: 0 auto;
      }
   }

   &-slider {
      .swiper {
         --col-gap: 0;
      }
   }

   &-wr {
      min-height: 40rem;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
   }

   &-name {
      color: $color-second;
   }

   &-img {
      position: absolute;
      @include full;
      z-index: -1;

      &::before {
         @include pseudo;
         @include full;
         background: linear-gradient(90deg, #36C 6.67%, rgba(51, 102, 204, 0.00) 71.15%);
         z-index: 1;

         @include max($mobile) {
            background: linear-gradient(106deg, rgba(102, 51, 153, 0.80) 0%, rgba(51, 102, 204, 0.80) 102.69%);
         }
      }

      &-des {
         @include max($mobile) {
            display: none;
         }
      }

      &-mobile {
         @include min($mobile-min) {
            display: none;
         }
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &-content {
      padding: 4rem 7rem;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      @include max($mobile) {
         padding: 4rem 2rem;
      }
   }
}

.home-movie-now .swiper-pagination,
.home-movie-soon .swiper-pagination {
   display: none;
}

.service .cinema-rental-list .address-box {
   background: rgba(51, 102, 204, 0.80);
}


.service .cinema-rental-list .image {
   &:hover {
      .content-main {
         opacity: 1;
         visibility: visible;
         transform: translateY(0);
         top: unset;
      }

      .address-box {
         background: linear-gradient(106deg, rgba(102, 51, 153, 0.90) 0%, rgba(51, 102, 204, 0.90) 102.69%);
      }

      .cinema-rental-body .desc {
         display: block;
         overflow: visible;
         -webkit-line-clamp: unset;

         @include max($mobile) {
            font-size: 1.2rem;
         }
      }
   }
}

.service .address-box .service .cinema-rental-body {
   opacity: 1;
   display: block;
}

.service .address-box .sub-tittle,
.technology .address-box .sub-tittle {
   @include max($res-mobile-min) {
      font-size: 1.8rem;
   }
}

.service .cinema-rental-body .desc {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   font-size: 1.4rem;
   font-weight: 400;
}

.technology .cinema-rental-body .desc {
   @include max($res-mobile-min) {
      font-size: 1.4rem;
   }
}

.technology {
   padding-bottom: var(--sp-sc)
}

;

.service .cinema-rental-link,
.technology .cinema-rental-link {
   margin-top: 2.4rem;

   @include max(1200px) {
      margin-top: 0.8rem;
   }

   @include max($mobile) {
      margin-top: 0.2rem;
   }

   @include max($mobile) {
      display: none;
   }
}

.service .sec-heading .link {
   text-decoration: underline;
   text-underline-position: under;
   transition: all .3s;

   &:hover {
      color: $color-second;
   }
}
.service-content{
  @include max($mobile){
    margin-top: 2rem;
  }
}
.service .cinema-rental-list .cinema-rental-item {
   @include max($tablet) {
      width: calc(6/12*100%);
   }

   @include max($mobile) {
      width: 100%;
   }
}

.service .cinema-rental-heading,
.technology .cinema-rental-heading {
   @include max($mobile) {
      margin-bottom: 0.2rem;
   }
}

.service .cinema-rental-list .content-main {
   opacity: 0;
   visibility: hidden;
   transform: translateY(100%);
   top: unset;
   height: 40%;
}

.service .cinema-rental-list .content-main,
.technology .cinema-rental-list .content-main {

   @include max($mobile) {
      position: absolute;
   }
}

.web-movie-slide-deskop {
   @include max($mobile) {
      display: none;
   }
}

.web-movie-slide-mobile {
   @include min($mobile-min) {
      display: none;
   }
}
.is-desktop {
  @include max($mobile) {
     display: none;
  }
}

.is-mobile {
  @include min($mobile-min) {
     display: none;
  }
}

.tab-control {
   display: flex;
   margin-left: var(--pd-container);
   margin-right: var(--pd-container);
}

.tab-control-it {
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   gap: 0.4rem;
   font-family: var(--font-title);
   font-size: 1.4rem;
   text-transform: uppercase;
   width: 50%;
   position: relative;
   border-bottom: 4px solid transparent;
   transition: all .3s ease;

   &.active {
      border-bottom: 4px solid $color-second;
      color: $color-second;

      .ic {
         filter: brightness(0) saturate(100%) invert(77%) sepia(69%) saturate(494%) hue-rotate(8deg) brightness(106%) contrast(98%);
      }
   }

}

.tab-control-it .ic {
   width: 3.6rem;
   height: 3.6rem;

   img {
      transition: all .2s ease;
      filter: brightness(0) saturate(100%) invert(92%) sepia(12%) saturate(152%) hue-rotate(178deg) brightness(102%) contrast(88%);
   }
}

.tab-content {
   padding-top: 3.2rem;
   padding-bottom: 3.2rem;
   background-color: $color-pri;
}

.tab-content-it {
   display: none;
   &.open{
      display: block;
   }
}

.web-movie-slide-mobile .web-movie-item {}

.technology .cinema-rental-list .address-box {
   background: rgba(51, 102, 204, 0.80);
   text-align: center;
}

.technology .cinema-rental-list .cinema-rental-heading {
   justify-content: center;

   @include max($mobile) {
      flex-direction: unset;
   }
}

.ab-branch .sec-heading .desc,
.technology .sec-heading .des,
.service .sec-heading .des {
   max-width: 66rem;
   margin: 0 auto;
}

.technology .cinema-rental-link {
   justify-content: center;
}

.technology .content-main {

   @include min($mobile-min) {
      top: calc(100% - 8.4rem);
   }

   @include max($res-mobile) {
      top: calc(100% - 5.4rem);
   }

   @include max($mobile) {
      position: absolute;
      top: calc(100% - 7.4rem);
   }
}

.technology .cinema-rental-body {
   @include max($mobile) {
      opacity: 0;
      display: none;
   }
}

.technology .cinema-rental-list .image {
   &:hover {

      @include max($mobile) {
         .cinema-rental-body {
            opacity: 1;
            display: block;
         }
      }
   }
}

.technology .cinema-rental-link {
   @include max($mobile) {
      opacity: 1;
      display: flex;
   }
}

.ab-branch .contact-branch-body {
   padding-bottom: var(--sp-sc);

   @include max(700px) {
      flex-direction: column;
   }
}

.cinema-rental-footer{
  max-height: 70%;
  overflow-y: auto;
}

//navigate booking
.navigate-wrap {
  display: flex;
  gap: 4rem;
  padding: 2rem 3rem;
  border-radius: .4rem;
  background-color: #ecf2ffff;
  .heading{
    font-size: 3rem;
    align-self: center;
    color: rgb(70, 69, 69);
  }
  .preventClick{
    pointer-events: none;
  }
  @include max(1040px){
    .heading{
      font-size: 2.2rem;
    }
    padding: 1rem 2rem;
    gap: 2rem;
  }
  @include max($mobile){
    flex-direction: column;
    border: 1px solid rgb(74, 73, 73);
    padding: 1.2rem 2rem;
    border-radius: .4rem;
  }
}
.navigate-filter {
  display: flex;
  gap: 1rem;
  flex: 1;
  justify-content: flex-end;
  .select-location-second{
    width: auto;
    margin: 0;
    .ant-select-single .ant-select-selector{
      border-radius: .8rem!important;
      @include max($tablet){
        border-radius: .6rem!important;
      }
    }
  }
  .navigate-filter-item{
    flex: 0 1 22%;
    height: 100%;
    max-width: 20rem;
    max-height: 5rem;
    justify-content: flex-end;
    pointer-events: none;
    display: block;
    .ant-select-selector, .ant-select-disabled{
      // background: gray;
      border: 1px solid rgb(144, 136, 136)!important;
      // color: rgb(52, 52, 52);
    }
    .ant-select-selection-item{
      letter-spacing: .1rem;
      text-transform: capitalize;
      color: $color-pri;
      text-align: center;
    }
    &::before{
      // @include borderArrow("down", .5rem, $color-pri);
      display: none;
    }
    &.active{
      .ant-select-selection-item{
        // color: $color-second;
        pointer-events: auto;
      }
      // .ant-select-selector{
      //   background: transparent;
      //   border: 1px solid $color-second!important;
      // }
    }
    &.isActive{
      .ant-select-selection-item{
        // color: black;
        pointer-events: auto;
      }
      .ant-select-selector{
        background: $color-second;
        border: 1px solid $color-second!important;
      }
    }
    @include max($lg-min){
      max-width: 16.5rem;
    }
    @include max($tablet){
      max-width: 15.5rem;
      .ant-select-selection-item{
        font-size: 1.4rem;
        font-family: var(--font-title);
        font-weight: 400;
       }
    }
    @include max(880px){
      max-width: 11.2rem
    }
  }
  .navigate-filter-btn{
    flex: 1 0 auto;
    max-width: 10rem;
    .btn {
      height: 100%;
      border-radius: .8rem;
      @include max($tablet){
        justify-content: flex-start;
        border-radius: .6rem;
      }
    }
    &.active{
      .btn{
        border:1px solid $color-second;
        color: $color-second;
      }
    }
  }
  @include max($mobile){
    flex-direction: column;
    .navigate-filter-item{
      flex-basis: 100%;
      max-width: 100%;
      display: none; 
      &.active, &.isActive{
        display: block;
        .ant-select-selector{
          padding: .5rem 1rem;
        }
      }
      // &.active{
      //   .ant-select-selection-item{
      //     color: $color-black;
      //   }
      //   .ant-select-selector{
      //     background: transparent;
      //     border: 1px solid $color-black!important;
      //   }
      // }
      .ant-select-selector{
        padding: .5rem;
      }
    }
    .navigate-filter-btn{
      max-width: 100%;
      .btn {
        justify-content: center;
      }
    }
    .ant-select-selection-item{
      font-size: 1.6rem!important;
      
    }
  }
}
.dark-image {
  filter: brightness(0)
}
.navigate-notfound{
  gap: 1rem!important
}

//custom-height-img

.custom-height-img{
  .km-bn-img{
    @include imgPd(365, 1215);
  }
  @include min($mobile){
    padding-bottom: 5rem;
  }
}
