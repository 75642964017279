.regis .tab-content-it {
    // display: block;
}

.form .input {
    position: relative;
}

.regis {
    position: relative;
}

.action-auth {
    @include min($mobile-min) {
        width: calc(6/12*100%);
    }
}

.regis .bg {
    position: absolute;
    display: block;
    z-index: -5;
    opacity: 0.3;

    @include max($mobile) {
        top: -5%;
        left: 0;
        right: 0;

        &::after,
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            display: block;
        }

        &::after {
            background: linear-gradient(183deg, #4A3375 13.91%, rgba(45, 37, 80, 0.00) 37.91%, #0F172A 57.58%);
        }

    }

    @include min($mobile-min) {
        right: 0;
        bottom: 20%;
        left: calc(var(--pc) + var(--pd-container));

        &::after,
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            display: block;
        }

        &::after {
            background: linear-gradient(#0F172A 20%, rgba(45, 37, 80, 0) 30%);
        }

        &::before {
            background: linear-gradient(rgba(45, 37, 80, 0) 20%, #0F172A 100%);
        }
    }

}

.bg-regis-des {
    @include max($mobile) {
        display: none;
    }

    display: block;
}

.bg-regis-mobi {
    @include min($mobile-min) {
        display: none;
    }

    display: block;
}

.regis .form {
    background-color: #F8FAFC;
}

.regis .form .input .icon {
    position: absolute;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #64748B;
}

.regis .tab-control {
    margin-left: unset;
    margin-right: unset;
}

.regis .tab-control-it {
    position: relative;
    border-bottom: none;
    padding: 1.2rem;
    border-radius: 0.4rem 0.4rem 0 0;
    overflow: hidden;
    cursor: pointer;
    transition: all .3s ease;
    width: 50%;


    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #F8FAFC;
        z-index: -2;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }

    &::before {
        content: "";
        @include mid;
        width: calc(100% - 0.8rem);
        height: calc(100% - 0.8rem);
        background: rgba(248, 250, 252, 0.3);
        z-index: -2;
        transition: all .3s ease;
        border-radius: $radius-1;
        opacity: 0;
        visibility: hidden;
    }

    &:hover{
        &::before {
            opacity: 1;
            visibility: visible;
        }
    }

}

.regis .tab-control-it.active {
    &::after {
        opacity: 1;
        visibility: visible;
    }

    .tt {
        color: $color-black;
    }
}

.regis .tab-control-it .tt {
    position: relative;
    transition: all .2s;
    z-index: 2;
}

.regis .link-forget-pass {
    display: block;
    text-decoration: underline;
    text-underline-position: under;
    text-align: right;
    margin-top: 0.8rem;
    transition: all .2s;

    &:hover {
        color: $color-pri;
    }
}

.acc-login-notice {
    text-align: center;
    color: #334155;
    font-size: 1.4rem;

    .link {
        color: $color-black;
        font-weight: 500;
        text-decoration: underline;
        text-underline-position: under;
        transition: all .2s;

        &:hover {
            color: $color-pri;
        }
    }
}

.form-login .form-footer {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    justify-content: center;
    align-items: center;
    margin-top: 16rem;
}

.form-login .form-footer .txt-dec {
    position: relative;

    &:after,
    &::before {
        content: "";
        position: absolute;
        background: #CBD5E1;
        width: 4rem;
        height: 0.18rem;
    }

    &:after {
        left: -1.2rem;
        top: 50%;
        transform: translate(-100%, -50%);
    }

    &:before {
        right: -1.2rem;
        top: 50%;
        transform: translate(100%, -50%);
    }
}

.form-login .form-footer .link-social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-size: 1.4rem;
    color: $color-black;
    padding: 0.8rem 1.2rem;
    border-radius: 3.6rem;
    border: 1px solid #94A3B8;
    background: $color-white;
    width: 100%;
    transition: all .3s ease;
    &:hover{
        background: rgb(248, 250, 252);
    }

    &:not(:last-child) {
        margin-bottom: 1.6rem;
    }
}

.form-login .form-footer .list-social {
    width: 100%;
}

.form-login .form-footer .link-social .icon {
    width: 1.6rem;
    height: 1.6rem;
    flex-shrink: 0;
}