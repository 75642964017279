.heading {
    font-family: var(--font-title);
    font-size: 4rem;
    color: $color-text;
    text-transform: uppercase;
    @include max(1200px) {
        font-size: 3.2rem;
    }
    @include max($mobile) {
        font-size: 2.4rem;
    }
}

.title {
    font-size: 4rem;
    color: $color-text;
}

.sub-tittle {
    font-size: 2.4rem;
    color: $color-text;
    font-family: var(--font-title);
    text-transform: uppercase;
    @include max($mobile) {
        font-size: 1.8rem;
    }
}

.txt-lg {
    font-size: 2rem;
    font-weight: 700;
}

.txt-sm {
    color: $color-text;
    font-size: 1.4rem;
}

.txt-upper {
    text-transform: uppercase;
}

.txt-ita {
    font-style: italic;
}

.txt-center {
    text-align: center;
}

.sec-heading {
    margin-bottom: 4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 4rem;
    @include max(1200px) {
        margin-bottom: 3.2rem;
        margin-top: 3.2rem;
    }
    @include max(768px) {
        margin-bottom: 0;
        margin-top: 2rem;
        gap: 0.8rem;
    }
    .heading {
        margin-bottom: 1.2rem;
        @include max(768px) {
            margin-bottom: 0.8rem;
        }
    }
    .desc {
        font-weight: 600;
    }
}