.pt-32 {
    padding-top: 3.2rem;
}

.pb-32 {
    padding-bottom: 3.2rem;
}

.pt-16 {
    padding-top: 1.6rem;
}

.pb-16 {
    padding-bottom: 1.6rem;
}

.pt-80 {
    // padding-top: 8rem;

    // @include max(768px) {
    //     padding-top: 4.8rem;
    // }

    // @include max(500px) {
    //     padding-top: 2.4rem;
    // }
    padding-top: var(--sp-sc);
}

.pb-80 {
    // padding-bottom: 8rem;

    // @include max(768px) {
    //     padding-bottom: 4.8rem;
    // }

    // @include max(500px) {
    //     padding-bottom: 2.4rem;
    // }
    padding-bottom: var(--sp-sc);
}

.mt-80 {
    margin-top: 8rem;

    @include max(768px) {
        margin-top: 4.8rem;
    }

    @include max(500px) {
        margin-top: 2.4rem;
    }
}

.mb-80 {
    margin-bottom: 8rem;

    @include max(768px) {
        margin-bottom: 4.8rem;
    }

    @include max(500px) {
        margin-bottom: 2.4rem;
    }
}

.pt-100 {
    padding-top: 10rem;

    @include max(768px) {
        padding-top: 8rem;
    }
}

.pb-100 {
    padding-bottom: 10rem;

    @include max(768px) {
        padding-bottom: 8rem;
    }

}

.mg-auto {
    margin-left: auto;
    margin-right: auto;
}

.sc-pd{
    padding: var(--pd-sc) 0;
}

.sc-pd-t{
    padding-top: var(--pd-sc);
}

