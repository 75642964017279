.hbooking {}

.hbooking-wr {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--rs);
    overflow: hidden;
    @include min($mobile-min) {
        padding-top: calc(200/1216*100%);
    }
    @include max($mobile) {
        padding-top: calc(280/350*100%);
    }
}

.hbooking-bg {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.hbooking-bg-des {
    display: block;
    @include max($mobile) {
        display: none;
    }
}

.hbooking-bg-mobi {
    display: block;
    @include min($mobile-min) {
        display: none;
    }
}

.hbooking .hbooking-ct {
    margin-top: auto;
    display: block;
    width: fit-content;
    position: absolute;
    left: calc(103/1216*100%);
    bottom: calc(60/500*100%);
    @include max($mobile) {
        width: 100%;
        left: 0;
        bottom: 0;
        .sub-tittle {
            font-size: 1.6rem;
        }
        .link .txt {
            font-size: 1.2rem;
        }
    }
}
.sec-hbooking .custom-spacing{
  padding-bottom: 0;
}
.hbooking-new{
  display: flex;
  max-height: 200px;
  .hbooking-left{
    flex: 0 0 35%;
    img{
      height: 100%;
      object-fit: cover;
    }
  }
  .hbooking-right{
    flex: 1;
    margin: 0;
    background: linear-gradient(106deg, rgba(102, 51, 153, 0.8) 0%, rgba(51, 102, 204, 0.8) 102.69%);
    .address-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding-left: 5rem;
      .sub-tittle {
        font-size: 4.5rem;
        margin-bottom: 1rem;
      }
      .link {
        align-items: start;
        .ic{
          margin-top: .2rem;
        }
      }
      @include max($tablet) {
        padding-left: 3rem;
        .sub-tittle {
          font-size: 3rem;
        } 
      }
      @include max($mobile) {
        padding: 1.2rem;
        .sub-tittle {
            font-size: 1.6rem;
            margin-bottom: .5rem;

        }
        .link {
          .txt {
            font-size: 1.2rem;
          }
          .ic{
            margin-top: 0;
          }
        }
    }
    }
  }
}
.tab-price-ticket .tab-control .list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
}

.tab-price-ticket .tab-control .it {
    min-width: 10rem;
    width: fit-content;
    display: block;
    font-size: 2rem;
    text-transform: uppercase;
    border-bottom: 0.4rem solid transparent;
    transition: all .3s ease;
    font-family: var(--font-title);
    cursor: pointer;
    @include max($mobile) {
        min-width: unset;
        width: calc(100% / 3);
    }
}

.price-ticket .tab-control {
    margin-bottom: 2.4rem;
    margin-left: unset;
    margin-right: unset;
}

.price-ticket .tab-image .image {
    &:not(:first-child) {
        display: none;
    }
}

.tab-price-ticket .tab-control .it.active,
.tab-price-ticket .tab-control .it:hover {
    border-bottom: 0.4rem solid $color-second;
    color: $color-second;
}

.not-btn-video .info .btn {
    margin-left: auto;
    margin-right: auto;
}

.booking-cinestar {
    padding-bottom: var(--sp-sc);
}

.booking-cinestar .address-box .item:nth-child(2) .link {
    text-decoration: unset;
    text-underline-position: under;
}

.booking-cinestar .address-box {
    pointer-events: visible;
}

.booking-cinestar .address-box .btn-booking {
    min-width: 15rem;
    width: fit-content;
    // opacity: 0;
    // display: none;
    transition: all .2s;
    @include max($mobile) {
        opacity: 1;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.booking-cinestar .cinema-rental-list .image {
    >img {
        @include max($mobile) {
            display: none;
        }
    }
}

.booking-cinestar .cinema-rental-list .image:hover .content-main {
    height: 100%;
    .btn-booking {
        display: block;
        opacity: 1;
    }
}

.booking .btn-more {
    margin-top: 4.8rem;
    @include max($res-mobile-min) {
        margin-top: 3.2rem;
    }
    @include max($tablet) {
        margin-top: 2.4rem;
    }
    @include max($mobile) {
        display: none;
    }
}

.booking .btn-more .btn {
    display: block;
    min-width: 23.6rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.filter .select2-container--default .select2-selection--single {
    border-radius: $radius-1;
    color: $color-black;
    height: fit-content;
    background-color: #F8FAFC;
    border: 0.1rem solid #F8FAFC;
}

.filter .select2-selection__placeholder {
    font-size: 2.4rem;
    font-weight: 600;
    color: $color-black !important;
    display: block;
    @include max($res-mobile-min) {
        font-size: 1.8rem;
    }
    @include max($mobile) {
        font-weight: 500;
        font-size: 1.4rem;
    }
}

.filter .select2.select2-container {
    width: calc(8/12*100%) !important;
    @include max($mobile) {
        width: 100% !important;
    }
}

.filter .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 2.4rem 2.4rem 2rem 2.4rem;
    text-align: left;
    @include max($res-mobile-min) {
        padding: 1.6rem 1.6rem 1.2rem 1.6rem;
    }
    @include max($mobile) {
        padding: 1.2rem 1.2rem 1rem 1.2rem;
    }
}

.filter .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 100%;
}

.filter .select2-selection__arrow .presentation {}

.filter .select2-container .select2-selection--single .select2-selection__rendered {
    color: $color-black;
    font-weight: 600;
    font-size: 2.4rem;
    @include max($res-mobile-min) {
        font-size: 1.8rem;
    }
    @include max($mobile) {
        font-weight: 500;
        font-size: 1.4rem;
    }
}

.filter .select2-container {
    height: fit-content;
}

.filter .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 2.4rem;
    @include max($res-mobile-min) {
        right: 1.6rem;
    }
    @include max($mobile) {
        right: 1.2rem;
    }
}

.filter .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #000 transparent transparent transparent;
    border-width: 0.6rem 0.5rem 0 0.6rem;
    @include max($mobile) {
        border-width: 0.5rem 0.4rem 0 0.5rem;
    }
}

.filter .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #000 transparent;
    border-width: 0 0.5rem 0.6rem 0.5rem;
    @include max($mobile) {
        border-width: 0 0.4rem 0.5rem 0.4rem;
    }
}

.booking-cinestar .address-box .sub-tittle {
    @include max($mobile) {
        font-size: 2rem;
    }
}

.booking-cinestar .cinema-rental-heading {
    @include max($mobile) {
        align-items: center;
        justify-content: center;
    }
}

.booking-cinestar .address-box .link .txt {}

.booking-cinestar .address-box .btn {
    margin-top: 3.2rem;
    @include max($res-mobile-min) {
        margin-top: 2.4rem;
    }
    @include max($tablet) {
        margin-top: 1.2rem;
    }
}

.movie-fes,
.movie-event-coming {
    padding-bottom: var(--sp-sc);
}
.book-theater{
  &-item{
    display: flex;
    margin: 2rem 0;
    gap: 1rem;
    background: linear-gradient(106deg, rgba(102, 51, 153, 0.80) 0%, rgba(51, 102, 204, 0.80) 102.69%);
    border-radius: .4rem;
  }
  &-image{
    border-radius: inherit;
    img{
      border-radius: inherit;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-content{
    flex: 1;
    padding:  .5rem .5rem .5rem 0;
    @include fz-14
  }
  &-icon{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: .3rem;
    i{
      color: #bdbb76;
      align-self: center;
    }
    img{
      max-width: 1.4rem;
    }
  }
  &-name{
    font-size: 1.6rem;
    font-weight: 600;
    @include max(376px){
      font-size: 1.4rem;
      --lc: 1;
      display: -webkit-box;
      -webkit-line-clamp: var(--lc);
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }
  &-info{
    @include fz-14
  }
  .txt-address{
    @include fz-12
  }
}
