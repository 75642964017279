.img-ticket {
    width: 32%;
    height: auto;
    display: block;
    margin-bottom: 2.4rem;

    @include max($mobile){
        display: none;
    }
}

.checkout-success{
    display: flex;
    flex-direction: column;
    @include max($mobile){
        flex-direction: column-reverse;
        gap: 4rem;
    }
}

.checkout-success-content {
    margin-left: auto;
    margin-right: auto;
    width: calc(8/12*100%);

    @include max($tablet) {
        width: calc(10/12*100%);
    }

    @include max($mobile) {
        width: 100%;
    }
}

.checkout-success-main {
    overflow: hidden;
    border-radius: 0.8rem;
    display: block;
}


.checkout-success-heading {
    display: flex;
    align-items: center;
    background-color: $color-white;
}

.img-movie {
    width: calc(3.5/8*100%);
}

.checkout-success-heading .img-movie .image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-top: calc(415/309*100%);

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.img-qrcode {
    background-color: $color-white;
    width: calc(4.5/8*100%);
}

.checkout-success-content .btn-gr {
    display: flex;
    align-items: center;
    gap: 1.2rem 3.2rem;
    margin-top: 4rem;
    @include max($mobile){
        flex-direction: column;
    }

    @include max($res-mobile-min){
        margin-top: 3.2rem;
    }

    @include max($mobile){
        margin-top: 2.4rem;
    }
}

.checkout-success-content .btn-gr .btn {
    width: 100%;
}

.checkout.checkout-success .heading {
    text-align: center;
}

.checkout-success .process {
    margin-left: auto;
    margin-right: auto;
}