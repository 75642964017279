.inner-radio {}

.inner-radio .form-label,
.btn-discount {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-bottom: 0;
    padding: 1.2rem;
    background: $color-four;
    border-radius: $radius-1;
    width: 100%;
    position: relative;

    @include max(768px) {
        gap: 0.8rem;
    }

    @include max($mobile) {
        padding: 0.8rem 1rem;
    }
}

.btn-discount {
    align-items: flex-start;
    transition: all .3s ease;
    position: relative;

    .text,
    img {
        position: relative;
        z-index: 2;
        transition: all .2s;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(106deg, rgba(102, 51, 153, 0.90) 0%, rgba(51, 102, 204, 0.90) 102.69%);
        opacity: 0;
        z-index: 1;
        transition: all .3s ease;
        border-radius: $radius-1;
    }


    &:hover {
        cursor: pointer;
        box-shadow: 0 0 1.1rem #36C;

        &::after {
            opacity: 1;
        }

        img {
            transform: scale(1.2) rotate(30deg);
        }
    }
}

.inner-radio .form-label {
    border: 0.527px solid $color-third;
    background: $color-white;
}

.btn-discount {
    background: $color-four;
    font-family: var(--font-pri);
    color: $color-text;
    text-align: left;
}

.inner-radio.inner-radio-white .form-label {
    position: relative;
    background: transparent;
    border: 1px solid #64748B;
    color: $color-text;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: $color-white;
        opacity: 0;
        z-index: -1;
        transition: all .4s ease-in-out;
    }

    &:hover {
        &::after {
            opacity: 1;
        }

        .text {
            color: $color-gray-2;
        }
    }
}

.checkout-payment .form-label .img {
    width: 4.8rem;
    height: 4.8rem;

    @include max(768px) {
        width: 3.2rem;
        height: 3.2rem;
    }

    @include max($mobile) {
        width: 2.4rem;
        height: 2.4rem;
    }
}

.checkout-payment .inner-radio .form-label .text,
.btn-discount .tt {
    position: relative;
    z-index: 1;
    transition: all .2s;
    font-size: 2rem;
    font-weight: 600;

    @include max(768px) {
        font-size: 1.6rem;
    }

    @include max($mobile) {
        font-size: 1.4rem;
    }
}

.btn-discount .img {
    width: 3rem;
    height: 3rem;

    @include max(768px) {
        width: 2.4rem;
        height: 2.4rem;
    }

    @include max($mobile) {
        width: 1.6rem;
        height: 1.6rem;
    }
}

.btn-discount .note {
    font-size: 1.6rem;
    margin-top: 0.4rem;
    font-weight: 300;

    @include max($mobile) {
        font-size: 1.2rem;
        margin-top: 0.2rem;
    }
}

.checkout-payment .inner-radio {}

.checkout-payment .block-check {
    @include min($mobile-min) {
        min-width: 66.4rem;
    }
}

.form-payment .form-list {
    padding-bottom: 2.4rem;
    border-bottom: 0.2rem solid rgba(248, 250, 252, 0.1);
}

.form-payment .btn-discount {
    margin-top: 2.4rem;
}

.form-payment .btn {
    margin-top: 4rem;
}

.form-discount .checkbox-label {
    border: 0.1rem solid $color-third;
    padding: 1.2rem;
    width: 100%;
    color: $color-gray-2;

    @include max($mobile) {
        padding: 0.8rem 1rem;
    }
}

.form-discount .form-label {
    color: $color-gray-2;
    flex-direction: column;
    gap: 0.2rem;
    align-items: flex-start;

    @include max($mobile) {
        padding: 1rem 3rem 0.8rem 1rem;
    }
}

.form-discount .form-it {
    display: flex;
    flex-direction: column;
    gap: 0.8rem 0;
}

.form-discount .form-label .tt {
    font-weight: 600;

    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.form-discount .form-label .note {
    font-size: 1.4rem;

    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.form-discount .btn {
    margin-top: 0;
}

.form-discount .inner-radio.not-action .form-label {
    background: #E2E8F0;
    border: 0.1rem solid #E2E8F0;
    color: #94A3B8;
    pointer-events: none;

    &::before {
        border: 0.1rem solid #94A3B8;
    }
}

.list-discount {
    position: relative;
    max-height: 26rem;
    overflow-y: auto;
    transition: all .2s ease;

    &::-webkit-scrollbar {
        display: none;
    }
}

.form-payment .form-discount .form-list {
    padding-bottom: 0;
}

.form-payment>.btn {
    @include max($mobile) {
        display: none;
    }
}

.form-checkout-cus .btn {
    @include min($mobile-min) {
        display: none;
    }
}