.forget-pass-success {
    position: relative;
    min-height: 80vh;

    @include max($mobile) {
        height: 100vh;
        padding-top: 0;
    }
}

.forget-pass-success .forget-pass-wr {

    @include max($mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.forget-pass-wr .heading {
    margin-bottom: 1.6rem;
}

.forget-pass-wr .content {
    margin-bottom: 3.2rem;
}

.forget-pass-wr .btn {
    max-width: 35rem;

    @include max($mobile) {
        max-width: unset;
        width: 100%;
    }
}