.hamburger {
    width: 35px;
    overflow: hidden;
}

.hamburger {
    &.active {
        .ham {
            @include transform(rotate(45deg));

            .top {
                stroke-dashoffset: -98px;
            }

            .bottom {
                stroke-dashoffset: -138px;
            }
        }
    }
}

.ham {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .line {
        fill: none;
        transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        stroke: $color-white;
        stroke-width: 5.5;
        stroke-linecap: round;

        @include max($res-mobile) {
            stroke: $color-white;
        }
    }

    .top {
        stroke-dasharray: 40 140;
    }

    .bottom {
        stroke-dasharray: 40 180;
    }
}