.update-pass,
.forget-password {
    position: relative;
    min-height: 80vh;

    @include max($mobile) {
        min-height: 100vh;
    }
}

.update-pass .update-pass-wr {
    max-width: 44.8rem;
}

.update-pass .btn-submit {
    display: block;
}

.update-pass .form {
    text-align: left;
}


.forget-password .form,
.form-update .form {
    background: transparent;
    color: $color-text;
    padding: unset;
}

.forget-password .form .form-list {
    gap: 2.4rem;
}