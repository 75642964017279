.btn {
    // Css default
    @include mid-flex;
    gap: 1rem;

    // Css custom
    background-color: transparent;
    outline: none;
    border: none;
    text-align: center;
    font-family: var(--font-title);
    text-transform: uppercase;
    letter-spacing: 0.8px;
    border-radius: $radius-1;
    cursor: pointer;
    padding: 0.8rem 1.2rem;
    overflow: hidden;

    &--pri {
        color: $color-gray-2;
        position: relative;
        z-index: 1;
        transition: all 0.3s ease-in-out;

        &::before,
        &::after {
            @include pseudo;
            height: 100%;
            width: 0.1rem;
            transition: all 0.4s ease-in-out;
        }

        &::before {
            width: 100%;
            background: $color-second;
            z-index: -2;
            right: 0;
            top: 0;
        }

        &::after {
            bottom: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: linear-gradient(106deg, #639 0%, #36C 102.69%);
            z-index: -1;
        }

        &:hover {
            color: $color-white;

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7487%) hue-rotate(198deg) brightness(105%) contrast(101%);
            }

            &::before {
                width: 0;
            }

            &::after {
                width: 100%;
            }
        }

        &-active {
          color: $color-white;

          img {
              filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7487%) hue-rotate(198deg) brightness(105%) contrast(101%);
          }

          &::before {
              width: 0;
          }

          &::after {
              width: 100%;
          }
        }
    }

    &--second {
        color: $color-text;
        position: relative;
        z-index: 1;
        transition: all 0.3s ease-in-out;

        &::before,
        &::after {
            @include pseudo;
            height: 100%;
            width: 0.1rem;
            transition: all 0.4s ease-in-out;
        }

        &::before {
            width: 100%;
            background: $color-pri;
            z-index: -2;
            right: 0;
            top: 0;
        }

        &::after {
            bottom: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: linear-gradient(90deg, #FF6D00 0%, #FFC107 100%);
            z-index: -1;
        }

        &:hover {
            color: $color-white;

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7487%) hue-rotate(198deg) brightness(105%) contrast(101%);
            }

            &::before {
                width: 0;
            }

            &::after {
                width: 100%;
            }
        }
    }

    &--outline {
        color: $color-second;
        position: relative;
        z-index: 1;
        transition: all 0.4s ease-in-out;
        border: 0.1rem solid $color-second;

        &::before,
        &::after {
            @include pseudo;
            height: 100%;
            width: 0.1rem;
            transition: all 0.4s ease-in-out;
        }


        &::before {
            width: 100%;
            background: transparent;
            z-index: -2;
            right: 0;
            top: 0;
        }

        &::after {
            bottom: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: linear-gradient(90deg, #FF6D00 0%, #FFC107 100%);
            z-index: -1;
        }

        &:hover {
            color: $color-white;

            img {
                transition: all .2s;
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7487%) hue-rotate(198deg) brightness(105%) contrast(101%);
            }

            &::before {
                width: 0;
            }

            &::after {
                width: 100%;
            }
        }
    }

    &--white {
        color: $color-white;
        position: relative;
        z-index: 1;
        transition: all 0.4s ease-in-out;
        border: 0.1rem solid $color-white;

        &::before,
        &::after {
            @include pseudo;
            height: 100%;
            width: 0.1rem;
            transition: all 0.4s ease-in-out;
        }

        &::before {
            width: 100%;
            background: transparent;
            z-index: -2;
            right: 0;
            top: 0;
        }

        &::after {
            bottom: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: linear-gradient(90deg, #FF6D00 0%, #FFC107 100%);
            z-index: -1;
        }

        &:hover {
            color: $color-white;

            img {
                transition: all .2s;
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7487%) hue-rotate(198deg) brightness(105%) contrast(101%);
            }

            &::before {
                width: 0;
            }

            &::after {
                width: 100%;
            }
        }
    }
    &.--wf{
      width: fit-content;
    }
    &.--mw{
      min-width: 23.6rem;
    }
    &.--gray{
      background-color: #E2E8F0;
      color: #000;
    }
}
.--m-top{
  margin-top: 1.6rem !important;
}
.--m-mid{
  margin: 0 auto;
}