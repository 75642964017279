.prof {}

.prof .about-user {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    @include max($mobile) {
        justify-content: center;
        flex-direction: column;
    }

}



.prof .about-user .ava {
    width: 4.2rem;
    height: 4.2rem;
    flex-shrink: 0;
    position: relative;

    @include max($mobile) {
        width: 4.8rem;
        height: 4.8rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.prof .about-user .ic {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: $color-white;
    @include mid-flex;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(25%, 25%);

    img {
        width: 1.2rem;
        height: 1.2rem;
    }
}

.prof .info .name {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    @include max($mobile) {
        justify-content: center;
        flex-direction: column;
    }
}

.prof .info .name .txt {
    font-weight: 600;
    display: block;

    @include max($mobile) {
        font-size: 2rem;
        font-family: var(--font-title);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
  }
}

.prof .info .level-memb {
    font-size: 1.4rem;
    color: $color-black;
    padding: 0.5rem 0.2rem 0.1rem 0.2rem;
    border-radius: $radius-1;
    background-color: $color-second;
    line-height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.prof .info .email {
    font-size: 1.4rem;
    word-break: break-all;

    @include max($mobile) {
        display: none;
    }
}

.prof .side-bar {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;


    @include max($tablet) {
        width: calc(3.5/12*100%);
    }

    @include max($mobile) {
        width: 100%;
        gap: 0;
    }
}

.prof .prof-main {

    @include max($tablet) {
        width: calc(8.5/12*100%);
    }

    @include max($mobile) {
        width: 100%;
    }
}

.prof .action-it {
    @include max($mobile) {
        width: 50%;
    }
}

.prof .action-link {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;

    .txt {
        margin-top: 0.6rem;
        color: $color-white;
        transition: all .2s;
    }

    img {
        transition: all .2s;
        filter: brightness(0) saturate(100%) invert(98%) sepia(98%) saturate(0%) hue-rotate(326deg) brightness(102%) contrast(102%);
    }

    @include max($mobile) {
        font-weight: 400;
        font-size: 1.4rem;
        font-family: var(--font-title);
        text-transform: uppercase;
        letter-spacing: 0.07rem;
        justify-content: center;
    }

    @include max(400px) {
        min-width: 12.5rem;
    }
}

.prof .action-it.active .action-link,
.prof .action-it .action-link:hover {
    .txt {
        color: $color-second;
    }

    img {
        filter: brightness(0) saturate(100%) invert(72%) sepia(97%) saturate(369%) hue-rotate(8deg) brightness(107%) contrast(98%);
    }
}



.prof .action-it.active .action-link {
    @include max($mobile) {
        border-radius: 2rem 2rem 0 0;
        border-bottom: 0.4rem solid $color-second;
    }
}

.prof .action-list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    @include max($mobile) {
        flex-direction: row;
        justify-content: center;
    }
}

.prof .action-link .ic,
.act-logout .logout .ic {
    width: 2.4rem;
    height: 2.4rem;
    display: inline-block;
    flex-shrink: 0;
}

.prof .action-link .desk {
    @include max($mobile) {
        display: none;
    }
}

.prof .action-link .ic {
    @include max($mobile) {
        display: none;
    }
}

.prof .action-it:nth-child(2) .action-link .ic {
    width: 2rem;
    height: 2rem;
}

.act-logout .logout {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: var(--font-pri);

    .txt {
        color: #9C9C9C;
        transition: all .2s;
    }

    img {
        transition: all .2;
    }

    &:hover {
        .txt {
            color: $color-second;
        }

        img {
            filter: brightness(0) saturate(100%) invert(90%) sepia(94%) saturate(7463%) hue-rotate(330deg) brightness(105%) contrast(105%);
        }
    }

    @include max($mobile) {
        display: none;
    }
}

.prof-main .re-search-form-wr {
    min-width: calc(6/10*100%);
    width: fit-content;
    margin-bottom: 3.2rem;

    @include max($res-mobile-min) {
        min-width: calc(7/9*100%);
        margin-bottom: 2.4rem;
    }

    @include max($tablet) {
        min-width: 100%;
    }
}

.prof-main .re-search-form-btn {
    @include max($tablet) {
        min-width: 16rem;
    }

    @include max($mobile) {
        min-width: 12rem;
    }
}

.prof-main .sec-heading {
    text-align: left;
    margin-bottom: 2.4rem;

    @include max($mobile) {
        margin-bottom: 0;
    }
}

.prof-main .sec-heading .heading {
    font-size: 3rem;
}

.prof-main .tb {
    width: 100%;
    text-align: center;
    background-color: $color-four;

    @include max(900px) {
        @include min ($mobile-min) {
            display: block;
            margin: 0 auto;
            overflow-x: auto;
            white-space: nowrap;

            &::-webkit-scrollbar {
                width: 0.5rem;
            }

            &::-webkit-scrollbar:horizontal {
                height: 0.3rem;
            }

            &::-webkit-scrollbar-track {
                background: $color-white;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: $radius-1;
                background: $color-four;
            }
        }
    }
}

.prof-main .tb .tb-head {
    padding: 3.2rem 1.6rem;
    font-weight: 700;
    line-height: 100%;
    width: 100%;

    @include max($res-mobile-min) {
        font-size: 1.8rem;
    }

    @include max($tablet) {
        font-size: 1.6rem;
        font-weight: 600;
    }

    @include max($mobile) {
        font-size: 1.4rem;
        font-weight: 400;
    }
}

.prof-main .tb-head .tt {
    padding: 1.6rem;

    @include max($mobile) {
        padding: 1.2rem;
    }
}

.prof-main .tb-head .tt:nth-child(3),
.prof-main .tb-head .tt:nth-child(4) {
    @include max($mobile) {
        display: none;
    }
}

.prof-main .tb .tb-body {
    background-color: $color-white;
    color: $color-black ;
}

.prof-main .tb-body-list .td-it {
    padding: 1.2rem 1.2rem;

    @include max($tablet) {
        font-size: 1.4rem;
    }

    @include max($mobile) {
        font-size: 1.2rem;
    }
}

.prof-main .tb-body-list {
    @include max($mobile) {
        border-bottom: 0.1rem solid #F1F5F9;
    }
}

.prof-main .tb-body-list .td-it:nth-child(3),
.prof-main .tb-body-list .td-it:nth-child(4) {
    @include max($mobile) {
        display: none;
    }
}


.prof-main .tb-body-list .td-it:last-child {
    padding-right: 2.4rem;
    white-space: nowrap;

    @include max($mobile) {
        padding-right: 1.2rem;
    }
}

.prof-main .tb-body-list .td-it:first-child {
    padding-left: 2.4rem;

    @include max($mobile) {
        padding-left: 1.2rem;
    }
}


.prof-main .tb-body-list:last-child .td-it {
    padding-bottom: 1.6rem;

    @include max($mobile) {
        padding-bottom: 1.2rem;
    }
}

.prof-main .tb-body-list:first-child .td-it {
    padding-top: 1.6rem;

    @include max($mobile) {
        padding-top: 1.2rem;
    }
}

.prof-main .show-ticket {
    color: #64748B;
    transition: all .3s;
    font-family: var(--font-pri);

    &:hover {
        color: $color-four;
        text-decoration: underline;
        text-underline-position: under;
    }

    @include max($mobile) {
        text-decoration: unset;
        text-underline-position: unset;
    }
}

.prof-main .block-ticket {
    // min-width: 66rem;
}

.prof-main .block-logout {
    // min-width: 32.8rem;
}

.prof-main .block-check {
    background-color: $color-four;
    text-align: left;
}

.prof-main .block-check .ct,
.prof-main .block-check .dt {
    color: $color-text;
}

.prof-main .block-check .btn-close {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    width: 3.2rem;
    height: 3.2rem;
    display: block;

    @include max($mobile) {
        right: 1.2rem;
        top: 1.2rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    &:hover {
        img {
            transition: all .2s;
            transform: rotate(180deg);
        }
    }
}

.prof-main .block-check .btn {
    display: block;
}

.block-logout .txt {
    margin-bottom: 2.4rem;
    text-align: center;

    @include min($mobile-min) {
        max-width: 28rem;
    }
}

.block-logout .btn-logout {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.prof-main .block-check .btn-save {
    font-size: 1.4rem;
}

.prof-main .heading {
    @include max($mobile) {
        display: none;
    }
}

.block-memb {
    overflow: hidden;

    @include max(900px) {
        padding: 1.2rem;
    }

    @include max($mobile) {
        background-color: transparent;
        color: $color-text;
        padding: unset;
        border-radius: unset;
        overflow: visible;
    }
}

.block-memb .block-memb-wr {
    justify-content: center;
    @include max(900px) {
        --col-gap: 1.2rem;
    }

    @include max($mobile) {
        --col-gap: 0;
        // --row-gap: 0;
    }
}

.block-memb .block-memb-it {
    width: 50%;

    // @include max(900px){
    //     --col-gap: 0;
    // }

    @include max($mobile) {
        width: 100%;
        padding: unset;
    }
}

.block-memb .block-memb-it:nth-child(2) {
    @include max($mobile) {
        margin-bottom: 0;
    }
}

.prof .acc-points {
    margin-bottom: 2.4rem;
}

.side-bar .acc-points {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.block-memb .acc-points {
    @include max($mobile) {
        display: none;
    }

    @include max(900px) {
        font-size: 1.2rem;
    }
}

.acc-points .ct {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.4rem;

}

.poin-current {
    background: linear-gradient(90deg, #FF296E 3.69%, #FBC821 75.91%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.block-bar {
    border-radius: 1.4rem;
    background: #64748B;
    width: 100%;
    height: 0.7rem;
    overflow: hidden;
    border: none;
}

.block-bar .curr-bar {
    height: 100%;
    background: linear-gradient(90deg, #FF296E 3.69%, #FBC821 75.91%);
}

.block-memb .img-card {
    display: block;
    position: relative;
    padding-top: calc(200/467*100%);
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: $radius-1;
    box-shadow: 0px 12px 48px 0px rgba(243, 234, 40, 0.30);

    img {
        @include mid;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.block-memb .box-memb {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.block-memb .box-memb .tt {
    font-family: var(--font-title);
    text-transform: uppercase;
    margin-bottom: 0.8rem;
}

.box-memb .memb-hd .tt {
    font-size: 2rem;
}

.box-memb .memb-hd .desc {
    font-weight: 600;

    @include max(900px) {
        font-size: 1.2rem;
    }
}

.box-memb .list {
    margin-left: 1.2rem;

    @include max(900px) {
        font-size: 1.2rem;
    }
}

.box-memb .list .item::before {
    list-style-type: disc;
    color: #D9D9D9;
    margin-left: 2.4rem;
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1rem;

    @include max($mobile) {
        color: $color-text;
    }

}

.block-memb-vip .btn {
    background: #CBD5E1;
    cursor: default;
    color: #0F172A;
}

.box-memb .btn {
    @include max(900px) {
        font-size: 1.4rem;
    }
}

.block-memb-member .btn {
  background: #CBD5E1;
  cursor: default;
  color: #0F172A;
}